/*声明一个数组cardTypeMap 初始化数据   
  输入银行卡号开头匹配银行名称  
  银行卡号开头 bankCardCodes            
  银行名称 bankNames*/
const cardTypeMap = [
  {
    bankCardCodes: "625247",
    bankNames: "中国工商银行",
  },
  {
    bankCardCodes: "625187",
    bankNames: "陕西秦农农村商业银行",
  },
  {
    bankCardCodes: "624458",
    bankNames: "中国建设银行亚洲股份有限公司",
  },
  {
    bankCardCodes: "62354290",
    bankNames: "娄烦县三禾村镇银行",
  },
  {
    bankCardCodes: "621678715",
    bankNames: "福泉富民村镇银行",
  },
  {
    bankCardCodes: "621678710",
    bankNames: "安顺西秀富民村镇银行",
  },
  {
    bankCardCodes: "621678712",
    bankNames: "遵义红花岗富民村镇银行",
  },
  {
    bankCardCodes: "621678711",
    bankNames: "贵阳南明富民村镇银行",
  },
  {
    bankCardCodes: "621678713",
    bankNames: "开阳富民村镇银行",
  },
  {
    bankCardCodes: "621678102",
    bankNames: "浙江洞头富民村镇银行",
  },
  {
    bankCardCodes: "621678108",
    bankNames: "上海松江富民村镇银行",
  },
  {
    bankCardCodes: "621347107",
    bankNames: "重庆黔江银座村镇银行",
  },
  {
    bankCardCodes: "621347105",
    bankNames: "浙江三门银座村镇银行",
  },
  {
    bankCardCodes: "62134627",
    bankNames: "江苏丹徒蒙银村镇银行",
  },
  {
    bankCardCodes: "622299",
    bankNames: "中国工商银行",
  },
  {
    bankCardCodes: "628354",
    bankNames: "晋中银行",
  },
  {
    bankCardCodes: "625992",
    bankNames: "江西省农村信用社联合社",
  },
  {
    bankCardCodes: "625189",
    bankNames: "江苏省农村信用社联合社",
  },
  {
    bankCardCodes: "628243",
    bankNames: "晋城银行",
  },
  {
    bankCardCodes: "628276",
    bankNames: "南充市商业银行",
  },
  {
    bankCardCodes: "625192",
    bankNames: "南充市商业银行",
  },
  {
    bankCardCodes: "625157",
    bankNames: "富滇银行",
  },
  {
    bankCardCodes: "625195",
    bankNames: "九江银行",
  },
  {
    bankCardCodes: "62519301",
    bankNames: "贵阳银行股份有限公司",
  },
  {
    bankCardCodes: "628349",
    bankNames: "张家港农村商业银行",
  },
  {
    bankCardCodes: "625208",
    bankNames: "张家港农村商业银行",
  },
  {
    bankCardCodes: "625191",
    bankNames: "恒丰银行",
  },
  {
    bankCardCodes: "625974",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "62053801",
    bankNames: "中银通商务支付有限公司",
  },
  {
    bankCardCodes: "62348001",
    bankNames: "CAPITRON BANK",
  },
  {
    bankCardCodes: "62348000",
    bankNames: "CAPITRON BANK",
  },
  {
    bankCardCodes: "62444501",
    bankNames: "KANBAWZA BANK LIMITED",
  },
  {
    bankCardCodes: "62444500",
    bankNames: "KANBAWZA BANK LIMITED",
  },
  {
    bankCardCodes: "62347600",
    bankNames: "KANBAWZA BANK LIMITED",
  },
  {
    bankCardCodes: "62443800",
    bankNames: "MetaBank",
  },
  {
    bankCardCodes: "62443002",
    bankNames: "AYA BANK",
  },
  {
    bankCardCodes: "62443001",
    bankNames: "AYA BANK",
  },
  {
    bankCardCodes: "62443000",
    bankNames: "AYA BANK",
  },
  {
    bankCardCodes: "62346600",
    bankNames: "AYA BANK",
  },
  {
    bankCardCodes: "624425",
    bankNames: "Cambodia Asia Bank",
  },
  {
    bankCardCodes: "624423",
    bankNames: "Cambodia Asia Bank",
  },
  {
    bankCardCodes: "62442601",
    bankNames: "VATTANAC BANK LIMITED",
  },
  {
    bankCardCodes: "62442600",
    bankNames: "VATTANAC BANK LIMITED",
  },
  {
    bankCardCodes: "62346400",
    bankNames: "VATTANAC BANK LIMITED",
  },
  {
    bankCardCodes: "62348101",
    bankNames: "PJSCB ORIENT FINANS",
  },
  {
    bankCardCodes: "62348100",
    bankNames: "PJSCB ORIENT FINANS",
  },
  {
    bankCardCodes: "6234623",
    bankNames: "CJSC Spitamen Bank",
  },
  {
    bankCardCodes: "6234622",
    bankNames: "CJSC Spitamen Bank",
  },
  {
    bankCardCodes: "6234621",
    bankNames: "CJSC Spitamen Bank",
  },
  {
    bankCardCodes: "6234620",
    bankNames: "CJSC Spitamen Bank",
  },
  {
    bankCardCodes: "6292791",
    bankNames: "Al Tail Money Transfer",
  },
  {
    bankCardCodes: "6292790",
    bankNames: "Al Tail Money Transfer",
  },
  {
    bankCardCodes: "6263741",
    bankNames: "Tsesnabank Joint Stock Company",
  },
  {
    bankCardCodes: "6263740",
    bankNames: "Tsesnabank Joint Stock Company",
  },
  {
    bankCardCodes: "6234671",
    bankNames: "Tsesnabank Joint Stock Company",
  },
  {
    bankCardCodes: "6234670",
    bankNames: "Tsesnabank Joint Stock Company",
  },
  {
    bankCardCodes: "6292711",
    bankNames: "Asia Merchant Bank Limited",
  },
  {
    bankCardCodes: "6292710",
    bankNames: "Asia Merchant Bank Limited",
  },
  {
    bankCardCodes: "6234441",
    bankNames: "Asia Merchant Bank Limited",
  },
  {
    bankCardCodes: "6234440",
    bankNames: "Asia Merchant Bank Limited",
  },
  {
    bankCardCodes: "6244166",
    bankNames: "Financiera Cuallix",
  },
  {
    bankCardCodes: "6234453",
    bankNames: "Joint-Stock bank",
  },
  {
    bankCardCodes: "6234452",
    bankNames: "Joint-Stock bank",
  },
  {
    bankCardCodes: "6234451",
    bankNames: "Joint-Stock bank",
  },
  {
    bankCardCodes: "6234450",
    bankNames: "Joint-Stock bank",
  },
  {
    bankCardCodes: "6234300",
    bankNames: "Land and Houses Bank PCL",
  },
  {
    bankCardCodes: "6234220",
    bankNames: "Kiatnakin Bank Pubulic CL",
  },
  {
    bankCardCodes: "623477",
    bankNames: "SmartChoice Technologies",
  },
  {
    bankCardCodes: "623459",
    bankNames: "SmartChoice Technologies",
  },
  {
    bankCardCodes: "62341409",
    bankNames: "XacBank",
  },
  {
    bankCardCodes: "62341408",
    bankNames: "XacBank",
  },
  {
    bankCardCodes: "62341407",
    bankNames: "XacBank",
  },
  {
    bankCardCodes: "6234470",
    bankNames: "PJSC Bank ZENIT",
  },
  {
    bankCardCodes: "6221740",
    bankNames: "NXSystems Limited",
  },
  {
    bankCardCodes: "62348700",
    bankNames: "KCB BANK LIMITED",
  },
  {
    bankCardCodes: "62441901",
    bankNames: "Co-Operative Bank Limited",
  },
  {
    bankCardCodes: "62334913",
    bankNames: "PVB Card Corporation",
  },
  {
    bankCardCodes: "62334923",
    bankNames: "PVB Card Corporation",
  },
  {
    bankCardCodes: "62334921",
    bankNames: "PVB Card Corporation",
  },
  {
    bankCardCodes: "626398",
    bankNames: "Jsc Basisbank",
  },
  {
    bankCardCodes: "624429",
    bankNames: "Jsc Basisbank",
  },
  {
    bankCardCodes: "624428",
    bankNames: "Jsc Basisbank",
  },
  {
    bankCardCodes: "6292750",
    bankNames: "格鲁吉亚 Invest Bank",
  },
  {
    bankCardCodes: "62443101",
    bankNames: "Myanmar Oriental Bank Ltd",
  },
  {
    bankCardCodes: "62443100",
    bankNames: "Myanmar Oriental Bank Ltd",
  },
  {
    bankCardCodes: "624432",
    bankNames: "新韩卡公司",
  },
  {
    bankCardCodes: "624443",
    bankNames: "韩国三星卡公司",
  },
  {
    bankCardCodes: "624442",
    bankNames: "韩国三星卡公司",
  },
  {
    bankCardCodes: "624441",
    bankNames: "韩国三星卡公司",
  },
  {
    bankCardCodes: "624440",
    bankNames: "韩国三星卡公司",
  },
  {
    bankCardCodes: "624434",
    bankNames: "韩国KB",
  },
  {
    bankCardCodes: "624375",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "624448",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "623457",
    bankNames: "Canadia Bank PLC",
  },
  {
    bankCardCodes: "623456",
    bankNames: "Canadia Bank PLC",
  },
  {
    bankCardCodes: "62927700",
    bankNames: "可汗银行",
  },
  {
    bankCardCodes: "62445400",
    bankNames: "可汗银行",
  },
  {
    bankCardCodes: "623488",
    bankNames: "PUBLIC BANK BERHAD",
  },
  {
    bankCardCodes: "623465",
    bankNames: "中信嘉华银行有限公司",
  },
  {
    bankCardCodes: "62314259",
    bankNames: "庆云乐安村镇银行",
  },
  {
    bankCardCodes: "62314289",
    bankNames: "昌乐乐安村镇银行",
  },
  {
    bankCardCodes: "62134612",
    bankNames: "鄂尔多斯市天骄蒙银村镇银行",
  },
  {
    bankCardCodes: "62367291",
    bankNames: "平昌农科村镇银行",
  },
  {
    bankCardCodes: "62367292",
    bankNames: "南江农科村镇银行",
  },
  {
    bankCardCodes: "623567002",
    bankNames: "上蔡惠民村镇银行",
  },
  {
    bankCardCodes: "623567001",
    bankNames: "禹州新民生村镇银行",
  },
  {
    bankCardCodes: "62354901",
    bankNames: "安徽固镇新淮河村镇银行",
  },
  {
    bankCardCodes: "62365507",
    bankNames: "五大连池惠丰村镇银行",
  },
  {
    bankCardCodes: "62365506",
    bankNames: "海伦惠丰村镇银行",
  },
  {
    bankCardCodes: "62365503",
    bankNames: "宝清广益村镇银行",
  },
  {
    bankCardCodes: "62365501",
    bankNames: "哈尔滨农信村镇银行",
  },
  {
    bankCardCodes: "62365502",
    bankNames: "哈尔滨宾州村镇银行",
  },
  {
    bankCardCodes: "62365504",
    bankNames: "依兰惠鑫村镇银行",
  },
  {
    bankCardCodes: "62365207",
    bankNames: "昆明宜良融丰村镇银行",
  },
  {
    bankCardCodes: "62365206",
    bankNames: "昆明晋宁融丰村镇银行",
  },
  {
    bankCardCodes: "62365209",
    bankNames: "五峰金谷村镇银行",
  },
  {
    bankCardCodes: "62365208",
    bankNames: "远安金古村镇银行",
  },
  {
    bankCardCodes: "62365205",
    bankNames: "平和润丰村镇银行",
  },
  {
    bankCardCodes: "62365204",
    bankNames: "东山润鑫村镇银行",
  },
  {
    bankCardCodes: "62365203",
    bankNames: "云霄润发村镇银行",
  },
  {
    bankCardCodes: "62365202",
    bankNames: "深州丰源村镇银行",
  },
  {
    bankCardCodes: "62365178",
    bankNames: "武安村镇银行",
  },
  {
    bankCardCodes: "62354213",
    bankNames: "永济市三禾村镇银行",
  },
  {
    bankCardCodes: "62354215",
    bankNames: "阳城县三禾村镇银行",
  },
  {
    bankCardCodes: "62354211",
    bankNames: "屯留县三禾村镇银行",
  },
  {
    bankCardCodes: "62365002",
    bankNames: "农安北银村镇银行",
  },
  {
    bankCardCodes: "62361982",
    bankNames: "岚县慧融村镇银行",
  },
  {
    bankCardCodes: "62361912",
    bankNames: "保德县慧融村镇银行",
  },
  {
    bankCardCodes: "62363585",
    bankNames: "浙江浦江嘉银村镇银行",
  },
  {
    bankCardCodes: "623626704",
    bankNames: "东营融和村镇银行",
  },
  {
    bankCardCodes: "623626706",
    bankNames: "献县融和村镇银行",
  },
  {
    bankCardCodes: "62364201",
    bankNames: "呼和浩特市赛罕金谷村镇银行",
  },
  {
    bankCardCodes: "62361346",
    bankNames: "浙江衢州衢江上银村镇银行",
  },
  {
    bankCardCodes: "62361345",
    bankNames: "上海闵行上银村镇银行",
  },
  {
    bankCardCodes: "62361701",
    bankNames: "西昌金信村镇银行",
  },
  {
    bankCardCodes: "62358302",
    bankNames: "兴城长兴村镇银行",
  },
  {
    bankCardCodes: "62358301",
    bankNames: "建昌恒昌村镇银行",
  },
  {
    bankCardCodes: "62358303",
    bankNames: "绥中长丰村镇银行",
  },
  {
    bankCardCodes: "623580001",
    bankNames: "浙江嵊州瑞丰村镇银行",
  },
  {
    bankCardCodes: "62368004",
    bankNames: "辽宁台安金安村镇银行",
  },
  {
    bankCardCodes: "62358421",
    bankNames: "广饶梁邹村镇银行",
  },
  {
    bankCardCodes: "623558613",
    bankNames: "鄱阳恒通村镇银行",
  },
  {
    bankCardCodes: "623558615",
    bankNames: "九江恒通村镇银行",
  },
  {
    bankCardCodes: "62314901",
    bankNames: "荥阳利丰村镇银行",
  },
  {
    bankCardCodes: "623145001",
    bankNames: "余姚通济村镇银行",
  },
  {
    bankCardCodes: "623530963",
    bankNames: "滦平盛阳村镇银行",
  },
  {
    bankCardCodes: "62352784",
    bankNames: "安顺西航南马村镇银行",
  },
  {
    bankCardCodes: "62352737",
    bankNames: "平坝鼎立村镇银行",
  },
  {
    bankCardCodes: "62352760",
    bankNames: "六盘水钟山凉都村镇银行",
  },
  {
    bankCardCodes: "623504005",
    bankNames: "台前德商村镇银行",
  },
  {
    bankCardCodes: "623522001",
    bankNames: "奉化罗蒙村镇银行",
  },
  {
    bankCardCodes: "623607013",
    bankNames: "澳洲联邦（涉县）村镇银行",
  },
  {
    bankCardCodes: "623607011",
    bankNames: "澳洲联邦（成安）村镇银行",
  },
  {
    bankCardCodes: "623607015",
    bankNames: "澳洲联邦（魏县）村镇银行",
  },
  {
    bankCardCodes: "623607014",
    bankNames: "澳洲联邦（邯郸县）村镇银行",
  },
  {
    bankCardCodes: "623607012",
    bankNames: "澳洲联邦（栾城）村镇银行",
  },
  {
    bankCardCodes: "62350801",
    bankNames: "珠海横琴村镇银行",
  },
  {
    bankCardCodes: "62138410",
    bankNames: "高唐青隆村镇银行",
  },
  {
    bankCardCodes: "62138409",
    bankNames: "东阿青隆村镇银行",
  },
  {
    bankCardCodes: "62138406",
    bankNames: "莘县青隆村镇银行",
  },
  {
    bankCardCodes: "62138408",
    bankNames: "鱼台青隆村镇银行",
  },
  {
    bankCardCodes: "62138405",
    bankNames: "山东周村青隆村镇银行",
  },
  {
    bankCardCodes: "62138407",
    bankNames: "桓台青隆村镇银行",
  },
  {
    bankCardCodes: "62127877",
    bankNames: "扶余惠民村镇银行",
  },
  {
    bankCardCodes: "62127827",
    bankNames: "洮南惠民村镇银行",
  },
  {
    bankCardCodes: "62127858",
    bankNames: "白城洮北惠民村镇银行",
  },
  {
    bankCardCodes: "621381002",
    bankNames: "淮安光大村镇银行",
  },
  {
    bankCardCodes: "62169504",
    bankNames: "扶沟郑银村镇银行",
  },
  {
    bankCardCodes: "621682838",
    bankNames: "临沧临翔沪农商村镇银行",
  },
  {
    bankCardCodes: "621682837",
    bankNames: "保山隆阳沪农商村镇银行",
  },
  {
    bankCardCodes: "621682835",
    bankNames: "弥勒沪农商村镇银行",
  },
  {
    bankCardCodes: "621682836",
    bankNames: "建水沪农商村镇银行",
  },
  {
    bankCardCodes: "621682832",
    bankNames: "蒙自沪农商村镇银行",
  },
  {
    bankCardCodes: "621682833",
    bankNames: "开远沪农商村镇银行",
  },
  {
    bankCardCodes: "621682831",
    bankNames: "个旧沪农商村镇银行",
  },
  {
    bankCardCodes: "621682829",
    bankNames: "嵩明沪农商村镇银行",
  },
  {
    bankCardCodes: "621682828",
    bankNames: "阿拉沪农商村镇银行",
  },
  {
    bankCardCodes: "621682802",
    bankNames: "深圳光明沪农商村镇银行",
  },
  {
    bankCardCodes: "621682819",
    bankNames: "永兴沪农商村镇银行",
  },
  {
    bankCardCodes: "621682820",
    bankNames: "桂阳沪农商村镇银行",
  },
  {
    bankCardCodes: "621682826",
    bankNames: "双峰沪农商村镇银行",
  },
  {
    bankCardCodes: "621682825",
    bankNames: "涟源沪农商村镇银行",
  },
  {
    bankCardCodes: "621682827",
    bankNames: "慈利沪农商村镇银行",
  },
  {
    bankCardCodes: "621682823",
    bankNames: "石门沪农商村镇银行",
  },
  {
    bankCardCodes: "621682821",
    bankNames: "临澧沪农商村镇银行",
  },
  {
    bankCardCodes: "621682822",
    bankNames: "澧县沪农商村镇银行",
  },
  {
    bankCardCodes: "621682818",
    bankNames: "衡阳沪农商村镇银行",
  },
  {
    bankCardCodes: "621682817",
    bankNames: "醴陵沪农商村镇银行",
  },
  {
    bankCardCodes: "621682815",
    bankNames: "宁乡沪农商行村镇银行",
  },
  {
    bankCardCodes: "621682816",
    bankNames: "长沙星沙沪农商村镇银行",
  },
  {
    bankCardCodes: "621682809",
    bankNames: "日照沪农商村镇银行",
  },
  {
    bankCardCodes: "621682810",
    bankNames: "茌平沪农商村镇银行",
  },
  {
    bankCardCodes: "621682811",
    bankNames: "阳谷沪农商村镇银行",
  },
  {
    bankCardCodes: "621682813",
    bankNames: "临清沪农商村镇银行",
  },
  {
    bankCardCodes: "621682812",
    bankNames: "聊城沪农商村镇银行",
  },
  {
    bankCardCodes: "621682807",
    bankNames: "东平沪农商村镇银行",
  },
  {
    bankCardCodes: "621682806",
    bankNames: "宁阳沪农商村镇银行",
  },
  {
    bankCardCodes: "621682808",
    bankNames: "泰安沪农商村镇银行",
  },
  {
    bankCardCodes: "621682805",
    bankNames: "济南槐荫沪农商村镇银行",
  },
  {
    bankCardCodes: "621682839",
    bankNames: "上海崇明沪农商村镇银行",
  },
  {
    bankCardCodes: "621682801",
    bankNames: "北京房山沪农商村镇银行",
  },
  {
    bankCardCodes: "621681005",
    bankNames: "阜新农商村镇银行",
  },
  {
    bankCardCodes: "621678706",
    bankNames: "赫章富民村镇银行",
  },
  {
    bankCardCodes: "621678705",
    bankNames: "威宁富民村镇银行",
  },
  {
    bankCardCodes: "621678703",
    bankNames: "纳雍富民村镇银行",
  },
  {
    bankCardCodes: "621678701",
    bankNames: "金沙富民村镇银行",
  },
  {
    bankCardCodes: "621678702",
    bankNames: "大方富民村镇银行",
  },
  {
    bankCardCodes: "621678709",
    bankNames: "贵阳云岩富民村镇银行",
  },
  {
    bankCardCodes: "621678708",
    bankNames: "贵阳乌当富民村镇银行",
  },
  {
    bankCardCodes: "621678107",
    bankNames: "浙江仙居富民村镇银行",
  },
  {
    bankCardCodes: "621678601",
    bankNames: "浙江遂昌富民村镇银行",
  },
  {
    bankCardCodes: "621678103",
    bankNames: "浙江东阳富民村镇银行",
  },
  {
    bankCardCodes: "62163126",
    bankNames: "东莞黄江珠江村镇银行",
  },
  {
    bankCardCodes: "621650104",
    bankNames: "乌拉特前旗大众村镇银行",
  },
  {
    bankCardCodes: "621650105",
    bankNames: "杭锦大众村镇银行",
  },
  {
    bankCardCodes: "623143004",
    bankNames: "太谷县兴泰村镇银行",
  },
  {
    bankCardCodes: "623143005",
    bankNames: "古交市阜民村镇银行",
  },
  {
    bankCardCodes: "621365014",
    bankNames: "安徽肥东湖商村镇银行",
  },
  {
    bankCardCodes: "621365013",
    bankNames: "浙江临海湖商村镇银行",
  },
  {
    bankCardCodes: "621347106",
    bankNames: "重庆渝北银座村镇银行",
  },
  {
    bankCardCodes: "621347103",
    bankNames: "江西赣州银座村镇银行",
  },
  {
    bankCardCodes: "621347102",
    bankNames: "北京顺义银座村镇银行",
  },
  {
    bankCardCodes: "621356056",
    bankNames: "邻水中银富登村镇银行",
  },
  {
    bankCardCodes: "621356035",
    bankNames: "岳池中银富登村镇银行",
  },
  {
    bankCardCodes: "621356033",
    bankNames: "蓬溪中银富登村镇银行",
  },
  {
    bankCardCodes: "621356051",
    bankNames: "杞县中银富登村镇银行",
  },
  {
    bankCardCodes: "62354331",
    bankNames: "仁怀蒙银村镇银行",
  },
  {
    bankCardCodes: "62134623",
    bankNames: "潍坊市寒亭区蒙银村镇银行",
  },
  {
    bankCardCodes: "621346102",
    bankNames: "方大村镇银行",
  },
  {
    bankCardCodes: "621241004",
    bankNames: "江苏宿豫东吴村镇银行",
  },
  {
    bankCardCodes: "621241002",
    bankNames: "江苏泗洪东吴村镇银行",
  },
  {
    bankCardCodes: "621241003",
    bankNames: "江苏泗阳东吴村镇银行",
  },
  {
    bankCardCodes: "62109210",
    bankNames: "浙江常山联合村镇银行",
  },
  {
    bankCardCodes: "621260118",
    bankNames: "宜昌夷陵兴福村镇银行",
  },
  {
    bankCardCodes: "621260102",
    bankNames: "恩施兴福村镇银行",
  },
  {
    bankCardCodes: "621260120",
    bankNames: "长阳兴福村镇银行",
  },
  {
    bankCardCodes: "621260122",
    bankNames: "秭归兴福村镇银行",
  },
  {
    bankCardCodes: "621260116",
    bankNames: "当阳兴福村镇银行",
  },
  {
    bankCardCodes: "621260110",
    bankNames: "内黄兴福村镇银行",
  },
  {
    bankCardCodes: "621260106",
    bankNames: "汤阴兴福村镇银行",
  },
  {
    bankCardCodes: "621260108",
    bankNames: "宿迁宿城兴福村镇银行",
  },
  {
    bankCardCodes: "621260107",
    bankNames: "泰州高港兴福村镇银行",
  },
  {
    bankCardCodes: "621260113",
    bankNames: "扬州高邮兴福村镇银行",
  },
  {
    bankCardCodes: "621260117",
    bankNames: "盐城滨海兴福村镇银行",
  },
  {
    bankCardCodes: "621260105",
    bankNames: "淮安清浦兴福村镇银行",
  },
  {
    bankCardCodes: "621260121",
    bankNames: "淮安淮阴兴福村镇银行",
  },
  {
    bankCardCodes: "621260119",
    bankNames: "无锡滨湖兴福村镇银行",
  },
  {
    bankCardCodes: "623503001",
    bankNames: "江苏靖江润丰村镇银行",
  },
  {
    bankCardCodes: "623628",
    bankNames: "陕西秦农农村商业银行",
  },
  {
    bankCardCodes: "623603",
    bankNames: "大连农村商业银行",
  },
  {
    bankCardCodes: "625183",
    bankNames: "甘肃省农村信用社联合社",
  },
  {
    bankCardCodes: "625226",
    bankNames: "安徽省农村信用社联合社",
  },
  {
    bankCardCodes: "625225",
    bankNames: "安徽省农村信用社联合社",
  },
  {
    bankCardCodes: "625201",
    bankNames: "甘肃银行",
  },
  {
    bankCardCodes: "623293",
    bankNames: "广东华兴银行",
  },
  {
    bankCardCodes: "621551",
    bankNames: "长安银行",
  },
  {
    bankCardCodes: "623285",
    bankNames: "南充市商业银行",
  },
  {
    bankCardCodes: "625190",
    bankNames: "丹东银行",
  },
  {
    bankCardCodes: "621552",
    bankNames: "贵阳银行股份有限公司",
  },
  {
    bankCardCodes: "623291",
    bankNames: "徽商银行",
  },
  {
    bankCardCodes: "625811",
    bankNames: "盛京银行",
  },
  {
    bankCardCodes: "62326531",
    bankNames: "温州银行",
  },
  {
    bankCardCodes: "625102",
    bankNames: "汇丰银行（中国）",
  },
  {
    bankCardCodes: "623287",
    bankNames: "兴业银行",
  },
  {
    bankCardCodes: "62326536",
    bankNames: "招商银行",
  },
  {
    bankCardCodes: "625657",
    bankNames: "招商银行",
  },
  {
    bankCardCodes: "62364873",
    bankNames: "广发银行股份有限公司",
  },
  {
    bankCardCodes: "621692",
    bankNames: "民生银行",
  },
  {
    bankCardCodes: "623292",
    bankNames: "华夏银行",
  },
  {
    bankCardCodes: "62844800",
    bankNames: "建设银行",
  },
  {
    bankCardCodes: "62844802",
    bankNames: "中国银行",
  },
  {
    bankCardCodes: "625818",
    bankNames: "中国农业银行贷记卡",
  },
  {
    bankCardCodes: "62844803",
    bankNames: "中国农业银行贷记卡",
  },
  {
    bankCardCodes: "623056",
    bankNames: "农业银行",
  },
  {
    bankCardCodes: "623053",
    bankNames: "农业银行",
  },
  {
    bankCardCodes: "623050",
    bankNames: "农业银行",
  },
  {
    bankCardCodes: "62844801",
    bankNames: "中国工商银行",
  },
  {
    bankCardCodes: "625605",
    bankNames: "中国邮政储蓄银行信用卡中心",
  },
  {
    bankCardCodes: "625603",
    bankNames: "中国邮政储蓄银行信用卡中心",
  },
  {
    bankCardCodes: "622835",
    bankNames: "中国邮政储蓄银行信用卡中心",
  },
  {
    bankCardCodes: "518905",
    bankNames: "中国邮政储蓄银行信用卡中心",
  },
  {
    bankCardCodes: "625367",
    bankNames: "中国邮政储蓄银行信用卡中心",
  },
  {
    bankCardCodes: "625368",
    bankNames: "中国邮政储蓄银行信用卡中心",
  },
  {
    bankCardCodes: "625919",
    bankNames: "中国邮政储蓄银行信用卡中心",
  },
  {
    bankCardCodes: "628310",
    bankNames: "中国邮政储蓄银行信用卡中心",
  },
  {
    bankCardCodes: "622811",
    bankNames: "中国邮政储蓄银行信用卡中心",
  },
  {
    bankCardCodes: "622810",
    bankNames: "中国邮政储蓄银行信用卡中心",
  },
  {
    bankCardCodes: "622812",
    bankNames: "中国邮政储蓄银行信用卡中心",
  },
  {
    bankCardCodes: "623677",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "623676",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "621582",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "622150",
    bankNames: "邮储银行",
  },
  {
    bankCardCodes: "622151",
    bankNames: "邮储银行",
  },
  {
    bankCardCodes: "622181",
    bankNames: "邮储银行",
  },
  {
    bankCardCodes: "622188",
    bankNames: "邮储银行",
  },
  {
    bankCardCodes: "955100",
    bankNames: "邮储银行",
  },
  {
    bankCardCodes: "621095",
    bankNames: "邮储银行",
  },
  {
    bankCardCodes: "620062",
    bankNames: "邮储银行",
  },
  {
    bankCardCodes: "621285",
    bankNames: "邮储银行",
  },
  {
    bankCardCodes: "621798",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "621799",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "621797",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "622199",
    bankNames: "邮储银行",
  },
  {
    bankCardCodes: "621096",
    bankNames: "邮储银行",
  },
  {
    bankCardCodes: "62215049",
    bankNames: "邮储银行河南分行",
  },
  {
    bankCardCodes: "62215050",
    bankNames: "邮储银行河南分行",
  },
  {
    bankCardCodes: "62215051",
    bankNames: "邮储银行河南分行",
  },
  {
    bankCardCodes: "62218849",
    bankNames: "邮储银行河南分行",
  },
  {
    bankCardCodes: "62218850",
    bankNames: "邮储银行河南分行",
  },
  {
    bankCardCodes: "62218851",
    bankNames: "邮储银行河南分行",
  },
  {
    bankCardCodes: "621622",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "623219",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "621674",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "623218",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "621599",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "623698",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "623699",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "623686",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "622182",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "625586",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "621098",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "620529",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "622180",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "622187",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "622189",
    bankNames: "邮政储蓄银行",
  },
  {
    bankCardCodes: "62926923",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926922",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926921",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926920",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926919",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926918",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926917",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926916",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926915",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926914",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926913",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926912",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926911",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926910",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926909",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926908",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926907",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926906",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926905",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926904",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926903",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926902",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926901",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926900",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926039",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926038",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926037",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926036",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926035",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926034",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926033",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926032",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926031",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926030",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926029",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926028",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926027",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926026",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926025",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926024",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926023",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926022",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926021",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926020",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926019",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926018",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926017",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926016",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926015",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926014",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926013",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926012",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926011",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926010",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926009",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926008",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926007",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926006",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926005",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926004",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926003",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926002",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926001",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62926000",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62636009",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62636008",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62636007",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62636006",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62636005",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62636004",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62636003",
    bankNames: "银国际支付标记",
  },
  {
    bankCardCodes: "62636002",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62636001",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "62636000",
    bankNames: "银联国际支付标记",
  },
  {
    bankCardCodes: "623644",
    bankNames: "中国建设银行",
  },
  {
    bankCardCodes: "621260",
    bankNames: "常熟农村商业银行",
  },
  {
    bankCardCodes: "518701",
    bankNames: "招商银行信用卡中心",
  },
  {
    bankCardCodes: "451461",
    bankNames: "招商银行信用卡中心",
  },
  {
    bankCardCodes: "623052",
    bankNames: "农业银行",
  },
  {
    bankCardCodes: "62441900",
    bankNames: "Co-Operative Bank Limited",
  },
  {
    bankCardCodes: "62362401",
    bankNames: "临沂河东齐商村镇银行",
  },
  {
    bankCardCodes: "62134607",
    bankNames: "乌拉特中旗蒙银村镇银行",
  },
  {
    bankCardCodes: "62134626",
    bankNames: "磴口蒙银村镇银行",
  },
  {
    bankCardCodes: "62134624",
    bankNames: "鄂托克前旗蒙银村镇银行",
  },
  {
    bankCardCodes: "62134611",
    bankNames: "鄂尔多斯市铁西蒙银村镇银行",
  },
  {
    bankCardCodes: "62134613",
    bankNames: "四子王蒙银村镇银行",
  },
  {
    bankCardCodes: "628377",
    bankNames: "邯郸银行",
  },
  {
    bankCardCodes: "623562",
    bankNames: "北京银行",
  },
  {
    bankCardCodes: "62442240",
    bankNames: "ICBC(USA) NA",
  },
  {
    bankCardCodes: "6244223",
    bankNames: "ICBC(USA) NA",
  },
  {
    bankCardCodes: "6244222",
    bankNames: "ICBC(USA) NA",
  },
  {
    bankCardCodes: "6244221",
    bankNames: "ICBC(USA) NA",
  },
  {
    bankCardCodes: "6244220",
    bankNames: "ICBC(USA) NA",
  },
  {
    bankCardCodes: "62431940",
    bankNames: "ICBC(USA) NA",
  },
  {
    bankCardCodes: "6243193",
    bankNames: "ICBC(USA) NA",
  },
  {
    bankCardCodes: "6243192",
    bankNames: "ICBC(USA) NA",
  },
  {
    bankCardCodes: "6243191",
    bankNames: "ICBC(USA) NA",
  },
  {
    bankCardCodes: "6243190",
    bankNames: "ICBC(USA) NA",
  },
  {
    bankCardCodes: "625651",
    bankNames: "中国工商银行",
  },
  {
    bankCardCodes: "624405",
    bankNames: "中行新加坡分行",
  },
  {
    bankCardCodes: "6227899",
    bankNames: "中行新加坡分行",
  },
  {
    bankCardCodes: "6227898",
    bankNames: "中行新加坡分行",
  },
  {
    bankCardCodes: "6227897",
    bankNames: "中行新加坡分行",
  },
  {
    bankCardCodes: "6227896",
    bankNames: "中行新加坡分行",
  },
  {
    bankCardCodes: "6227895",
    bankNames: "中行新加坡分行",
  },
  {
    bankCardCodes: "6227894",
    bankNames: "中行新加坡分行",
  },
  {
    bankCardCodes: "6227893",
    bankNames: "中行新加坡分行",
  },
  {
    bankCardCodes: "6227892",
    bankNames: "中行新加坡分行",
  },
  {
    bankCardCodes: "6227891",
    bankNames: "中行新加坡分行",
  },
  {
    bankCardCodes: "6227890",
    bankNames: "中行新加坡分行",
  },
  {
    bankCardCodes: "622790",
    bankNames: "中行新加坡分行",
  },
  {
    bankCardCodes: "625943",
    bankNames: "Bank of China(Malaysia)",
  },
  {
    bankCardCodes: "625829",
    bankNames: "Bank of China(Malaysia)",
  },
  {
    bankCardCodes: "623423",
    bankNames: "Bank of China,Canada",
  },
  {
    bankCardCodes: "628328",
    bankNames: "华融湘江银行",
  },
  {
    bankCardCodes: "625886",
    bankNames: "华融湘江银行",
  },
  {
    bankCardCodes: "628271",
    bankNames: "商丘市商业银行",
  },
  {
    bankCardCodes: "625156",
    bankNames: "武汉农村商业银行",
  },
  {
    bankCardCodes: "625152",
    bankNames: "天津滨海农村商业银行",
  },
  {
    bankCardCodes: "620528",
    bankNames: "邢台银行",
  },
  {
    bankCardCodes: "623637",
    bankNames: "安徽省农村信用社",
  },
  {
    bankCardCodes: "623220",
    bankNames: "安徽省农村信用社",
  },
  {
    bankCardCodes: "621550",
    bankNames: "安徽省农村信用社",
  },
  {
    bankCardCodes: "621778",
    bankNames: "安徽省农村信用社",
  },
  {
    bankCardCodes: "628392",
    bankNames: "江西省农村信用社联合社",
  },
  {
    bankCardCodes: "623092",
    bankNames: "江西省农村信用社联合社",
  },
  {
    bankCardCodes: "625519",
    bankNames: "湖南省农村信用联合社",
  },
  {
    bankCardCodes: "628386",
    bankNames: "湖南农村信用社联合社",
  },
  {
    bankCardCodes: "622906",
    bankNames: "湖南农村信用社联合社",
  },
  {
    bankCardCodes: "625506",
    bankNames: "湖南农信",
  },
  {
    bankCardCodes: "628369",
    bankNames: "无锡农村商业银行",
  },
  {
    bankCardCodes: "622569",
    bankNames: "无锡农村商业银行",
  },
  {
    bankCardCodes: "625158",
    bankNames: "临汾市尧都区农村信用合作联社",
  },
  {
    bankCardCodes: "628382",
    bankNames: "临汾市尧都区农村信用合作联社",
  },
  {
    bankCardCodes: "625820",
    bankNames: "临汾市尧都区农村信用合作联社",
  },
  {
    bankCardCodes: "628284",
    bankNames: "泰安银行",
  },
  {
    bankCardCodes: "628217",
    bankNames: "东营银行",
  },
  {
    bankCardCodes: "621416",
    bankNames: "上饶银行",
  },
  {
    bankCardCodes: "628223",
    bankNames: "上饶银行",
  },
  {
    bankCardCodes: "628226",
    bankNames: "江苏省农村信用社联合社",
  },
  {
    bankCardCodes: "622816",
    bankNames: "江苏省农村信用社联合社",
  },
  {
    bankCardCodes: "622815",
    bankNames: "江苏省农村信用社联合社",
  },
  {
    bankCardCodes: "623088",
    bankNames: "成都农村商业银行股份有限公司",
  },
  {
    bankCardCodes: "628308",
    bankNames: "成都农村商业银行股份有限公司",
  },
  {
    bankCardCodes: "622808",
    bankNames: "成都农村商业银行股份有限公司",
  },
  {
    bankCardCodes: "628301",
    bankNames: "天津农村商业银行",
  },
  {
    bankCardCodes: "625819",
    bankNames: "天津农村商业银行",
  },
  {
    bankCardCodes: "622829",
    bankNames: "天津农村商业银行",
  },
  {
    bankCardCodes: "625357",
    bankNames: "福建省农村信用社联合社",
  },
  {
    bankCardCodes: "625128",
    bankNames: "福建省农村信用社联合社",
  },
  {
    bankCardCodes: "628232",
    bankNames: "福建省农村信用社联合社",
  },
  {
    bankCardCodes: "622290",
    bankNames: "福建省农村信用社联合社",
  },
  {
    bankCardCodes: "622802",
    bankNames: "福建省农村信用社联合社",
  },
  {
    bankCardCodes: "628397",
    bankNames: "德州银行",
  },
  {
    bankCardCodes: "628229",
    bankNames: "承德银行",
  },
  {
    bankCardCodes: "628307",
    bankNames: "云南省农村信用社",
  },
  {
    bankCardCodes: "622469",
    bankNames: "云南省农村信用社",
  },
  {
    bankCardCodes: "625088",
    bankNames: "广东顺德农村商业银行",
  },
  {
    bankCardCodes: "628322",
    bankNames: "广东顺德农村商业银行",
  },
  {
    bankCardCodes: "628235",
    bankNames: "广州农村商业银行",
  },
  {
    bankCardCodes: "625080",
    bankNames: "广州农村商业银行",
  },
  {
    bankCardCodes: "625701",
    bankNames: "上海农商银行贷记卡",
  },
  {
    bankCardCodes: "625989",
    bankNames: "上海农商银行贷记卡",
  },
  {
    bankCardCodes: "625500",
    bankNames: "上海农商银行贷记卡",
  },
  {
    bankCardCodes: "628211",
    bankNames: "上海农商银行贷记卡",
  },
  {
    bankCardCodes: "622722",
    bankNames: "上海农商银行贷记卡",
  },
  {
    bankCardCodes: "622611",
    bankNames: "上海农商银行贷记卡",
  },
  {
    bankCardCodes: "628373",
    bankNames: "柳州银行",
  },
  {
    bankCardCodes: "628293",
    bankNames: "柳州银行",
  },
  {
    bankCardCodes: "625090",
    bankNames: "龙江银行股份有限公司",
  },
  {
    bankCardCodes: "62536602",
    bankNames: "龙江银行股份有限公司",
  },
  {
    bankCardCodes: "625588",
    bankNames: "龙江银行",
  },
  {
    bankCardCodes: "628368",
    bankNames: "龙江银行股份有限公司",
  },
  {
    bankCardCodes: "622270",
    bankNames: "龙江银行股份有限公司",
  },
  {
    bankCardCodes: "625369",
    bankNames: "桂林银行",
  },
  {
    bankCardCodes: "625168",
    bankNames: "桂林银行",
  },
  {
    bankCardCodes: "628219",
    bankNames: "桂林银行",
  },
  {
    bankCardCodes: "628365",
    bankNames: "张家口市商业银行",
  },
  {
    bankCardCodes: "628237",
    bankNames: "广东南粤银行",
  },
  {
    bankCardCodes: "623128",
    bankNames: "广东南粤银行股份有限公司",
  },
  {
    bankCardCodes: "621727",
    bankNames: "广东南粤银行股份有限公司",
  },
  {
    bankCardCodes: "628234",
    bankNames: "威海市商业银行",
  },
  {
    bankCardCodes: "623001",
    bankNames: "广西北部湾银行",
  },
  {
    bankCardCodes: "628227",
    bankNames: "广西北部湾银行",
  },
  {
    bankCardCodes: "628306",
    bankNames: "湖州银行",
  },
  {
    bankCardCodes: "628252",
    bankNames: "内蒙古银行",
  },
  {
    bankCardCodes: "622287",
    bankNames: "浙江泰隆商业银行",
  },
  {
    bankCardCodes: "622717",
    bankNames: "浙江泰隆商业银行",
  },
  {
    bankCardCodes: "622565",
    bankNames: "浙江泰隆商业银行",
  },
  {
    bankCardCodes: "628275",
    bankNames: "浙江泰隆商业银行",
  },
  {
    bankCardCodes: "628327",
    bankNames: "嘉兴银行",
  },
  {
    bankCardCodes: "628353",
    bankNames: "阜新银行股份有限公司",
  },
  {
    bankCardCodes: "628231",
    bankNames: "富滇银行",
  },
  {
    bankCardCodes: "628264",
    bankNames: "营口银行",
  },
  {
    bankCardCodes: "628233",
    bankNames: "赣州银行股份有限公司",
  },
  {
    bankCardCodes: "628391",
    bankNames: "潍坊银行",
  },
  {
    bankCardCodes: "625129",
    bankNames: "江南农村商业银行",
  },
  {
    bankCardCodes: "62536601",
    bankNames: "青海银行",
  },
  {
    bankCardCodes: "625959",
    bankNames: "青海银行",
  },
  {
    bankCardCodes: "628287",
    bankNames: "青海银行",
  },
  {
    bankCardCodes: "622817",
    bankNames: "青海银行",
  },
  {
    bankCardCodes: "628257",
    bankNames: "日照银行",
  },
  {
    bankCardCodes: "628238",
    bankNames: "九江银行股份有限公司",
  },
  {
    bankCardCodes: "628239",
    bankNames: "青岛银行",
  },
  {
    bankCardCodes: "628361",
    bankNames: "南昌银行",
  },
  {
    bankCardCodes: "628305",
    bankNames: "南昌银行",
  },
  {
    bankCardCodes: "622718",
    bankNames: "南昌银行",
  },
  {
    bankCardCodes: "628263",
    bankNames: "兰州银行",
  },
  {
    bankCardCodes: "622416",
    bankNames: "兰州银行",
  },
  {
    bankCardCodes: "628345",
    bankNames: "西安银行",
  },
  {
    bankCardCodes: "625167",
    bankNames: "西安银行",
  },
  {
    bankCardCodes: "622533",
    bankNames: "贵阳银行股份有限公司",
  },
  {
    bankCardCodes: "628213",
    bankNames: "贵阳银行股份有限公司",
  },
  {
    bankCardCodes: "621752",
    bankNames: "哈尔滨银行",
  },
  {
    bankCardCodes: "625952",
    bankNames: "哈尔滨银行",
  },
  {
    bankCardCodes: "625577",
    bankNames: "哈尔滨银行",
  },
  {
    bankCardCodes: "625119",
    bankNames: "哈尔滨银行",
  },
  {
    bankCardCodes: "628224",
    bankNames: "哈尔滨商行",
  },
  {
    bankCardCodes: "622809",
    bankNames: "哈尔滨商行",
  },
  {
    bankCardCodes: "628220",
    bankNames: "重庆银行股份有限公司",
  },
  {
    bankCardCodes: "622613",
    bankNames: "重庆银行股份有限公司",
  },
  {
    bankCardCodes: "625358",
    bankNames: "徽商银行",
  },
  {
    bankCardCodes: "623664",
    bankNames: "徽商银行",
  },
  {
    bankCardCodes: "625700",
    bankNames: "徽商银行",
  },
  {
    bankCardCodes: "625652",
    bankNames: "徽商银行",
  },
  {
    bankCardCodes: "625828",
    bankNames: "徽商银行",
  },
  {
    bankCardCodes: "622651",
    bankNames: "徽商银行",
  },
  {
    bankCardCodes: "628251",
    bankNames: "徽商银行",
  },
  {
    bankCardCodes: "628261",
    bankNames: "锦州银行",
  },
  {
    bankCardCodes: "628294",
    bankNames: "锦州银行",
  },
  {
    bankCardCodes: "622710",
    bankNames: "锦州银行",
  },
  {
    bankCardCodes: "628311",
    bankNames: "齐商银行",
  },
  {
    bankCardCodes: "628270",
    bankNames: "珠海华润银行",
  },
  {
    bankCardCodes: "628341",
    bankNames: "贵州省农村信用联合社",
  },
  {
    bankCardCodes: "625163",
    bankNames: "郑州银行",
  },
  {
    bankCardCodes: "628359",
    bankNames: "临商银行",
  },
  {
    bankCardCodes: "625166",
    bankNames: "苏州银行",
  },
  {
    bankCardCodes: "628228",
    bankNames: "成都市商业银行",
  },
  {
    bankCardCodes: "622818",
    bankNames: "成都市商业银行",
  },
  {
    bankCardCodes: "622813",
    bankNames: "成都市商业银行",
  },
  {
    bankCardCodes: "621777",
    bankNames: "南京银行",
  },
  {
    bankCardCodes: "625800",
    bankNames: "杭州市商业银行",
  },
  {
    bankCardCodes: "628236",
    bankNames: "杭州市商业银行",
  },
  {
    bankCardCodes: "622286",
    bankNames: "杭州市商业银行",
  },
  {
    bankCardCodes: "603367",
    bankNames: "杭州市商业银行",
  },
  {
    bankCardCodes: "625598",
    bankNames: "河北银行股份有限公司",
  },
  {
    bankCardCodes: "628321",
    bankNames: "河北银行股份有限公司",
  },
  {
    bankCardCodes: "622921",
    bankNames: "河北银行股份有限公司",
  },
  {
    bankCardCodes: "628333",
    bankNames: "龙江银行股份有限公司",
  },
  {
    bankCardCodes: "622875",
    bankNames: "广州银行股份有限公司",
  },
  {
    bankCardCodes: "628367",
    bankNames: "广州银行股份有限公司",
  },
  {
    bankCardCodes: "625836",
    bankNames: "广州银行股份有限公司",
  },
  {
    bankCardCodes: "625050",
    bankNames: "广州银行股份有限公司",
  },
  {
    bankCardCodes: "628379",
    bankNames: "齐鲁银行股份有限公司",
  },
  {
    bankCardCodes: "622318",
    bankNames: "宁波银行",
  },
  {
    bankCardCodes: "622282",
    bankNames: "宁波银行",
  },
  {
    bankCardCodes: "628207",
    bankNames: "宁波银行",
  },
  {
    bankCardCodes: "622778",
    bankNames: "宁波银行",
  },
  {
    bankCardCodes: "625903",
    bankNames: "宁波银行",
  },
  {
    bankCardCodes: "625169",
    bankNames: "深圳农村商业银行",
  },
  {
    bankCardCodes: "625165",
    bankNames: "常熟农村商业银行",
  },
  {
    bankCardCodes: "625816",
    bankNames: "北京银行",
  },
  {
    bankCardCodes: "622852",
    bankNames: "北京银行",
  },
  {
    bankCardCodes: "622851",
    bankNames: "北京银行",
  },
  {
    bankCardCodes: "628203",
    bankNames: "北京银行",
  },
  {
    bankCardCodes: "622853",
    bankNames: "北京银行",
  },
  {
    bankCardCodes: "622163",
    bankNames: "北京银行",
  },
  {
    bankCardCodes: "522001",
    bankNames: "北京银行",
  },
  {
    bankCardCodes: "625095",
    bankNames: "南洋商业银行",
  },
  {
    bankCardCodes: "625093",
    bankNames: "南洋商业银行",
  },
  {
    bankCardCodes: "625973",
    bankNames: "东亚银行(中国)有限公司",
  },
  {
    bankCardCodes: "625972",
    bankNames: "东亚银行(中国)有限公司",
  },
  {
    bankCardCodes: "622266",
    bankNames: "东亚银行(中国)有限公司",
  },
  {
    bankCardCodes: "622265",
    bankNames: "东亚银行(中国)有限公司",
  },
  {
    bankCardCodes: "625833",
    bankNames: "浦发银行信用卡中心",
  },
  {
    bankCardCodes: "622693",
    bankNames: "浦发银行信用卡中心",
  },
  {
    bankCardCodes: "625831",
    bankNames: "浦发银行信用卡中心",
  },
  {
    bankCardCodes: "377187",
    bankNames: "浦发银行信用卡中心",
  },
  {
    bankCardCodes: "625970",
    bankNames: "浦发银行信用卡中心",
  },
  {
    bankCardCodes: "625971",
    bankNames: "浦发银行信用卡中心",
  },
  {
    bankCardCodes: "625969",
    bankNames: "华夏银行",
  },
  {
    bankCardCodes: "625968",
    bankNames: "华夏银行",
  },
  {
    bankCardCodes: "625967",
    bankNames: "华夏银行",
  },
  {
    bankCardCodes: "622636",
    bankNames: "华夏银行",
  },
  {
    bankCardCodes: "528708",
    bankNames: "华夏银行",
  },
  {
    bankCardCodes: "628318",
    bankNames: "华夏银行",
  },
  {
    bankCardCodes: "622638",
    bankNames: "华夏银行",
  },
  {
    bankCardCodes: "622637",
    bankNames: "华夏银行",
  },
  {
    bankCardCodes: "539868",
    bankNames: "华夏银行",
  },
  {
    bankCardCodes: "539867",
    bankNames: "华夏银行",
  },
  {
    bankCardCodes: "528709",
    bankNames: "华夏银行",
  },
  {
    bankCardCodes: "523959",
    bankNames: "华夏银行",
  },
  {
    bankCardCodes: "625980",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "625978",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "622801",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "625339",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "625976",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "628202",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "628201",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "625977",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "625975",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "622658",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "622655",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "622650",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "622570",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "622161",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "543159",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "524090",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "481699",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "425862",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "406254",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "406252",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "356840",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "356839",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "625979",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "625981",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "622687",
    bankNames: "中国光大银行",
  },
  {
    bankCardCodes: "622659",
    bankNames: "光大银行",
  },
  {
    bankCardCodes: "622685",
    bankNames: "光大银行",
  },
  {
    bankCardCodes: "622657",
    bankNames: "光大银行",
  },
  {
    bankCardCodes: "622678",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "622768",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "622767",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "622766",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "628372",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "628371",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "628370",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "622919",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "622918",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "622916",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "356392",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "356391",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "356390",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "628208",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "518212",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "628209",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "556617",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "628206",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "622689",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "622688",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "622680",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "622679",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "558916",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "433666",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "520108",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "403393",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "514906",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "433669",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "433667",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "404157",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "404174",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "404173",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "404172",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "404171",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "404159",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "404158",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "376966",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "403392",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "403391",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "400360",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "376969",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "376968",
    bankNames: "中信银行信用卡中心",
  },
  {
    bankCardCodes: "620038",
    bankNames: "中银通商务支付有限公司",
  },
  {
    bankCardCodes: "6234390",
    bankNames: "中银通支付商务有限公司",
  },
  {
    bankCardCodes: "921000",
    bankNames: "中银通支付",
  },
  {
    bankCardCodes: "621563",
    bankNames: "中银通商务支付有限公司",
  },
  {
    bankCardCodes: "620550",
    bankNames: "中银通商务支付有限公司",
  },
  {
    bankCardCodes: "620048",
    bankNames: "中银通商务支付有限公司",
  },
  {
    bankCardCodes: "921002",
    bankNames: "中银通商务支付有限公司",
  },
  {
    bankCardCodes: "921001",
    bankNames: "中银通商务支付有限公司",
  },
  {
    bankCardCodes: "920000",
    bankNames: "中银通商务支付有限公司",
  },
  {
    bankCardCodes: "620515",
    bankNames: "中银通商务支付有限公司",
  },
  {
    bankCardCodes: "622351",
    bankNames: "南洋商业银行",
  },
  {
    bankCardCodes: "621041",
    bankNames: "中国银行(香港)",
  },
  {
    bankCardCodes: "622355",
    bankNames: "集友银行",
  },
  {
    bankCardCodes: "622353",
    bankNames: "集友银行",
  },
  {
    bankCardCodes: "622352",
    bankNames: "集友银行",
  },
  {
    bankCardCodes: "622350",
    bankNames: "南洋商业银行",
  },
  {
    bankCardCodes: "622349",
    bankNames: "南洋商业银行",
  },
  {
    bankCardCodes: "622348",
    bankNames: "中国银行香港有限公司",
  },
  {
    bankCardCodes: "622347",
    bankNames: "中国银行香港有限公司",
  },
  {
    bankCardCodes: "622346",
    bankNames: "中国银行香港有限公司",
  },
  {
    bankCardCodes: "62346001",
    bankNames: "ABC BANKING CORPORATION LTD",
  },
  {
    bankCardCodes: "62346000",
    bankNames: "ABC BANKING CORPORATION LTD",
  },
  {
    bankCardCodes: "62345500",
    bankNames: "BRD Commercal Bank Limited",
  },
  {
    bankCardCodes: "6292720",
    bankNames: "Al Tail Money Transfer",
  },
  {
    bankCardCodes: "624418",
    bankNames: "CrediMax",
  },
  {
    bankCardCodes: "624416",
    bankNames: "Financiera Cuallix",
  },
  {
    bankCardCodes: "62344100",
    bankNames: "National Bank of Kenya Limited",
  },
  {
    bankCardCodes: "62441501",
    bankNames: "National Bank of Kenya Limited",
  },
  {
    bankCardCodes: "62441500",
    bankNames: "National Bank of Kenya Limited",
  },
  {
    bankCardCodes: "62344001",
    bankNames: "National Bank of Kenya Limited",
  },
  {
    bankCardCodes: "62344000",
    bankNames: "National Bank of Kenya Limited",
  },
  {
    bankCardCodes: "62343800",
    bankNames: "PROCREDIT BANK CONGO",
  },
  {
    bankCardCodes: "6234420",
    bankNames: "Noor Bank PJSC",
  },
  {
    bankCardCodes: "6234352",
    bankNames: "Conservative commercial bank",
  },
  {
    bankCardCodes: "6234351",
    bankNames: "Conservative commercial bank",
  },
  {
    bankCardCodes: "6234350",
    bankNames: "Conservative commercial bank",
  },
  {
    bankCardCodes: "6234330",
    bankNames: "AGD Bank Ltd",
  },
  {
    bankCardCodes: "6234323",
    bankNames: "OJS SCBP Primsotsbank",
  },
  {
    bankCardCodes: "6234322",
    bankNames: "OJS SCBP Primsotsbank",
  },
  {
    bankCardCodes: "6234321",
    bankNames: "OJS SCBP Primsotsbank",
  },
  {
    bankCardCodes: "6234320",
    bankNames: "OJS SCBP Primsotsbank",
  },
  {
    bankCardCodes: "62441701",
    bankNames: "Banque S C pour Afrique",
  },
  {
    bankCardCodes: "62344901",
    bankNames: "Banque S C pour Afrique",
  },
  {
    bankCardCodes: "6234310",
    bankNames: "MIP BANK",
  },
  {
    bankCardCodes: "6244096",
    bankNames: "Joint-Stock Commercial Bank",
  },
  {
    bankCardCodes: "6244092",
    bankNames: "Joint-Stock Commercial Bank",
  },
  {
    bankCardCodes: "623443",
    bankNames: "Habib Bank Limited",
  },
  {
    bankCardCodes: "6234270",
    bankNames: "HBL",
  },
  {
    bankCardCodes: "623448",
    bankNames: "Habib Bank Limited",
  },
  {
    bankCardCodes: "6234251",
    bankNames: "JSCB Primorye",
  },
  {
    bankCardCodes: "6234250",
    bankNames: "JSCB Primorye",
  },
  {
    bankCardCodes: "6234152",
    bankNames: "Sinopay（Singapore）PTE Ltd",
  },
  {
    bankCardCodes: "6234151",
    bankNames: "Sinopay（Singapore）PTE Ltd",
  },
  {
    bankCardCodes: "6234150",
    bankNames: "Sinopay（Singapore）PTE Ltd",
  },
  {
    bankCardCodes: "624391",
    bankNames: "DBS Bank Ltd",
  },
  {
    bankCardCodes: "623414",
    bankNames: "XacBank",
  },
  {
    bankCardCodes: "62341403",
    bankNames: "XacBank",
  },
  {
    bankCardCodes: "62341402",
    bankNames: "XacBank",
  },
  {
    bankCardCodes: "62341401",
    bankNames: "XacBank",
  },
  {
    bankCardCodes: "621052",
    bankNames: "DBS Bank Ltd",
  },
  {
    bankCardCodes: "6233991",
    bankNames: "FIDELITY BANK GHANA LIMITED",
  },
  {
    bankCardCodes: "6233990",
    bankNames: "FIDELITY BANK GHANA LIMITED",
  },
  {
    bankCardCodes: "6243902",
    bankNames: "Credit Ural Bank Joint Stock",
  },
  {
    bankCardCodes: "6243901",
    bankNames: "Credit Ural Bank Joint Stock",
  },
  {
    bankCardCodes: "6243900",
    bankNames: "Credit Ural Bank Joint Stock",
  },
  {
    bankCardCodes: "6234090",
    bankNames: "Credit Ural Bank Joint Stock",
  },
  {
    bankCardCodes: "629180",
    bankNames: "Subsidiary JSC VTB Bank",
  },
  {
    bankCardCodes: "623421",
    bankNames: "Subsidiary JSC VTB Bank",
  },
  {
    bankCardCodes: "623420",
    bankNames: "Subsidiary JSC VTB Bank",
  },
  {
    bankCardCodes: "623419",
    bankNames: "Subsidiary JSC VTB Bank",
  },
  {
    bankCardCodes: "623418",
    bankNames: "Subsidiary JSC VTB Bank",
  },
  {
    bankCardCodes: "623417",
    bankNames: "Subsidiary JSC VTB Bank",
  },
  {
    bankCardCodes: "6234121",
    bankNames: "ROSENERGOBANK",
  },
  {
    bankCardCodes: "6234122",
    bankNames: "ROSENERGOBANK",
  },
  {
    bankCardCodes: "6243970",
    bankNames: "ROSENERGOBANK",
  },
  {
    bankCardCodes: "6234120",
    bankNames: "ROSENERGOBANK",
  },
  {
    bankCardCodes: "6234460",
    bankNames: "Russian Agricultural Bank",
  },
  {
    bankCardCodes: "623389",
    bankNames: "Sindh Bank Limited",
  },
  {
    bankCardCodes: "6233961",
    bankNames: "Bank of Moscow OJSC",
  },
  {
    bankCardCodes: "6233960",
    bankNames: "Bank of Moscow OJSC",
  },
  {
    bankCardCodes: "624378",
    bankNames: "Hyundaicard",
  },
  {
    bankCardCodes: "624377",
    bankNames: "Hyundaicard",
  },
  {
    bankCardCodes: "624376",
    bankNames: "Hyundaicard",
  },
  {
    bankCardCodes: "624368",
    bankNames: "Hyundaicard",
  },
  {
    bankCardCodes: "623388",
    bankNames: "Lao China Bank Co.,Ltd",
  },
  {
    bankCardCodes: "623386",
    bankNames: "Lao China Bank Co.,Ltd",
  },
  {
    bankCardCodes: "623383",
    bankNames: "Lao China Bank Co.,Ltd",
  },
  {
    bankCardCodes: "6233824",
    bankNames: "Subsidiary Bank Sberbank RUS",
  },
  {
    bankCardCodes: "6233823",
    bankNames: "Subsidiary Bank Sberbank RUS",
  },
  {
    bankCardCodes: "6233822",
    bankNames: "Subsidiary Bank Sberbank RUS",
  },
  {
    bankCardCodes: "6233821",
    bankNames: "Subsidiary Bank Sberbank RUS",
  },
  {
    bankCardCodes: "6233820",
    bankNames: "Subsidiary Bank Sberbank RUS",
  },
  {
    bankCardCodes: "623385",
    bankNames: "BANCO SOL,S.A.",
  },
  {
    bankCardCodes: "6233841",
    bankNames: "BANCO SOL,S.A.",
  },
  {
    bankCardCodes: "6233840",
    bankNames: "BANCO SOL,S.A.",
  },
  {
    bankCardCodes: "6233781",
    bankNames: "Open Joint-stock Company",
  },
  {
    bankCardCodes: "6233780",
    bankNames: "Open Joint-stock Company",
  },
  {
    bankCardCodes: "624367",
    bankNames: "Cooperative & Agricultural",
  },
  {
    bankCardCodes: "623380",
    bankNames: "Cooperative & Agricultural",
  },
  {
    bankCardCodes: "623381",
    bankNames: "Bank Alfalah Limited",
  },
  {
    bankCardCodes: "624354",
    bankNames: "Banque Pour Le Commerce",
  },
  {
    bankCardCodes: "624353",
    bankNames: "Banque Pour Le Commerce",
  },
  {
    bankCardCodes: "624352",
    bankNames: "Banque Pour Le Commerce",
  },
  {
    bankCardCodes: "624364",
    bankNames: "MetaBank",
  },
  {
    bankCardCodes: "6233681",
    bankNames: "OJSC Tojiksodirotbank",
  },
  {
    bankCardCodes: "623369",
    bankNames: "Heritage International Bank",
  },
  {
    bankCardCodes: "6243425",
    bankNames: "OSJC MTS Bank",
  },
  {
    bankCardCodes: "6243424",
    bankNames: "OSJC MTS Bank",
  },
  {
    bankCardCodes: "6243423",
    bankNames: "OSJC MTS Bank",
  },
  {
    bankCardCodes: "6243422",
    bankNames: "OSJC MTS Bank",
  },
  {
    bankCardCodes: "6243421",
    bankNames: "OSJC MTS Bank",
  },
  {
    bankCardCodes: "6243420",
    bankNames: "OSJC MTS Bank",
  },
  {
    bankCardCodes: "623362",
    bankNames: "Bank of China(Australia)",
  },
  {
    bankCardCodes: "623359",
    bankNames: "Bank AL Habib Limited",
  },
  {
    bankCardCodes: "6221441",
    bankNames: "Travelex Japan KK",
  },
  {
    bankCardCodes: "6221440",
    bankNames: "Travelex Card Services",
  },
  {
    bankCardCodes: "623493",
    bankNames: "Co-Operative Bank Limited",
  },
  {
    bankCardCodes: "6233539",
    bankNames: "Light Bank",
  },
  {
    bankCardCodes: "6233538",
    bankNames: "Light Bank",
  },
  {
    bankCardCodes: "6233537",
    bankNames: "Light Bank",
  },
  {
    bankCardCodes: "6234981",
    bankNames: "Light Bank",
  },
  {
    bankCardCodes: "6234980",
    bankNames: "Light Bank",
  },
  {
    bankCardCodes: "6233536",
    bankNames: "Light Bank",
  },
  {
    bankCardCodes: "6233535",
    bankNames: "Light Bank",
  },
  {
    bankCardCodes: "6233534",
    bankNames: "Light Bank",
  },
  {
    bankCardCodes: "6233533",
    bankNames: "Light Bank",
  },
  {
    bankCardCodes: "6233532",
    bankNames: "Light Bank",
  },
  {
    bankCardCodes: "6233531",
    bankNames: "Light Bank",
  },
  {
    bankCardCodes: "62335108",
    bankNames: "CJSC “Spitamen Bank”",
  },
  {
    bankCardCodes: "62335107",
    bankNames: "CJSC “Spitamen Bank”",
  },
  {
    bankCardCodes: "62335106",
    bankNames: "CJSC “Spitamen Bank”",
  },
  {
    bankCardCodes: "62335105",
    bankNames: "CJSC “Spitamen Bank”",
  },
  {
    bankCardCodes: "62335104",
    bankNames: "CJSC “Spitamen Bank”",
  },
  {
    bankCardCodes: "62335103",
    bankNames: "CJSC “Spitamen Bank”",
  },
  {
    bankCardCodes: "62335102",
    bankNames: "CJSC “Spitamen Bank”",
  },
  {
    bankCardCodes: "62335101",
    bankNames: "CJSC “Spitamen Bank”",
  },
  {
    bankCardCodes: "623499",
    bankNames: "Himalayan Bank Limited",
  },
  {
    bankCardCodes: "62335203",
    bankNames: "State bank",
  },
  {
    bankCardCodes: "62335202",
    bankNames: "State bank",
  },
  {
    bankCardCodes: "62335201",
    bankNames: "State bank",
  },
  {
    bankCardCodes: "623360",
    bankNames: "Optima Bank OJSC",
  },
  {
    bankCardCodes: "624323",
    bankNames: "JSC ATFBank",
  },
  {
    bankCardCodes: "623338",
    bankNames: "JSC ATFBank",
  },
  {
    bankCardCodes: "623337",
    bankNames: "JSC ATFBank",
  },
  {
    bankCardCodes: "623336",
    bankNames: "JSC ATFBank",
  },
  {
    bankCardCodes: "6243561",
    bankNames: "Global Bank of Commerce Ltd",
  },
  {
    bankCardCodes: "6243560",
    bankNames: "Global Bank of Commerce Ltd",
  },
  {
    bankCardCodes: "6243551",
    bankNames: "Global Bank of Commerce Ltd",
  },
  {
    bankCardCodes: "6243550",
    bankNames: "Global Bank of Commerce Ltd",
  },
  {
    bankCardCodes: "623348",
    bankNames: "State Bank of Mauritius",
  },
  {
    bankCardCodes: "623331",
    bankNames: "State Bank of Mauritius",
  },
  {
    bankCardCodes: "623375",
    bankNames: "Advanced Bank of Asia Ltd.",
  },
  {
    bankCardCodes: "623325",
    bankNames: "Davr Bank",
  },
  {
    bankCardCodes: "623314",
    bankNames: "Fidelity Bank Plc",
  },
  {
    bankCardCodes: "624388",
    bankNames: "NongHyup Bank",
  },
  {
    bankCardCodes: "624387",
    bankNames: "NongHyup Bank",
  },
  {
    bankCardCodes: "624325",
    bankNames: "NongHyup Bank",
  },
  {
    bankCardCodes: "624324",
    bankNames: "NongHyup Bank",
  },
  {
    bankCardCodes: "624321",
    bankNames: "NongHyup Bank",
  },
  {
    bankCardCodes: "624320",
    bankNames: "NongHyup Bank",
  },
  {
    bankCardCodes: "623341",
    bankNames: "NongHyup Bank",
  },
  {
    bankCardCodes: "623323",
    bankNames: "NongHyup Bank",
  },
  {
    bankCardCodes: "623313",
    bankNames: "OJSC Hamkor bank",
  },
  {
    bankCardCodes: "623312",
    bankNames: "Al Baraka Bank(Pakistan)",
  },
  {
    bankCardCodes: "623311",
    bankNames: "Ecobank Nigeria",
  },
  {
    bankCardCodes: "623307",
    bankNames: "U Microfinance Bank Limited",
  },
  {
    bankCardCodes: "6243052",
    bankNames: "PT BANK SINARMAS TBK",
  },
  {
    bankCardCodes: "6243051",
    bankNames: "PT BANK SINARMAS TBK",
  },
  {
    bankCardCodes: "62334912",
    bankNames: "PVB Card Corporation",
  },
  {
    bankCardCodes: "62334911",
    bankNames: "PVB Card Corporation",
  },
  {
    bankCardCodes: "62334910",
    bankNames: "PVB Card Corporation",
  },
  {
    bankCardCodes: "623398",
    bankNames: "PVB Card Corporation",
  },
  {
    bankCardCodes: "623492",
    bankNames: "PVB Card Corporation",
  },
  {
    bankCardCodes: "623324",
    bankNames: "PVB Card Corporation",
  },
  {
    bankCardCodes: "623304",
    bankNames: "PVB Card Corporation",
  },
  {
    bankCardCodes: "623303",
    bankNames: "PVB Card Corporation",
  },
  {
    bankCardCodes: "623302",
    bankNames: "PVB Card Corporation",
  },
  {
    bankCardCodes: "623300",
    bankNames: "Rawbank S.a.r.l",
  },
  {
    bankCardCodes: "624322",
    bankNames: "Cim Finance Ltd",
  },
  {
    bankCardCodes: "624306",
    bankNames: "Cim Finance Ltd",
  },
  {
    bankCardCodes: "6234242",
    bankNames: "JSC Kazkommertsbank",
  },
  {
    bankCardCodes: "6234241",
    bankNames: "JSC Kazkommertsbank",
  },
  {
    bankCardCodes: "6234240",
    bankNames: "JSC Kazkommertsbank",
  },
  {
    bankCardCodes: "6214458",
    bankNames: "PT Bank Sinarmas,Tbk",
  },
  {
    bankCardCodes: "6214455",
    bankNames: "PT Bank Sinarmas,Tbk",
  },
  {
    bankCardCodes: "621301",
    bankNames: "格鲁吉亚 Invest Bank",
  },
  {
    bankCardCodes: "62927300",
    bankNames: "Non-banking credit",
  },
  {
    bankCardCodes: "620129",
    bankNames: "The Mauritius Commercial Bank",
  },
  {
    bankCardCodes: "6233455",
    bankNames: "JSC Liberty Bank",
  },
  {
    bankCardCodes: "6233454",
    bankNames: "JSC Liberty Bank",
  },
  {
    bankCardCodes: "6233453",
    bankNames: "JSC Liberty Bank",
  },
  {
    bankCardCodes: "623347",
    bankNames: "JSC Liberty Bank",
  },
  {
    bankCardCodes: "6233452",
    bankNames: "JSC Liberty Bank",
  },
  {
    bankCardCodes: "6233451",
    bankNames: "JSC Liberty Bank",
  },
  {
    bankCardCodes: "62341601",
    bankNames: "Capital Bank of Mongolia",
  },
  {
    bankCardCodes: "62341602",
    bankNames: "Capital Bank of Mongolia",
  },
  {
    bankCardCodes: "621694",
    bankNames: "Capital Bank of Mongolia",
  },
  {
    bankCardCodes: "625944",
    bankNames: "Phongsavanh Bank Limited",
  },
  {
    bankCardCodes: "621794",
    bankNames: "Phongsavanh Bank Limited",
  },
  {
    bankCardCodes: "624339",
    bankNames: "新韩卡公司",
  },
  {
    bankCardCodes: "626394",
    bankNames: "新韩卡公司",
  },
  {
    bankCardCodes: "624332",
    bankNames: "新韩卡公司",
  },
  {
    bankCardCodes: "624348",
    bankNames: "新韩卡公司",
  },
  {
    bankCardCodes: "624331",
    bankNames: "新韩卡公司",
  },
  {
    bankCardCodes: "625841",
    bankNames: "新韩卡公司",
  },
  {
    bankCardCodes: "625840",
    bankNames: "新韩卡公司",
  },
  {
    bankCardCodes: "623024",
    bankNames: "新韩卡公司",
  },
  {
    bankCardCodes: "621657",
    bankNames: "巴基斯坦HabibBank",
  },
  {
    bankCardCodes: "624357",
    bankNames: "The Bancorp Bank",
  },
  {
    bankCardCodes: "622164",
    bankNames: "The Bancorp Bank",
  },
  {
    bankCardCodes: "620105",
    bankNames: "The Bancorp Bank",
  },
  {
    bankCardCodes: "620091",
    bankNames: "Commercial Bank of Dubai",
  },
  {
    bankCardCodes: "620079",
    bankNames: "Commercial Bank of Dubai",
  },
  {
    bankCardCodes: "623315",
    bankNames: "CRDB BANK PLC",
  },
  {
    bankCardCodes: "623317",
    bankNames: "CRDB BANK PLC",
  },
  {
    bankCardCodes: "623316",
    bankNames: "CRDB BANK PLC",
  },
  {
    bankCardCodes: "621649",
    bankNames: "CJSC Fononbank",
  },
  {
    bankCardCodes: "624413",
    bankNames: "韩国三星卡公司",
  },
  {
    bankCardCodes: "626395",
    bankNames: "韩国三星卡公司",
  },
  {
    bankCardCodes: "624411",
    bankNames: "韩国三星卡公司",
  },
  {
    bankCardCodes: "624410",
    bankNames: "韩国三星卡公司",
  },
  {
    bankCardCodes: "624401",
    bankNames: "韩国三星卡公司",
  },
  {
    bankCardCodes: "624400",
    bankNames: "韩国三星卡公司",
  },
  {
    bankCardCodes: "624399",
    bankNames: "韩国三星卡公司",
  },
  {
    bankCardCodes: "625817",
    bankNames: "韩国三星卡公司",
  },
  {
    bankCardCodes: "625814",
    bankNames: "韩国三星卡公司",
  },
  {
    bankCardCodes: "620056",
    bankNames: "Credit Saison",
  },
  {
    bankCardCodes: "624370",
    bankNames: "韩国KB",
  },
  {
    bankCardCodes: "623374",
    bankNames: "韩国KB",
  },
  {
    bankCardCodes: "625804",
    bankNames: "韩国KB",
  },
  {
    bankCardCodes: "621654",
    bankNames: "Krung Thaj Bank Public Co. Ltd",
  },
  {
    bankCardCodes: "624366",
    bankNames: "Mongolia Trade Develop. Bank",
  },
  {
    bankCardCodes: "621642",
    bankNames: "Mongolia Trade Develop. Bank",
  },
  {
    bankCardCodes: "621647",
    bankNames: "俄罗斯ORIENT EXPRESS BANK",
  },
  {
    bankCardCodes: "625104",
    bankNames: "俄罗斯ORIENT EXPRESS BANK",
  },
  {
    bankCardCodes: "623370",
    bankNames: "DE SURINAAMSCHE BANK N.V.",
  },
  {
    bankCardCodes: "623339",
    bankNames: "OJSC Russian Investment Bank",
  },
  {
    bankCardCodes: "624351",
    bankNames: "Cambodia Mekong Bank PL",
  },
  {
    bankCardCodes: "624338",
    bankNames: "Cambodia Mekong Bank PL",
  },
  {
    bankCardCodes: "623332",
    bankNames: "Cambodia Mekong Bank PL",
  },
  {
    bankCardCodes: "621624",
    bankNames: "OJSCBASIAALLIANCEBANK",
  },
  {
    bankCardCodes: "621645",
    bankNames: "巴基斯坦FAYSAL BANK",
  },
  {
    bankCardCodes: "623358",
    bankNames: "韩国乐天",
  },
  {
    bankCardCodes: "624333",
    bankNames: "韩国乐天",
  },
  {
    bankCardCodes: "624313",
    bankNames: "韩国乐天",
  },
  {
    bankCardCodes: "625904",
    bankNames: "韩国乐天",
  },
  {
    bankCardCodes: "620537",
    bankNames: "澳门通股份有限公司",
  },
  {
    bankCardCodes: "620126",
    bankNames: "澳门通股份有限公司",
  },
  {
    bankCardCodes: "620532",
    bankNames: "澳门通股份有限公司",
  },
  {
    bankCardCodes: "621324",
    bankNames: "澳门BDA",
  },
  {
    bankCardCodes: "621274",
    bankNames: "澳门BDA",
  },
  {
    bankCardCodes: "6292740",
    bankNames: "Kyrgyz Investment Credit Bank",
  },
  {
    bankCardCodes: "6234610",
    bankNames: "Kyrgyz Investment Credit Bank",
  },
  {
    bankCardCodes: "623327",
    bankNames: "Kyrgyz Investment Credit Bank",
  },
  {
    bankCardCodes: "623326",
    bankNames: "Kyrgyz Investment Credit Bank",
  },
  {
    bankCardCodes: "621354",
    bankNames: "BCEL",
  },
  {
    bankCardCodes: "6201088",
    bankNames: "Russian Standard Bank",
  },
  {
    bankCardCodes: "6201086",
    bankNames: "Russian Standard Bank",
  },
  {
    bankCardCodes: "6250388",
    bankNames: "Russian Standard Bank",
  },
  {
    bankCardCodes: "6250386",
    bankNames: "Russian Standard Bank",
  },
  {
    bankCardCodes: "6216848",
    bankNames: "Russian Standard Bank",
  },
  {
    bankCardCodes: "6216846",
    bankNames: "Russian Standard Bank",
  },
  {
    bankCardCodes: "620108",
    bankNames: "Russian Standard Bank",
  },
  {
    bankCardCodes: "621349",
    bankNames: "乌兹别克斯坦INFINBANK",
  },
  {
    bankCardCodes: "621344",
    bankNames: "Royal Bank Open Stock Company",
  },
  {
    bankCardCodes: "625004",
    bankNames: "Royal Bank Open Stock Company",
  },
  {
    bankCardCodes: "620024",
    bankNames: "Royal Bank Open Stock Company",
  },
  {
    bankCardCodes: "620015",
    bankNames: "新加坡星网电子付款私人有限公司",
  },
  {
    bankCardCodes: "6234290",
    bankNames: "EQUITY BANK KENYA LIMITED",
  },
  {
    bankCardCodes: "6234280",
    bankNames: "EQUITY BANK KENYA LIMITED",
  },
  {
    bankCardCodes: "6243652",
    bankNames: "Gazprombank",
  },
  {
    bankCardCodes: "6243651",
    bankNames: "Gazprombank",
  },
  {
    bankCardCodes: "6243650",
    bankNames: "Gazprombank",
  },
  {
    bankCardCodes: "6233762",
    bankNames: "Gazprombank",
  },
  {
    bankCardCodes: "6233761",
    bankNames: "Gazprombank",
  },
  {
    bankCardCodes: "6233760",
    bankNames: "Gazprombank",
  },
  {
    bankCardCodes: "6233732",
    bankNames: "Gazprombank",
  },
  {
    bankCardCodes: "6233731",
    bankNames: "Gazprombank",
  },
  {
    bankCardCodes: "6233730",
    bankNames: "Gazprombank",
  },
  {
    bankCardCodes: "6233723",
    bankNames: "Gazprombank",
  },
  {
    bankCardCodes: "6233722",
    bankNames: "Gazprombank",
  },
  {
    bankCardCodes: "6233721",
    bankNames: "Gazprombank",
  },
  {
    bankCardCodes: "6233720",
    bankNames: "Gazprombank",
  },
  {
    bankCardCodes: "6233712",
    bankNames: "Gazprombank",
  },
  {
    bankCardCodes: "6233711",
    bankNames: "Gazprombank",
  },
  {
    bankCardCodes: "6233710",
    bankNames: "Gazprombank",
  },
  {
    bankCardCodes: "625007",
    bankNames: "菲律宾RCBC",
  },
  {
    bankCardCodes: "625035",
    bankNames: "菲律宾BDO",
  },
  {
    bankCardCodes: "625033",
    bankNames: "菲律宾BDO",
  },
  {
    bankCardCodes: "625002",
    bankNames: "越南西贡商业银行",
  },
  {
    bankCardCodes: "620009",
    bankNames: "越南西贡商业银行",
  },
  {
    bankCardCodes: "621055",
    bankNames: "越南西贡商业银行",
  },
  {
    bankCardCodes: "621257",
    bankNames: "Baiduri Bank Berhad",
  },
  {
    bankCardCodes: "622130",
    bankNames: "日本三菱信用卡公司",
  },
  {
    bankCardCodes: "625013",
    bankNames: "Allied Bank",
  },
  {
    bankCardCodes: "622145",
    bankNames: "Allied Bank",
  },
  {
    bankCardCodes: "62235600",
    bankNames: "CSC",
  },
  {
    bankCardCodes: "62349550",
    bankNames: "CSC",
  },
  {
    bankCardCodes: "621234",
    bankNames: "CSC",
  },
  {
    bankCardCodes: "622356",
    bankNames: "CSC",
  },
  {
    bankCardCodes: "621264",
    bankNames: "俄罗斯远东商业银行",
  },
  {
    bankCardCodes: "621045",
    bankNames: "丝绸之路银行",
  },
  {
    bankCardCodes: "621040",
    bankNames: "莫斯科人民储蓄银行",
  },
  {
    bankCardCodes: "623454",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "623453",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "623452",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "623451",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "624382",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "624381",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "624343",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "623393",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "623392",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "623391",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "624345",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "624404",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "624380",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "624420",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "624384",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "624374",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "624373",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "623395",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "624346",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "624446",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "624424",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "624344",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "623379",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625246",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625245",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "621641",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "621640",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "621484",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625243",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625244",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625132",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625111",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625320",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625220",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625179",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625178",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625139",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625032",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625131",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625127",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625125",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625123",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625120",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625112",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625006",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625106",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625103",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625079",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625078",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625039",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "621032",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625031",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625027",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625025",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625023",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625020",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625012",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625011",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "621003",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "625003",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "621220",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "621078",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "621039",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "620132",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "621031",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "621027",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "621025",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "621023",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "621020",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "621012",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "621011",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "621006",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "620812",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "620278",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "620220",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "620125",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "620123",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "620120",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "620106",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "620103",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "620039",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "620031",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "620027",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "620011",
    bankNames: "BC卡公司",
  },
  {
    bankCardCodes: "622414",
    bankNames: "蒙古郭勒姆特银行",
  },
  {
    bankCardCodes: "622444",
    bankNames: "蒙古郭勒姆特银行",
  },
  {
    bankCardCodes: "621049",
    bankNames: "蒙古郭勒姆特银行",
  },
  {
    bankCardCodes: "625154",
    bankNames: "越南Vietcombank",
  },
  {
    bankCardCodes: "625124",
    bankNames: "越南Vietcombank",
  },
  {
    bankCardCodes: "621295",
    bankNames: "越南Vietcombank",
  },
  {
    bankCardCodes: "62345800",
    bankNames: "可汗银行",
  },
  {
    bankCardCodes: "622954",
    bankNames: "可汗银行",
  },
  {
    bankCardCodes: "621224",
    bankNames: "可汗银行",
  },
  {
    bankCardCodes: "625019",
    bankNames: "中国工商银行（澳门）",
  },
  {
    bankCardCodes: "625018",
    bankNames: "中国工商银行（澳门）",
  },
  {
    bankCardCodes: "625017",
    bankNames: "中国工商银行（澳门）",
  },
  {
    bankCardCodes: "623355",
    bankNames: "Bangkok Bank Pcl",
  },
  {
    bankCardCodes: "622354",
    bankNames: "Bangkok Bank Pcl",
  },
  {
    bankCardCodes: "6234501",
    bankNames: "哈萨克斯坦国民储蓄银行",
  },
  {
    bankCardCodes: "6234500",
    bankNames: "哈萨克斯坦国民储蓄银行",
  },
  {
    bankCardCodes: "624341",
    bankNames: "哈萨克斯坦国民储蓄银行",
  },
  {
    bankCardCodes: "623357",
    bankNames: "哈萨克斯坦国民储蓄银行",
  },
  {
    bankCardCodes: "622550",
    bankNames: "哈萨克斯坦国民储蓄银行",
  },
  {
    bankCardCodes: "622549",
    bankNames: "哈萨克斯坦国民储蓄银行",
  },
  {
    bankCardCodes: "625016",
    bankNames: "澳门商业银行",
  },
  {
    bankCardCodes: "625014",
    bankNames: "澳门商业银行",
  },
  {
    bankCardCodes: "621255",
    bankNames: "澳门商业银行",
  },
  {
    bankCardCodes: "621254",
    bankNames: "澳门商业银行",
  },
  {
    bankCardCodes: "621253",
    bankNames: "澳门商业银行",
  },
  {
    bankCardCodes: "60119",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60114",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60113",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60110",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6509",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601179",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601177",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6508",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6507",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6506",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "65",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "644",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601189",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601188",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601187",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601186",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6011399",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601178",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60113989",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601174",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601149",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601148",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601147",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601146",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601145",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601144",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601143",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601142",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601140",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6011391",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112994",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60113988",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60113986",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60113985",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60113984",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60113946",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60113945",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60113944",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60113943",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60113941",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60113940",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6011393",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6011392",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112995",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6011390",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601138",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601137",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601136",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601131",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60113089",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60113081",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60113080",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6011300",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112997",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112996",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6011296",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6011294",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112993",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112992",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112991",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112990",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112989",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112988",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112987",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112986",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112981",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112980",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6011297",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601122",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6011295",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112013",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6011293",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6011292",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6011291",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "6011290",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601128",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601127",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601126",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601125",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601124",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601123",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601121",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112089",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112012",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112011",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "60112010",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601101",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "601100",
    bankNames: "Discover Financial Services，I",
  },
  {
    bankCardCodes: "624398",
    bankNames: "大西洋银行股份有限公司",
  },
  {
    bankCardCodes: "624372",
    bankNames: "大西洋银行股份有限公司",
  },
  {
    bankCardCodes: "624371",
    bankNames: "大西洋银行股份有限公司",
  },
  {
    bankCardCodes: "623064",
    bankNames: "大西洋银行股份有限公司",
  },
  {
    bankCardCodes: "623043",
    bankNames: "大西洋银行股份有限公司",
  },
  {
    bankCardCodes: "621247",
    bankNames: "大西洋银行股份有限公司",
  },
  {
    bankCardCodes: "622432",
    bankNames: "大西洋银行股份有限公司",
  },
  {
    bankCardCodes: "621232",
    bankNames: "大西洋银行股份有限公司",
  },
  {
    bankCardCodes: "624408",
    bankNames: "澳门国际银行",
  },
  {
    bankCardCodes: "624407",
    bankNames: "澳门国际银行",
  },
  {
    bankCardCodes: "622435",
    bankNames: "澳门国际银行",
  },
  {
    bankCardCodes: "622436",
    bankNames: "澳门国际银行",
  },
  {
    bankCardCodes: "622434",
    bankNames: "澳门国际银行",
  },
  {
    bankCardCodes: "622920",
    bankNames: "日本三井住友卡公司",
  },
  {
    bankCardCodes: "624309",
    bankNames: "澳门永亨银行股份有限公司",
  },
  {
    bankCardCodes: "624308",
    bankNames: "澳门永亨银行股份有限公司",
  },
  {
    bankCardCodes: "624302",
    bankNames: "澳门永亨银行股份有限公司",
  },
  {
    bankCardCodes: "624300",
    bankNames: "澳门永亨银行股份有限公司",
  },
  {
    bankCardCodes: "622785",
    bankNames: "澳门永亨银行股份有限公司",
  },
  {
    bankCardCodes: "622775",
    bankNames: "澳门永亨银行股份有限公司",
  },
  {
    bankCardCodes: "622862",
    bankNames: "澳门永亨银行股份有限公司",
  },
  {
    bankCardCodes: "622932",
    bankNames: "澳门永亨银行股份有限公司",
  },
  {
    bankCardCodes: "622861",
    bankNames: "澳门永亨银行股份有限公司",
  },
  {
    bankCardCodes: "622433",
    bankNames: "新加坡大华银行",
  },
  {
    bankCardCodes: "620153",
    bankNames: "Travelex",
  },
  {
    bankCardCodes: "620152",
    bankNames: "Travelex",
  },
  {
    bankCardCodes: "622496",
    bankNames: "Travelex",
  },
  {
    bankCardCodes: "622495",
    bankNames: "Travelex",
  },
  {
    bankCardCodes: "6258434",
    bankNames: "Kasikorn Bank PCL",
  },
  {
    bankCardCodes: "6258433",
    bankNames: "Kasikorn Bank PCL",
  },
  {
    bankCardCodes: "625842",
    bankNames: "Kasikorn Bank PCL",
  },
  {
    bankCardCodes: "623334",
    bankNames: "K & R International Limited",
  },
  {
    bankCardCodes: "620107",
    bankNames: "中国建设银行（亚洲）",
  },
  {
    bankCardCodes: "620068",
    bankNames: "中国银盛",
  },
  {
    bankCardCodes: "620070",
    bankNames: "中国银盛",
  },
  {
    bankCardCodes: "622484",
    bankNames: "渣打银行香港有限公司",
  },
  {
    bankCardCodes: "622483",
    bankNames: "渣打银行香港有限公司",
  },
  {
    bankCardCodes: "622482",
    bankNames: "渣打银行香港有限公司",
  },
  {
    bankCardCodes: "621740",
    bankNames: "渣打银行（香港）",
  },
  {
    bankCardCodes: "622948",
    bankNames: "渣打银行香港有限公司",
  },
  {
    bankCardCodes: "624412",
    bankNames: "中国建设银行澳门分行",
  },
  {
    bankCardCodes: "623350",
    bankNames: "中国建设银行澳门分行",
  },
  {
    bankCardCodes: "621084",
    bankNames: "中国建设银行澳门分行",
  },
  {
    bankCardCodes: "622974",
    bankNames: "AEON信贷财务亚洲有限公司",
  },
  {
    bankCardCodes: "622941",
    bankNames: "AEON信贷财务亚洲有限公司",
  },
  {
    bankCardCodes: "621064",
    bankNames: "AEON信贷财务亚洲有限公司",
  },
  {
    bankCardCodes: "620205",
    bankNames: "大丰银行有限公司",
  },
  {
    bankCardCodes: "620204",
    bankNames: "大丰银行有限公司",
  },
  {
    bankCardCodes: "620072",
    bankNames: "大丰银行有限公司",
  },
  {
    bankCardCodes: "622546",
    bankNames: "大丰银行有限公司",
  },
  {
    bankCardCodes: "622548",
    bankNames: "大丰银行有限公司",
  },
  {
    bankCardCodes: "622547",
    bankNames: "大丰银行有限公司",
  },
  {
    bankCardCodes: "622493",
    bankNames: "AEON信贷财务亚洲有限公司",
  },
  {
    bankCardCodes: "623310",
    bankNames: "集友银行",
  },
  {
    bankCardCodes: "621784",
    bankNames: "集友银行",
  },
  {
    bankCardCodes: "621043",
    bankNames: "集友银行",
  },
  {
    bankCardCodes: "620207",
    bankNames: "集友银行",
  },
  {
    bankCardCodes: "620206",
    bankNames: "集友银行",
  },
  {
    bankCardCodes: "623042",
    bankNames: "集友银行",
  },
  {
    bankCardCodes: "621742",
    bankNames: "集友银行",
  },
  {
    bankCardCodes: "623308",
    bankNames: "南洋商业银行",
  },
  {
    bankCardCodes: "621783",
    bankNames: "南洋商业银行",
  },
  {
    bankCardCodes: "621042",
    bankNames: "南洋商业银行",
  },
  {
    bankCardCodes: "620209",
    bankNames: "南洋商业银行",
  },
  {
    bankCardCodes: "620208",
    bankNames: "南洋商业银行",
  },
  {
    bankCardCodes: "623041",
    bankNames: "南洋商业银行",
  },
  {
    bankCardCodes: "621743",
    bankNames: "南洋商业银行",
  },
  {
    bankCardCodes: "623309",
    bankNames: "中国银行（香港）",
  },
  {
    bankCardCodes: "621782",
    bankNames: "中国银行（香港）",
  },
  {
    bankCardCodes: "620203",
    bankNames: "中国银行（香港）",
  },
  {
    bankCardCodes: "620202",
    bankNames: "中国银行（香港）",
  },
  {
    bankCardCodes: "623040",
    bankNames: "中国银行（香港）",
  },
  {
    bankCardCodes: "621741",
    bankNames: "中国银行（香港）",
  },
  {
    bankCardCodes: "625198",
    bankNames: "中银信用卡(国际)有限公司",
  },
  {
    bankCardCodes: "625196",
    bankNames: "中银信用卡(国际)有限公司",
  },
  {
    bankCardCodes: "625147",
    bankNames: "中银信用卡(国际)有限公司",
  },
  {
    bankCardCodes: "625042",
    bankNames: "中银信用卡(国际)有限公司",
  },
  {
    bankCardCodes: "625040",
    bankNames: "中银信用卡(国际)有限公司",
  },
  {
    bankCardCodes: "625055",
    bankNames: "中银信用卡(国际)有限公司",
  },
  {
    bankCardCodes: "623413",
    bankNames: "创兴银行有限公司",
  },
  {
    bankCardCodes: "624337",
    bankNames: "创兴银行有限公司",
  },
  {
    bankCardCodes: "625009",
    bankNames: "创兴银行有限公司",
  },
  {
    bankCardCodes: "625008",
    bankNames: "创兴银行有限公司",
  },
  {
    bankCardCodes: "621087",
    bankNames: "创兴银行有限公司",
  },
  {
    bankCardCodes: "622463",
    bankNames: "创兴银行有限公司",
  },
  {
    bankCardCodes: "622272",
    bankNames: "创兴银行有限公司",
  },
  {
    bankCardCodes: "623397",
    bankNames: "中信嘉华银行有限公司",
  },
  {
    bankCardCodes: "623328",
    bankNames: "中信嘉华银行有限公司",
  },
  {
    bankCardCodes: "624303",
    bankNames: "中信嘉华银行有限公司",
  },
  {
    bankCardCodes: "622459",
    bankNames: "中信嘉华银行有限公司",
  },
  {
    bankCardCodes: "622456",
    bankNames: "中信嘉华银行有限公司",
  },
  {
    bankCardCodes: "622453",
    bankNames: "中信嘉华银行有限公司",
  },
  {
    bankCardCodes: "623107",
    bankNames: "恒生银行",
  },
  {
    bankCardCodes: "623106",
    bankNames: "恒生银行",
  },
  {
    bankCardCodes: "621441",
    bankNames: "恒生银行",
  },
  {
    bankCardCodes: "621440",
    bankNames: "恒生银行",
  },
  {
    bankCardCodes: "622410",
    bankNames: "恒生银行",
  },
  {
    bankCardCodes: "622409",
    bankNames: "恒生银行",
  },
  {
    bankCardCodes: "625092",
    bankNames: "恒生银行有限公司",
  },
  {
    bankCardCodes: "622377",
    bankNames: "恒生银行有限公司",
  },
  {
    bankCardCodes: "622378",
    bankNames: "恒生银行有限公司",
  },
  {
    bankCardCodes: "622376",
    bankNames: "恒生银行有限公司",
  },
  {
    bankCardCodes: "625024",
    bankNames: "恒生银行有限公司",
  },
  {
    bankCardCodes: "625026",
    bankNames: "恒生银行有限公司",
  },
  {
    bankCardCodes: "621443",
    bankNames: "香港上海汇丰银行有限公司",
  },
  {
    bankCardCodes: "621442",
    bankNames: "香港上海汇丰银行有限公司",
  },
  {
    bankCardCodes: "622407",
    bankNames: "香港上海汇丰银行有限公司",
  },
  {
    bankCardCodes: "622406",
    bankNames: "香港上海汇丰银行有限公司",
  },
  {
    bankCardCodes: "625098",
    bankNames: "香港上海汇丰银行有限公司",
  },
  {
    bankCardCodes: "625096",
    bankNames: "香港上海汇丰银行有限公司",
  },
  {
    bankCardCodes: "625034",
    bankNames: "香港上海汇丰银行有限公司",
  },
  {
    bankCardCodes: "622361",
    bankNames: "香港上海汇丰银行有限公司",
  },
  {
    bankCardCodes: "622360",
    bankNames: "香港上海汇丰银行有限公司",
  },
  {
    bankCardCodes: "625063",
    bankNames: "永隆银行有限公司",
  },
  {
    bankCardCodes: "625062",
    bankNames: "永隆银行有限公司",
  },
  {
    bankCardCodes: "622970",
    bankNames: "永隆银行有限公司",
  },
  {
    bankCardCodes: "622971",
    bankNames: "永隆银行有限公司",
  },
  {
    bankCardCodes: "622423",
    bankNames: "永隆银行有限公司",
  },
  {
    bankCardCodes: "622387",
    bankNames: "永隆银行有限公司",
  },
  {
    bankCardCodes: "625107",
    bankNames: "上海商业银行",
  },
  {
    bankCardCodes: "622952",
    bankNames: "上海商业银行",
  },
  {
    bankCardCodes: "622386",
    bankNames: "上海商业银行",
  },
  {
    bankCardCodes: "621034",
    bankNames: "上海商业银行",
  },
  {
    bankCardCodes: "621747",
    bankNames: "星展银行香港有限公司",
  },
  {
    bankCardCodes: "621746",
    bankNames: "星展银行香港有限公司",
  },
  {
    bankCardCodes: "621745",
    bankNames: "星展银行香港有限公司",
  },
  {
    bankCardCodes: "621744",
    bankNames: "星展银行香港有限公司",
  },
  {
    bankCardCodes: "622492",
    bankNames: "星展银行香港有限公司",
  },
  {
    bankCardCodes: "622491",
    bankNames: "星展银行香港有限公司",
  },
  {
    bankCardCodes: "622490",
    bankNames: "星展银行香港有限公司",
  },
  {
    bankCardCodes: "622487",
    bankNames: "星展银行香港有限公司",
  },
  {
    bankCardCodes: "624329",
    bankNames: "中国建设银行亚洲股份有限公司",
  },
  {
    bankCardCodes: "621083",
    bankNames: "中国建设银行亚洲股份有限公司",
  },
  {
    bankCardCodes: "621487",
    bankNames: "中国建设银行亚洲股份有限公司",
  },
  {
    bankCardCodes: "622382",
    bankNames: "中国建设银行亚洲股份有限公司",
  },
  {
    bankCardCodes: "622677",
    bankNames: "中国建设银行亚洲股份有限公司",
  },
  {
    bankCardCodes: "622676",
    bankNames: "中国建设银行亚洲股份有限公司",
  },
  {
    bankCardCodes: "622675",
    bankNames: "中国建设银行亚洲股份有限公司",
  },
  {
    bankCardCodes: "622381",
    bankNames: "中国建设银行亚洲股份有限公司",
  },
  {
    bankCardCodes: "625010",
    bankNames: "永亨银行",
  },
  {
    bankCardCodes: "622798",
    bankNames: "永亨银行",
  },
  {
    bankCardCodes: "622957",
    bankNames: "永亨银行",
  },
  {
    bankCardCodes: "622963",
    bankNames: "永亨银行",
  },
  {
    bankCardCodes: "622958",
    bankNames: "永亨银行",
  },
  {
    bankCardCodes: "622871",
    bankNames: "永亨银行",
  },
  {
    bankCardCodes: "622489",
    bankNames: "大新银行有限公司",
  },
  {
    bankCardCodes: "625940",
    bankNames: "大新银行有限公司",
  },
  {
    bankCardCodes: "622294",
    bankNames: "大新银行有限公司",
  },
  {
    bankCardCodes: "622451",
    bankNames: "大新银行有限公司",
  },
  {
    bankCardCodes: "622375",
    bankNames: "大新银行有限公司",
  },
  {
    bankCardCodes: "622373",
    bankNames: "大新银行有限公司",
  },
  {
    bankCardCodes: "622297",
    bankNames: "大新银行有限公司",
  },
  {
    bankCardCodes: "622296",
    bankNames: "大新银行有限公司",
  },
  {
    bankCardCodes: "622295",
    bankNames: "大新银行有限公司",
  },
  {
    bankCardCodes: "622293",
    bankNames: "大新银行有限公司",
  },
  {
    bankCardCodes: "625091",
    bankNames: "花旗银行有限公司",
  },
  {
    bankCardCodes: "622371",
    bankNames: "花旗银行有限公司",
  },
  {
    bankCardCodes: "621411",
    bankNames: "东亚银行澳门分行",
  },
  {
    bankCardCodes: "623318",
    bankNames: "东亚银行有限公司",
  },
  {
    bankCardCodes: "622472",
    bankNames: "东亚银行有限公司",
  },
  {
    bankCardCodes: "622943",
    bankNames: "东亚银行有限公司",
  },
  {
    bankCardCodes: "622471",
    bankNames: "东亚银行有限公司",
  },
  {
    bankCardCodes: "622365",
    bankNames: "东亚银行有限公司",
  },
  {
    bankCardCodes: "622372",
    bankNames: "东亚银行有限公司",
  },
  {
    bankCardCodes: "621652853",
    bankNames: "信阳平桥恒丰村镇银行",
  },
  {
    bankCardCodes: "62359605",
    bankNames: "新绛县新田村镇银行",
  },
  {
    bankCardCodes: "62359603",
    bankNames: "临猗县新田村镇银行",
  },
  {
    bankCardCodes: "62359601",
    bankNames: "曲沃县新田村镇银行",
  },
  {
    bankCardCodes: "62359602",
    bankNames: "繁峙县新田村镇银行",
  },
  {
    bankCardCodes: "623174001",
    bankNames: "虞城通商村镇银行"
  },
  {
    bankCardCodes: "62363442",
    bankNames: "乐东惠丰村镇银行",
  },
  {
    bankCardCodes: "62363441",
    bankNames: "东方惠丰村镇银行",
  },
  {
    bankCardCodes: "62363440",
    bankNames: "临高惠丰村镇银行",
  },
  {
    bankCardCodes: "621382055",
    bankNames: "宁夏贺兰回商村镇银行",
  },
  {
    bankCardCodes: "621382059",
    bankNames: "毕节发展村镇银行",
  },
  {
    bankCardCodes: "621382065",
    bankNames: "息烽包商黔隆村镇银行",
  },
  {
    bankCardCodes: "621382054",
    bankNames: "贵阳花溪建设村镇银行",
  },
  {
    bankCardCodes: "621382071",
    bankNames: "广元包商贵民村镇银行",
  },
  {
    bankCardCodes: "621382069",
    bankNames: "新都桂城村镇银行",
  },
  {
    bankCardCodes: "621382073",
    bankNames: "武冈包商村镇银行",
  },
  {
    bankCardCodes: "621382075",
    bankNames: "掇刀包商村镇银行",
  },
  {
    bankCardCodes: "621382050",
    bankNames: "漯河市郾城包商村镇银行",
  },
  {
    bankCardCodes: "621382056",
    bankNames: "鄄城包商村镇银行",
  },
  {
    bankCardCodes: "621382064",
    bankNames: "仪征包商村镇银行",
  },
  {
    bankCardCodes: "621382072",
    bankNames: "江苏南通如皋包商村镇银行",
  },
  {
    bankCardCodes: "621382060",
    bankNames: "九台龙嘉村镇银行",
  },
  {
    bankCardCodes: "621382067",
    bankNames: "大连金州联丰村镇银行",
  },
  {
    bankCardCodes: "621382062",
    bankNames: "乌审旗包商村镇银行",
  },
  {
    bankCardCodes: "621382061",
    bankNames: "准格尔旗包商村镇银行",
  },
  {
    bankCardCodes: "621382058",
    bankNames: "化德包商村镇银行",
  },
  {
    bankCardCodes: "621382057",
    bankNames: "集宁包闹村镇银行",
  },
  {
    bankCardCodes: "621382051",
    bankNames: "西乌珠穆沁包商惠丰村镇银行",
  },
  {
    bankCardCodes: "621382074",
    bankNames: "科尔沁包商村镇银行",
  },
  {
    bankCardCodes: "621382068",
    bankNames: "鄂温克包商村镇银行",
  },
  {
    bankCardCodes: "621382052",
    bankNames: "莫力达瓦包商村镇银行",
  },
  {
    bankCardCodes: "621382063",
    bankNames: "宁城包商村镇银行",
  },
  {
    bankCardCodes: "621382066",
    bankNames: "固阳包商惠农村镇银行",
  },
  {
    bankCardCodes: "621382053",
    bankNames: "清徐惠民村镇银行",
  },
  {
    bankCardCodes: "621382070",
    bankNames: "天津津南村镇银行",
  },
  {
    bankCardCodes: "621382076",
    bankNames: "北京昌平包商村镇银行",
  },
  {
    bankCardCodes: "623194773",
    bankNames: "许昌新浦村镇银行",
  },
  {
    bankCardCodes: "62361526",
    bankNames: "宁津胶东村镇银行",
  },
  {
    bankCardCodes: "62361525",
    bankNames: "禹城胶东村镇银行",
  },
  {
    bankCardCodes: "62361522",
    bankNames: "齐河胶东村镇银行",
  },
  {
    bankCardCodes: "62361523",
    bankNames: "夏津胶东村镇银行",
  },
  {
    bankCardCodes: "62361527",
    bankNames: "牟平胶东村镇银行",
  },
  {
    bankCardCodes: "62361528",
    bankNames: "莱阳胶东村镇银行",
  },
  {
    bankCardCodes: "62314292",
    bankNames: "垦利乐安村镇银行",
  },
  {
    bankCardCodes: "62310401",
    bankNames: "贵阳小河科技村镇银行",
  },
  {
    bankCardCodes: "62310402",
    bankNames: "修文江海村镇银行",
  },
  {
    bankCardCodes: "62300401",
    bankNames: "朝阳柳城村镇银行",
  },
  {
    bankCardCodes: "62165826",
    bankNames: "元氏信融村镇银行",
  },
  {
    bankCardCodes: "621658001",
    bankNames: "元氏信融村镇银行",
  },
  {
    bankCardCodes: "62006401",
    bankNames: "中山古镇南粤村镇银行",
  },
  {
    bankCardCodes: "62144735",
    bankNames: "黔西花都村镇银行",
  },
  {
    bankCardCodes: "62359351",
    bankNames: "山东惠民舜丰村镇银行",
  },
  {
    bankCardCodes: "62359349",
    bankNames: "山东利津舜丰村镇银行",
  },
  {
    bankCardCodes: "62359329",
    bankNames: "青岛黄岛舜丰村镇银行",
  },
  {
    bankCardCodes: "62359353",
    bankNames: "广阳舜丰村镇银行",
  },
  {
    bankCardCodes: "62359350",
    bankNames: "霸州舜丰村镇银行",
  },
  {
    bankCardCodes: "62359356",
    bankNames: "大城舜丰村镇银行",
  },
  {
    bankCardCodes: "62359354",
    bankNames: "唐山市丰南舜丰村镇银行",
  },
  {
    bankCardCodes: "62359364",
    bankNames: "乐亭舜丰村镇银行",
  },
  {
    bankCardCodes: "621389",
    bankNames: "江苏通州华商村镇银行",
  },
  {
    bankCardCodes: "62361703",
    bankNames: "大连经济技术开发区鑫汇村镇银行",
  },
  {
    bankCardCodes: "62361702",
    bankNames: "庄河汇通村镇银行",
  },
  {
    bankCardCodes: "62359065",
    bankNames: "调兵山惠民村镇银行",
  },
  {
    bankCardCodes: "623587002",
    bankNames: "金寨徽银村镇银行",
  },
  {
    bankCardCodes: "623587001",
    bankNames: "无为徽银村镇银行",
  },
  {
    bankCardCodes: "62358563",
    bankNames: "铁岭新星村镇银行",
  },
  {
    bankCardCodes: "623579001",
    bankNames: "浙江玉环永兴村镇银行",
  },
  {
    bankCardCodes: "62358101",
    bankNames: "宁夏中宁青银村镇银行",
  },
  {
    bankCardCodes: "62368003",
    bankNames: "辽宁千山金泉村镇银行",
  },
  {
    bankCardCodes: "62368002",
    bankNames: "辽宁岫岩金玉村镇银行",
  },
  {
    bankCardCodes: "62368001",
    bankNames: "辽宁海城金海村镇银行",
  },
  {
    bankCardCodes: "62359430",
    bankNames: "费县梁邹村镇银行",
  },
  {
    bankCardCodes: "62357005",
    bankNames: "洪洞县洪都村镇银行",
  },
  {
    bankCardCodes: "62357001",
    bankNames: "襄汾万都村镇银行",
  },
  {
    bankCardCodes: "62357003",
    bankNames: "介休市华都村镇银行",
  },
  {
    bankCardCodes: "62357004",
    bankNames: "寿阳县汇都村镇银行",
  },
  {
    bankCardCodes: "62357007",
    bankNames: "交口融都村镇银行",
  },
  {
    bankCardCodes: "62357011",
    bankNames: "临县泉都村镇银行",
  },
  {
    bankCardCodes: "62357008",
    bankNames: "文水县润都村镇银行",
  },
  {
    bankCardCodes: "62357002",
    bankNames: "汾阳市九都村镇银行有限公司",
  },
  {
    bankCardCodes: "62357006",
    bankNames: "忻州市忻府区秀都村镇银行",
  },
  {
    bankCardCodes: "62357010",
    bankNames: "代县泓都村镇银行",
  },
  {
    bankCardCodes: "62357009",
    bankNames: "大同市南郊区京都村镇银行",
  },
  {
    bankCardCodes: "62357012",
    bankNames: "太原市尖草坪区信都村镇银行",
  },
  {
    bankCardCodes: "623558612",
    bankNames: "余干恒通村镇银行",
  },
  {
    bankCardCodes: "623558616",
    bankNames: "横峰恒通村镇银行",
  },
  {
    bankCardCodes: "623558611",
    bankNames: "鹰潭月湖恒通村镇银行",
  },
  {
    bankCardCodes: "62355865",
    bankNames: "浙江松阳恒通村镇银行",
  },
  {
    bankCardCodes: "623558618",
    bankNames: "浙江富阳恒通村镇银行",
  },
  {
    bankCardCodes: "62355703",
    bankNames: "昆明呈贡华夏村镇银行",
  },
  {
    bankCardCodes: "62355702",
    bankNames: "四川江油华夏村镇银行",
  },
  {
    bankCardCodes: "62355701",
    bankNames: "北京大兴华夏村镇银行",
  },
  {
    bankCardCodes: "62355358",
    bankNames: "沂源博商村镇银行",
  },
  {
    bankCardCodes: "62355001",
    bankNames: "汝州玉川村镇银行",
  },
  {
    bankCardCodes: "623538001",
    bankNames: "浙江龙游义商村镇银行",
  },
  {
    bankCardCodes: "623535001",
    bankNames: "湖北大冶泰隆村镇银行",
  },
  {
    bankCardCodes: "623535002",
    bankNames: "浙江庆元泰隆村镇银行",
  },
  {
    bankCardCodes: "62314062",
    bankNames: "盘山安泰村镇银行股份有限公司",
  },
  {
    bankCardCodes: "62314061",
    bankNames: "大洼恒丰村镇银行",
  },
  {
    bankCardCodes: "623532001",
    bankNames: "江苏洪泽金阳光村镇银行",
  },
  {
    bankCardCodes: "623530953",
    bankNames: "香河益民村镇银行",
  },
  {
    bankCardCodes: "623525001",
    bankNames: "惠水恒升村镇银行",
  },
  {
    bankCardCodes: "62352501",
    bankNames: "浙江永嘉恒升村镇银行",
  },
  {
    bankCardCodes: "623519533",
    bankNames: "庆阳市西峰瑞信村镇银行",
  },
  {
    bankCardCodes: "62168303",
    bankNames: "山东肥城民丰村镇银行",
  },
  {
    bankCardCodes: "62168306",
    bankNames: "山东梁山民丰村镇银行",
  },
  {
    bankCardCodes: "62168308",
    bankNames: "安徽明光民丰村镇银行",
  },
  {
    bankCardCodes: "62168309",
    bankNames: "安徽宿松民丰村镇银行",
  },
  {
    bankCardCodes: "62168302",
    bankNames: "江苏灌南民丰村镇银行",
  },
  {
    bankCardCodes: "62168304",
    bankNames: "江苏灌云民丰村镇银行",
  },
  {
    bankCardCodes: "62168301",
    bankNames: "江苏丰县民丰村镇银行",
  },
  {
    bankCardCodes: "62168305",
    bankNames: "江苏溧水民丰村镇银行",
  },
  {
    bankCardCodes: "623504007",
    bankNames: "睢县德商村镇银行",
  },
  {
    bankCardCodes: "623504006",
    bankNames: "宁陵德商村镇银行",
  },
  {
    bankCardCodes: "623504003",
    bankNames: "民权德商村镇银行",
  },
  {
    bankCardCodes: "623517001",
    bankNames: "襄城汇浦村镇银行",
  },
  {
    bankCardCodes: "623504001",
    bankNames: "范县德商村镇银行",
  },
  {
    bankCardCodes: "623504009",
    bankNames: "浙江定海德商村镇银行",
  },
  {
    bankCardCodes: "623504004",
    bankNames: "浙江秀洲德商村镇银行",
  },
  {
    bankCardCodes: "623504008",
    bankNames: "海宁德商村镇银行",
  },
  {
    bankCardCodes: "623504002",
    bankNames: "余杭德商村镇银行",
  },
  {
    bankCardCodes: "62351501",
    bankNames: "江苏丹阳保得村镇银行",
  },
  {
    bankCardCodes: "623504010",
    bankNames: "天津滨海德商村镇银行",
  },
  {
    bankCardCodes: "623607009",
    bankNames: "澳洲联邦银行(永城)村镇银行",
  },
  {
    bankCardCodes: "623607005",
    bankNames: "澳洲联邦银行(渑池)村镇银行",
  },
  {
    bankCardCodes: "623607001",
    bankNames: "澳洲联邦村镇银行济源村镇银行",
  },
  {
    bankCardCodes: "623607010",
    bankNames: "澳洲联邦村镇银行温县村镇银行",
  },
  {
    bankCardCodes: "623607004",
    bankNames: "澳洲联邦村镇银行伊川村镇银行",
  },
  {
    bankCardCodes: "623607003",
    bankNames: "澳洲联邦村镇银行兰考村镇银行",
  },
  {
    bankCardCodes: "623607002",
    bankNames: "澳洲联邦银行登封村镇银行",
  },
  {
    bankCardCodes: "623607007",
    bankNames: "澳洲联邦银行（永年）村镇银行",
  },
  {
    bankCardCodes: "623607008",
    bankNames: "澳洲联邦银行（磁县）村镇银行",
  },
  {
    bankCardCodes: "623607006",
    bankNames: "澳洲联邦银行（辛集）村镇银行",
  },
  {
    bankCardCodes: "623045001",
    bankNames: "定安合丰村镇银行",
  },
  {
    bankCardCodes: "623512001",
    bankNames: "江苏邳州陇海村镇银行股份有限公司",
  },
  {
    bankCardCodes: "623502001",
    bankNames: "浙江缙云杭银村镇银行",
  },
  {
    bankCardCodes: "62361028",
    bankNames: "陕西洛南阳光村镇银行",
  },
  {
    bankCardCodes: "62361025",
    bankNames: "陕西洛南阳光村镇银行",
  },
  {
    bankCardCodes: "62361027",
    bankNames: "西安高陵阳光村镇银行",
  },
  {
    bankCardCodes: "62361026",
    bankNames: "西安高陵阳光村镇银行",
  },
  {
    bankCardCodes: "623609002",
    bankNames: "江苏新沂汉源村镇银行",
  },
  {
    bankCardCodes: "623609001",
    bankNames: "江苏沛县汉源村镇银行",
  },
  {
    bankCardCodes: "623608002",
    bankNames: "百色右江华润村镇银行",
  },
  {
    bankCardCodes: "623608001",
    bankNames: "德庆华润村镇银行",
  },
  {
    bankCardCodes: "62360619",
    bankNames: "富蕴中成村镇银行",
  },
  {
    bankCardCodes: "62360618",
    bankNames: "鄯善中成村镇银行",
  },
  {
    bankCardCodes: "62360617",
    bankNames: "泸水中成村镇银行",
  },
  {
    bankCardCodes: "62360611",
    bankNames: "大理古城中成村镇银行",
  },
  {
    bankCardCodes: "62360616",
    bankNames: "玉溪澄江中成村镇银行",
  },
  {
    bankCardCodes: "62360615",
    bankNames: "昆明寻甸中成村镇银行",
  },
  {
    bankCardCodes: "62360614",
    bankNames: "昆明禄劝中成村镇银行",
  },
  {
    bankCardCodes: "62360610",
    bankNames: "昆明石林中成村镇银行",
  },
  {
    bankCardCodes: "62360613",
    bankNames: "昆明东川中成村镇银行",
  },
  {
    bankCardCodes: "62360612",
    bankNames: "昆明马金铺中成村镇银行",
  },
  {
    bankCardCodes: "62360609",
    bankNames: "南充嘉陵中成村镇银行",
  },
  {
    bankCardCodes: "62360606",
    bankNames: "南部县中成村镇银行",
  },
  {
    bankCardCodes: "62360604",
    bankNames: "筠连中成村镇银行",
  },
  {
    bankCardCodes: "62360605",
    bankNames: "长宁中成村镇银行",
  },
  {
    bankCardCodes: "62360608",
    bankNames: "峨嵋山中成村镇银行",
  },
  {
    bankCardCodes: "62360607",
    bankNames: "犍为中成村镇银行",
  },
  {
    bankCardCodes: "62360600",
    bankNames: "自贡中成村镇银行",
  },
  {
    bankCardCodes: "62360621",
    bankNames: "莱芜中成村镇银行",
  },
  {
    bankCardCodes: "62360623",
    bankNames: "无棣中成村镇银行",
  },
  {
    bankCardCodes: "62360624",
    bankNames: "衮州中成村镇银行",
  },
  {
    bankCardCodes: "62360622",
    bankNames: "奎文中成村镇银行",
  },
  {
    bankCardCodes: "62360620",
    bankNames: "东营河口中成村镇银行",
  },
  {
    bankCardCodes: "62360601",
    bankNames: "青岛胶州中成村镇银行",
  },
  {
    bankCardCodes: "62360627",
    bankNames: "上杭中成村镇银行",
  },
  {
    bankCardCodes: "62360628",
    bankNames: "浦城中成村镇银行",
  },
  {
    bankCardCodes: "62360626",
    bankNames: "南靖中成村镇银行",
  },
  {
    bankCardCodes: "62360625",
    bankNames: "惠安中成村镇银行",
  },
  {
    bankCardCodes: "62360629",
    bankNames: "扬州广陵中成村镇银行",
  },
  {
    bankCardCodes: "62360603",
    bankNames: "建湖中成村镇银行",
  },
  {
    bankCardCodes: "62360602",
    bankNames: "常州新北中成村镇银行",
  },
  {
    bankCardCodes: "62360638",
    bankNames: "宣化中成村镇银行",
  },
  {
    bankCardCodes: "62360635",
    bankNames: "安国中成村镇银行",
  },
  {
    bankCardCodes: "62360633",
    bankNames: "曲阳中成村镇银行",
  },
  {
    bankCardCodes: "62360631",
    bankNames: "高碑店中成村镇银行",
  },
  {
    bankCardCodes: "62360632",
    bankNames: "望都中成村镇银行",
  },
  {
    bankCardCodes: "62360630",
    bankNames: "涿州中成村镇银行",
  },
  {
    bankCardCodes: "62360634",
    bankNames: "定州中成村镇银行",
  },
  {
    bankCardCodes: "62360637",
    bankNames: "滦南中成村镇银行",
  },
  {
    bankCardCodes: "62360636",
    bankNames: "滦县中成村镇银行",
  },
  {
    bankCardCodes: "623678353",
    bankNames: "山东临朐聚丰村镇银行",
  },
  {
    bankCardCodes: "623604001",
    bankNames: "南京浦口靖发村镇银行",
  },
  {
    bankCardCodes: "623601001",
    bankNames: "哈密天山村镇银行",
  },
  {
    bankCardCodes: "621699003",
    bankNames: "辽宁义县祥和村镇银行",
  },
  {
    bankCardCodes: "621699005",
    bankNames: "辽宁黑山锦行村镇银行",
  },
  {
    bankCardCodes: "621699001",
    bankNames: "锦州北镇益民村镇银行",
  },
  {
    bankCardCodes: "621699002",
    bankNames: "锦州太和益民村镇银行",
  },
  {
    bankCardCodes: "621386004",
    bankNames: "孟州射阳村镇银行",
  },
  {
    bankCardCodes: "621386003",
    bankNames: "河南沁阳江南村镇银行",
  },
  {
    bankCardCodes: "621386001",
    bankNames: "武陟射阳村镇银行",
  },
  {
    bankCardCodes: "621386002",
    bankNames: "江苏海安盐海村镇银行",
  },
  {
    bankCardCodes: "621368001",
    bankNames: "山东莒南村镇银行",
  },
  {
    bankCardCodes: "621384001",
    bankNames: "山东邹平青隆村镇银行",
  },
  {
    bankCardCodes: "62138401",
    bankNames: "沧州市运河青隆村镇银行",
  },
  {
    bankCardCodes: "62138403",
    bankNames: "黄骅青隆村镇银行",
  },
  {
    bankCardCodes: "62138404",
    bankNames: "东光青隆村镇银行",
  },
  {
    bankCardCodes: "62138402",
    bankNames: "青县青隆村镇银行",
  },
  {
    bankCardCodes: "62127818",
    bankNames: "合阳惠民村镇银行",
  },
  {
    bankCardCodes: "62127813",
    bankNames: "云安惠民村镇银行",
  },
  {
    bankCardCodes: "62127815",
    bankNames: "清远清新惠民村镇银行",
  },
  {
    bankCardCodes: "62127812",
    bankNames: "五华惠民村镇银行",
  },
  {
    bankCardCodes: "62127817",
    bankNames: "惠东惠民村镇银行",
  },
  {
    bankCardCodes: "62127816",
    bankNames: "雷州惠民村镇银行",
  },
  {
    bankCardCodes: "62127810",
    bankNames: "广州萝岗惠民村镇银行",
  },
  {
    bankCardCodes: "621278333",
    bankNames: "通城惠民村镇银行",
  },
  {
    bankCardCodes: "62127855",
    bankNames: "荆门东宝惠民村镇银行",
  },
  {
    bankCardCodes: "62127831",
    bankNames: "青岛平度惠民村镇银行",
  },
  {
    bankCardCodes: "62127837",
    bankNames: "含山惠民村镇银行",
  },
  {
    bankCardCodes: "62127836",
    bankNames: "庐江惠民村镇银行",
  },
  {
    bankCardCodes: "62127820",
    bankNames: "五常惠民村镇银行",
  },
  {
    bankCardCodes: "62127828",
    bankNames: "双城惠民村镇银行有限责任公司",
  },
  {
    bankCardCodes: "62127821",
    bankNames: "松原宁江惠民村镇银行",
  },
  {
    bankCardCodes: "62127822",
    bankNames: "乾安惠民村镇银行",
  },
  {
    bankCardCodes: "62127823",
    bankNames: "大安惠民村镇银行",
  },
  {
    bankCardCodes: "62127839",
    bankNames: "吉林丰满惠民村镇银行",
  },
  {
    bankCardCodes: "621278503",
    bankNames: "桦甸惠民村镇银行",
  },
  {
    bankCardCodes: "62127825",
    bankNames: "长春南关惠民村镇银行",
  },
  {
    bankCardCodes: "621278293",
    bankNames: "长春高新惠民村镇银行",
  },
  {
    bankCardCodes: "621278193",
    bankNames: "安平惠民村镇银行",
  },
  {
    bankCardCodes: "62127843",
    bankNames: "廊坊市安次区惠民村镇银行",
  },
  {
    bankCardCodes: "62127834",
    bankNames: "文安县惠民村镇银行股份有限公司",
  },
  {
    bankCardCodes: "62127852",
    bankNames: "滨海惠民村镇银行",
  },
  {
    bankCardCodes: "621265001",
    bankNames: "萍乡湘东黄海村镇银行",
  },
  {
    bankCardCodes: "62316906",
    bankNames: "东莞厚街华业村镇银行",
  },
  {
    bankCardCodes: "62316901",
    bankNames: "开县泰业村镇银行",
  },
  {
    bankCardCodes: "62316903",
    bankNames: "灵山泰业村镇银行",
  },
  {
    bankCardCodes: "62316902",
    bankNames: "东莞长安村镇银行",
  },
  {
    bankCardCodes: "62316905",
    bankNames: "东源泰业村镇银行",
  },
  {
    bankCardCodes: "62316904",
    bankNames: "枞阳泰业村镇银行",
  },
  {
    bankCardCodes: "621385663",
    bankNames: "内江兴隆村镇银行",
  },
  {
    bankCardCodes: "621383002",
    bankNames: "辽宁辰州汇通村镇银行股份有限公司",
  },
  {
    bankCardCodes: "621383001",
    bankNames: "辽宁大石桥隆丰村镇银行",
  },
  {
    bankCardCodes: "62319563",
    bankNames: "浙江泰顺温银村镇银行",
  },
  {
    bankCardCodes: "621382026",
    bankNames: "宁夏贺兰回商村镇银行",
  },
  {
    bankCardCodes: "621382022",
    bankNames: "毕节发展村镇银行",
  },
  {
    bankCardCodes: "621382011",
    bankNames: "息烽包商黔隆村镇银行",
  },
  {
    bankCardCodes: "621382027",
    bankNames: "贵阳花溪建设村镇银行",
  },
  {
    bankCardCodes: "621382024",
    bankNames: "广元包商贵民村镇银行",
  },
  {
    bankCardCodes: "621382014",
    bankNames: "新都桂城村镇银行",
  },
  {
    bankCardCodes: "621382012",
    bankNames: "武冈包商村镇银行",
  },
  {
    bankCardCodes: "621382016",
    bankNames: "掇刀包商村镇银行",
  },
  {
    bankCardCodes: "621382015",
    bankNames: "漯河市郾城包商村镇银行",
  },
  {
    bankCardCodes: "621382023",
    bankNames: "鄄城包商村镇银行",
  },
  {
    bankCardCodes: "621382021",
    bankNames: "仪征包商村镇银行",
  },
  {
    bankCardCodes: "621382017",
    bankNames: "江苏南通如皋包商村镇银行",
  },
  {
    bankCardCodes: "621382013",
    bankNames: "九台龙嘉村镇银行",
  },
  {
    bankCardCodes: "621382025",
    bankNames: "大连金州联丰村镇银行",
  },
  {
    bankCardCodes: "621382004",
    bankNames: "乌审旗包商村镇银行",
  },
  {
    bankCardCodes: "621382003",
    bankNames: "准格尔旗包商村镇银行",
  },
  {
    bankCardCodes: "621382008",
    bankNames: "化德包商村镇银行",
  },
  {
    bankCardCodes: "621382007",
    bankNames: "集宁包商村镇银行",
  },
  {
    bankCardCodes: "621382009",
    bankNames: "西乌珠穆沁包商惠丰村镇银行",
  },
  {
    bankCardCodes: "621382010",
    bankNames: "科尔沁包商村镇银行",
  },
  {
    bankCardCodes: "621382005",
    bankNames: "鄂温克旗包商村镇银行",
  },
  {
    bankCardCodes: "621382006",
    bankNames: "莫力达瓦包商村镇银行",
  },
  {
    bankCardCodes: "621382002",
    bankNames: "宁城包商村镇银行",
  },
  {
    bankCardCodes: "621382001",
    bankNames: "固阳包商惠农村镇银行",
  },
  {
    bankCardCodes: "621382020",
    bankNames: "清徐惠民村镇银行",
  },
  {
    bankCardCodes: "621382018",
    bankNames: "天津津南村镇银行",
  },
  {
    bankCardCodes: "621382019",
    bankNames: "北京昌平兆丰村镇银行",
  },
  {
    bankCardCodes: "621381001",
    bankNames: "韶山光大村镇银行",
  },
  {
    bankCardCodes: "621387973",
    bankNames: "浙江兰溪越商村镇银行",
  },
  {
    bankCardCodes: "621380001",
    bankNames: "遂平恒生村镇银行",
  },
  {
    bankCardCodes: "621689003",
    bankNames: "兴业柳银村镇银行",
  },
  {
    bankCardCodes: "621689006",
    bankNames: "博白柳银村镇银行",
  },
  {
    bankCardCodes: "621689005",
    bankNames: "陆川柳银村镇银行",
  },
  {
    bankCardCodes: "621689004",
    bankNames: "北流柳银村镇银行",
  },
  {
    bankCardCodes: "621689002",
    bankNames: "融水柳银村镇银行",
  },
  {
    bankCardCodes: "621689001",
    bankNames: "柳江柳银村镇银行",
  },
  {
    bankCardCodes: "621689007",
    bankNames: "从化柳银村镇银行",
  },
  {
    bankCardCodes: "62317501",
    bankNames: "玉溪红塔区兴和村镇银行",
  },
  {
    bankCardCodes: "62317502",
    bankNames: "楚雄兴彝村镇银行",
  },
  {
    bankCardCodes: "62312201",
    bankNames: "海南儋州绿色村镇银行",
  },
  {
    bankCardCodes: "62311701",
    bankNames: "长葛轩辕村镇银行",
  },
  {
    bankCardCodes: "62311702",
    bankNames: "开封新东方村镇银行",
  },
  {
    bankCardCodes: "62311703",
    bankNames: "尉氏合益村镇银行",
  },
  {
    bankCardCodes: "62169716",
    bankNames: "山东兰陵村镇银行股份有限公司",
  },
  {
    bankCardCodes: "621697873",
    bankNames: "芜湖泰寿村镇银行",
  },
  {
    bankCardCodes: "621697793",
    bankNames: "任丘村镇银行",
  },
  {
    bankCardCodes: "62169757",
    bankNames: "天津宁河村镇银行",
  },
  {
    bankCardCodes: "621697813",
    bankNames: "天津华明村镇银行",
  },
  {
    bankCardCodes: "62352801",
    bankNames: "安徽五河永泰村镇银行",
  },
  {
    bankCardCodes: "62169503",
    bankNames: "鄢陵郑银村镇银行",
  },
  {
    bankCardCodes: "62169501",
    bankNames: "新密郑银村镇银行",
  },
  {
    bankCardCodes: "62169502",
    bankNames: "中牟郑银村镇银行",
  },
  {
    bankCardCodes: "62169303",
    bankNames: "丹东福汇村镇银行",
  },
  {
    bankCardCodes: "62169302",
    bankNames: "丹东鼎安村镇银行",
  },
  {
    bankCardCodes: "62169301",
    bankNames: "丹东鼎元村镇银行",
  },
  {
    bankCardCodes: "621687001",
    bankNames: "灵宝融丰村镇银行",
  },
  {
    bankCardCodes: "621687913",
    bankNames: "宝丰豫丰村镇银行",
  },
  {
    bankCardCodes: "621685702",
    bankNames: "广东普宁汇成村镇银行",
  },
  {
    bankCardCodes: "621685701",
    bankNames: "广东澄海潮商村镇银行",
  },
  {
    bankCardCodes: "621682311",
    bankNames: "临沧临翔沪农商村镇银行",
  },
  {
    bankCardCodes: "621682830",
    bankNames: "瑞丽沪农商村镇银行",
  },
  {
    bankCardCodes: "621682303",
    bankNames: "瑞丽沪农商村镇银行",
  },
  {
    bankCardCodes: "621682310",
    bankNames: "保山隆阳沪农商村镇银行",
  },
  {
    bankCardCodes: "621682308",
    bankNames: "弥勒沪农商村镇银行",
  },
  {
    bankCardCodes: "621682309",
    bankNames: "建水沪农商村镇银行",
  },
  {
    bankCardCodes: "621682306",
    bankNames: "蒙自沪农商村镇银行",
  },
  {
    bankCardCodes: "621682307",
    bankNames: "开远沪农商村镇银行",
  },
  {
    bankCardCodes: "621682305",
    bankNames: "个旧沪农商村镇银行",
  },
  {
    bankCardCodes: "621682302",
    bankNames: "嵩明沪农商村镇银行",
  },
  {
    bankCardCodes: "621682301",
    bankNames: "阿拉沪农商村镇银行",
  },
  {
    bankCardCodes: "621682003",
    bankNames: "深圳光明沪农商村镇银行",
  },
  {
    bankCardCodes: "621682206",
    bankNames: "永兴沪农商村镇银行",
  },
  {
    bankCardCodes: "621682207",
    bankNames: "桂阳沪农商村镇银行",
  },
  {
    bankCardCodes: "621682212",
    bankNames: "双峰沪农商村镇银行",
  },
  {
    bankCardCodes: "621682211",
    bankNames: "涟源沪农商村镇银行",
  },
  {
    bankCardCodes: "621682213",
    bankNames: "慈利沪农商村镇银行",
  },
  {
    bankCardCodes: "621682210",
    bankNames: "石门沪农商村镇银行",
  },
  {
    bankCardCodes: "621682208",
    bankNames: "临澧沪农商村镇银行",
  },
  {
    bankCardCodes: "621682209",
    bankNames: "澧县沪农商村镇银行",
  },
  {
    bankCardCodes: "621682205",
    bankNames: "衡阳沪农商村镇银行",
  },
  {
    bankCardCodes: "621682203",
    bankNames: "醴陵沪农商村镇银行",
  },
  {
    bankCardCodes: "621682201",
    bankNames: "宁乡沪农商行村镇银行",
  },
  {
    bankCardCodes: "621682202",
    bankNames: "长沙星沙沪农商村镇银行",
  },
  {
    bankCardCodes: "621682107",
    bankNames: "日照沪农商村镇银行",
  },
  {
    bankCardCodes: "621682108",
    bankNames: "茌平沪农商村镇银行",
  },
  {
    bankCardCodes: "621682109",
    bankNames: "阳谷沪农商村镇银行",
  },
  {
    bankCardCodes: "621682111",
    bankNames: "临清沪农商村镇银行",
  },
  {
    bankCardCodes: "621682110",
    bankNames: "聊城沪农商村镇银行",
  },
  {
    bankCardCodes: "621682105",
    bankNames: "东平沪农商村镇银行",
  },
  {
    bankCardCodes: "621682103",
    bankNames: "宁阳沪农商村镇银行",
  },
  {
    bankCardCodes: "621682106",
    bankNames: "泰安沪农商村镇银行",
  },
  {
    bankCardCodes: "621682102",
    bankNames: "济南槐荫沪农商村镇银行",
  },
  {
    bankCardCodes: "621682803",
    bankNames: "济南长清沪农商村镇银行",
  },
  {
    bankCardCodes: "621682101",
    bankNames: "济南长清沪农商村镇银行",
  },
  {
    bankCardCodes: "621682002",
    bankNames: "北京房山沪农商村镇银行",
  },
  {
    bankCardCodes: "621681003",
    bankNames: "铁岭农商村镇银行",
  },
  {
    bankCardCodes: "621681002",
    bankNames: "辽宁彰武金通村镇银行股份有限公司",
  },
  {
    bankCardCodes: "621681001",
    bankNames: "沈阳于洪永安村镇银行",
  },
  {
    bankCardCodes: "621680111",
    bankNames: "云南香格里拉渝农商村镇银行",
  },
  {
    bankCardCodes: "621680011",
    bankNames: "云南香格里拉渝农商村镇银行",
  },
  {
    bankCardCodes: "621680108",
    bankNames: "云南鹤庆渝农商村镇银行",
  },
  {
    bankCardCodes: "621680008",
    bankNames: "云南鹤庆渝农商村镇银行",
  },
  {
    bankCardCodes: "621680106",
    bankNames: "云南祥云渝农商村镇银行",
  },
  {
    bankCardCodes: "621680006",
    bankNames: "云南祥云渝农商村镇银行",
  },
  {
    bankCardCodes: "621680104",
    bankNames: "云南大理渝农商村镇银行",
  },
  {
    bankCardCodes: "621680004",
    bankNames: "云南大理渝农商村镇银行",
  },
  {
    bankCardCodes: "621680103",
    bankNames: "大竹渝农商村镇银行",
  },
  {
    bankCardCodes: "621680003",
    bankNames: "大竹渝农商村镇银行",
  },
  {
    bankCardCodes: "621680105",
    bankNames: "广西鹿寨渝农商村镇银行",
  },
  {
    bankCardCodes: "621680005",
    bankNames: "广西鹿寨渝农商村镇银行",
  },
  {
    bankCardCodes: "621680110",
    bankNames: "福建福安渝农商村镇银行",
  },
  {
    bankCardCodes: "621680010",
    bankNames: "福建福安渝农商村镇银行",
  },
  {
    bankCardCodes: "621680109",
    bankNames: "福建沙县渝农商村镇银行",
  },
  {
    bankCardCodes: "621680009",
    bankNames: "福建沙县渝农商村镇银行",
  },
  {
    bankCardCodes: "621680107",
    bankNames: "福建平潭渝农商村镇银行",
  },
  {
    bankCardCodes: "621680007",
    bankNames: "福建平潭渝农商村镇银行",
  },
  {
    bankCardCodes: "621680102",
    bankNames: "张家港渝农商村镇银行",
  },
  {
    bankCardCodes: "621680002",
    bankNames: "张家港渝农商村镇银行",
  },
  {
    bankCardCodes: "621678215",
    bankNames: "福泉富民村镇银行",
  },
  {
    bankCardCodes: "621678210",
    bankNames: "安顺西秀富民村镇银行",
  },
  {
    bankCardCodes: "621678206",
    bankNames: "赫章富民村镇银行",
  },
  {
    bankCardCodes: "621678205",
    bankNames: "威宁富民村镇银行",
  },
  {
    bankCardCodes: "621678203",
    bankNames: "纳雍富民村镇银行",
  },
  {
    bankCardCodes: "621678201",
    bankNames: "金沙富民村镇银行",
  },
  {
    bankCardCodes: "621678202",
    bankNames: "大方富民村镇银行",
  },
  {
    bankCardCodes: "621678212",
    bankNames: "遵义红花岗富民村镇银行",
  },
  {
    bankCardCodes: "621678211",
    bankNames: "贵阳南明富民村镇银行",
  },
  {
    bankCardCodes: "621678209",
    bankNames: "贵阳云岩富民村镇银行",
  },
  {
    bankCardCodes: "621678207",
    bankNames: "贵阳观山湖富民村镇银行",
  },
  {
    bankCardCodes: "621678213",
    bankNames: "开阳富民村镇银行",
  },
  {
    bankCardCodes: "621678208",
    bankNames: "贵阳乌当富民村镇银行",
  },
  {
    bankCardCodes: "621678002",
    bankNames: "江西上栗富民村镇银行",
  },
  {
    bankCardCodes: "621678001",
    bankNames: "萍乡安源富民村镇银行",
  },
  {
    bankCardCodes: "621678106",
    bankNames: "浙江台州路桥富民村镇银行",
  },
  {
    bankCardCodes: "621678101",
    bankNames: "浙江遂昌富民村镇银行",
  },
  {
    bankCardCodes: "621678105",
    bankNames: "浙江上虞富民村镇银行",
  },
  {
    bankCardCodes: "621676017",
    bankNames: "丽江玉龙长江村镇银行",
  },
  {
    bankCardCodes: "621676016",
    bankNames: "丽水永胜长江村镇银行",
  },
  {
    bankCardCodes: "621676018",
    bankNames: "西双版纳勐海长江村镇银行",
  },
  {
    bankCardCodes: "621676020",
    bankNames: "曲靖会泽长江村镇银行",
  },
  {
    bankCardCodes: "621676019",
    bankNames: "曲靖宣威长江村镇银行",
  },
  {
    bankCardCodes: "621676015",
    bankNames: "昆明五华长江村镇银行",
  },
  {
    bankCardCodes: "621676004",
    bankNames: "海南澄迈长江村镇银行股份有限公司",
  },
  {
    bankCardCodes: "621676005",
    bankNames: "海南五指山长江村镇银行股份有限公司",
  },
  {
    bankCardCodes: "621676008",
    bankNames: "潮州潮安长江村镇银行",
  },
  {
    bankCardCodes: "621676011",
    bankNames: "东莞虎门长江村镇银行",
  },
  {
    bankCardCodes: "621676012",
    bankNames: "惠州博罗长江村镇银行",
  },
  {
    bankCardCodes: "621676013",
    bankNames: "茂名电白长江村镇银行",
  },
  {
    bankCardCodes: "621676006",
    bankNames: "汕头龙湖长江村镇银行",
  },
  {
    bankCardCodes: "621676003",
    bankNames: "广州增城长江村镇银行",
  },
  {
    bankCardCodes: "621676002",
    bankNames: "湖北赤壁长江村镇银行",
  },
  {
    bankCardCodes: "621676001",
    bankNames: "湖北咸安长江村镇银行",
  },
  {
    bankCardCodes: "621676010",
    bankNames: "湖北英山长江村镇银行股份有限公司",
  },
  {
    bankCardCodes: "621676009",
    bankNames: "湖北红安长江村镇银行股份有限公司",
  },
  {
    bankCardCodes: "621676014",
    bankNames: "镇江润州长江村镇银行",
  },
  {
    bankCardCodes: "621398001",
    bankNames: "江都吉银村镇银行",
  },
  {
    bankCardCodes: "621659003",
    bankNames: "珲春吉银村镇银行",
  },
  {
    bankCardCodes: "621659010",
    bankNames: "东丰吉银村镇银行",
  },
  {
    bankCardCodes: "621659008",
    bankNames: "双辽吉银村镇银行",
  },
  {
    bankCardCodes: "621659009",
    bankNames: "舒兰吉银村镇银行",
  },
  {
    bankCardCodes: "621659006",
    bankNames: "长春双阳吉银村镇银行",
  },
  {
    bankCardCodes: "621659001",
    bankNames: "永清吉银村镇银行",
  },
  {
    bankCardCodes: "621659002",
    bankNames: "沧县吉银村镇银行",
  },
  {
    bankCardCodes: "621656001",
    bankNames: "河南方城凤裕村镇银行",
  },
  {
    bankCardCodes: "62165666",
    bankNames: "东营莱商村镇银行",
  },
  {
    bankCardCodes: "621396",
    bankNames: "东营莱商村镇银行",
  },
  {
    bankCardCodes: "621656003",
    bankNames: "五台莱商村镇银行",
  },
  {
    bankCardCodes: "621656002",
    bankNames: "天津武清村镇银行",
  },
  {
    bankCardCodes: "62309701",
    bankNames: "长子县融汇村镇银行",
  },
  {
    bankCardCodes: "62309702",
    bankNames: "襄垣县融汇村镇银行",
  },
  {
    bankCardCodes: "621675001",
    bankNames: "南昌大丰村镇银行",
  },
  {
    bankCardCodes: "621355001",
    bankNames: "昆山鹿城村镇银行",
  },
  {
    bankCardCodes: "621355051",
    bankNames: "昆山鹿城村镇银行",
  },
  {
    bankCardCodes: "621355002",
    bankNames: "宜兴阳羡村镇银行",
  },
  {
    bankCardCodes: "621355052",
    bankNames: "宜兴阳羡村镇银行",
  },
  {
    bankCardCodes: "62319803",
    bankNames: "贺州八步东盈村镇银行",
  },
  {
    bankCardCodes: "62319802",
    bankNames: "云浮新兴东盈民生村镇银行",
  },
  {
    bankCardCodes: "62319806",
    bankNames: "东莞大朗东盈村镇银行",
  },
  {
    bankCardCodes: "62319801",
    bankNames: "惠州仲恺东盈村镇银行",
  },
  {
    bankCardCodes: "621316001",
    bankNames: "青岛胶南海汇村镇银行",
  },
  {
    bankCardCodes: "621628660",
    bankNames: "佛山高明顺银村镇银行",
  },
  {
    bankCardCodes: "621628662",
    bankNames: "樟树顺银村镇银行",
  },
  {
    bankCardCodes: "621628661",
    bankNames: "丰城顺银村镇银行",
  },
  {
    bankCardCodes: "62308299",
    bankNames: "广元贵商村镇银行",
  },
  {
    bankCardCodes: "621653001",
    bankNames: "吴忠市滨河村镇银行",
  },
  {
    bankCardCodes: "621653006",
    bankNames: "大武口石银村镇银行",
  },
  {
    bankCardCodes: "621653007",
    bankNames: "银川掌政石银村镇银行",
  },
  {
    bankCardCodes: "621653005",
    bankNames: "重庆江津石银村镇银行",
  },
  {
    bankCardCodes: "621653004",
    bankNames: "重庆南川石银村镇银行",
  },
  {
    bankCardCodes: "621653003",
    bankNames: "青岛莱西元泰村镇银行",
  },
  {
    bankCardCodes: "621653002",
    bankNames: "安徽肥西石银村镇银行",
  },
  {
    bankCardCodes: "62163750",
    bankNames: "鄂托克旗汇泽村镇银行",
  },
  {
    bankCardCodes: "621637003",
    bankNames: "鄂托克旗汇泽村镇银行",
  },
  {
    bankCardCodes: "621637002",
    bankNames: "罕台村镇银行",
  },
  {
    bankCardCodes: "621637001",
    bankNames: "鄂尔多斯市康巴什村镇银行",
  },
  {
    bankCardCodes: "621637004",
    bankNames: "兴和汇泽村镇银行",
  },
  {
    bankCardCodes: "621637005",
    bankNames: "正蓝旗汇泽村镇银行",
  },
  {
    bankCardCodes: "621637007",
    bankNames: "柳林汇泽村镇银行",
  },
  {
    bankCardCodes: "621637006",
    bankNames: "兴县汇泽村镇银行",
  },
  {
    bankCardCodes: "621637008",
    bankNames: "古交汇泽村镇银行",
  },
  {
    bankCardCodes: "621637009",
    bankNames: "唐县汇泽村镇银行",
  },
  {
    bankCardCodes: "62163111",
    bankNames: "彭山珠江村镇银行",
  },
  {
    bankCardCodes: "62163110",
    bankNames: "广汉珠江村镇银行",
  },
  {
    bankCardCodes: "62163109",
    bankNames: "新津珠江村镇银行",
  },
  {
    bankCardCodes: "62163102",
    bankNames: "中山东凤珠江村镇银行",
  },
  {
    bankCardCodes: "62163123",
    bankNames: "兴宁珠江村镇银行",
  },
  {
    bankCardCodes: "62163101",
    bankNames: "鹤山珠江村镇银行",
  },
  {
    bankCardCodes: "621310",
    bankNames: "三水珠江村镇银行",
  },
  {
    bankCardCodes: "62163125",
    bankNames: "深圳坪山珠江村镇银行",
  },
  {
    bankCardCodes: "62163121",
    bankNames: "常宁珠江村镇银行",
  },
  {
    bankCardCodes: "62163107",
    bankNames: "信阳珠江村镇银行",
  },
  {
    bankCardCodes: "62163108",
    bankNames: "辉县珠江村镇银行",
  },
  {
    bankCardCodes: "62163118",
    bankNames: "安阳珠江村镇银行",
  },
  {
    bankCardCodes: "62163104",
    bankNames: "莱芜珠江村镇银行",
  },
  {
    bankCardCodes: "62163115",
    bankNames: "莱州珠江村镇银行",
  },
  {
    bankCardCodes: "62163116",
    bankNames: "海阳珠江村镇银行",
  },
  {
    bankCardCodes: "62163114",
    bankNames: "福山珠江村镇银行",
  },
  {
    bankCardCodes: "62163117",
    bankNames: "青岛城阳珠江村镇银行",
  },
  {
    bankCardCodes: "62163112",
    bankNames: "吉安吉州珠江村镇银行",
  },
  {
    bankCardCodes: "62163120",
    bankNames: "盱眙珠江村镇银行",
  },
  {
    bankCardCodes: "62163119",
    bankNames: "启东珠江村镇银行",
  },
  {
    bankCardCodes: "62163122",
    bankNames: "苏州吴中珠江村镇银行",
  },
  {
    bankCardCodes: "62163103",
    bankNames: "大连保税区珠江村镇银行",
  },
  {
    bankCardCodes: "62163113",
    bankNames: "北京门头沟珠江村镇银行",
  },
  {
    bankCardCodes: "621630001",
    bankNames: "清河金农村镇银行",
  },
  {
    bankCardCodes: "621630003",
    bankNames: "沙河襄通村镇银行",
  },
  {
    bankCardCodes: "621630002",
    bankNames: "迁安襄隆村镇银行",
  },
  {
    bankCardCodes: "621650101",
    bankNames: "琼海国民村镇银行",
  },
  {
    bankCardCodes: "621650001",
    bankNames: "琼海国民村镇银行",
  },
  {
    bankCardCodes: "621650102",
    bankNames: "文昌国民村镇银行",
  },
  {
    bankCardCodes: "621650002",
    bankNames: "文昌国民村镇银行",
  },
  {
    bankCardCodes: "621635116",
    bankNames: "北屯国民村镇银行",
  },
  {
    bankCardCodes: "621635102",
    bankNames: "五家渠国民村镇银行",
  },
  {
    bankCardCodes: "621635106",
    bankNames: "石河子国民村镇银行",
  },
  {
    bankCardCodes: "621635120",
    bankNames: "伊犁国民村镇银行",
  },
  {
    bankCardCodes: "621635123",
    bankNames: "奎屯国民村镇银行",
  },
  {
    bankCardCodes: "621635125",
    bankNames: "库车国民村镇银行",
  },
  {
    bankCardCodes: "621635117",
    bankNames: "博乐国民村镇银行",
  },
  {
    bankCardCodes: "621635108",
    bankNames: "昌吉国民村镇银行",
  },
  {
    bankCardCodes: "621635115",
    bankNames: "哈密红星国民村镇银行",
  },
  {
    bankCardCodes: "621635122",
    bankNames: "克拉玛依金龙国民村镇银行",
  },
  {
    bankCardCodes: "621635118",
    bankNames: "绿洲国民村镇银行",
  },
  {
    bankCardCodes: "621635107",
    bankNames: "邛崃国民村镇银行",
  },
  {
    bankCardCodes: "621635007",
    bankNames: "邛崃国民村镇银行",
  },
  {
    bankCardCodes: "621635105",
    bankNames: "东兴国民村镇银行",
  },
  {
    bankCardCodes: "621635005",
    bankNames: "东兴国民村镇银行",
  },
  {
    bankCardCodes: "621635110",
    bankNames: "防城港防城国民村镇银行",
  },
  {
    bankCardCodes: "621635010",
    bankNames: "防城港防城国民村镇银行",
  },
  {
    bankCardCodes: "621635119",
    bankNames: "广西浦北国民村镇银行",
  },
  {
    bankCardCodes: "621635113",
    bankNames: "钦州市钦南国民村镇银行",
  },
  {
    bankCardCodes: "621635013",
    bankNames: "钦州市钦南国民村镇银行",
  },
  {
    bankCardCodes: "621635111",
    bankNames: "平果国民村镇银行",
  },
  {
    bankCardCodes: "621635109",
    bankNames: "合浦国民村镇银行",
  },
  {
    bankCardCodes: "621635112",
    bankNames: "银海国民村镇银行",
  },
  {
    bankCardCodes: "621635104",
    bankNames: "桂林国民村镇银行",
  },
  {
    bankCardCodes: "621635004",
    bankNames: "桂林国民村镇银行",
  },
  {
    bankCardCodes: "621635124",
    bankNames: "广西上林国民村镇银行",
  },
  {
    bankCardCodes: "621635103",
    bankNames: "南宁江南国民村镇银行",
  },
  {
    bankCardCodes: "621635003",
    bankNames: "南宁江南国民村镇银行",
  },
  {
    bankCardCodes: "621635114",
    bankNames: "宁波市鄞州国民村镇银行",
  },
  {
    bankCardCodes: "621635101",
    bankNames: "象山国民村镇银行",
  },
  {
    bankCardCodes: "621627010",
    bankNames: "云南安宁稠州村镇银行",
  },
  {
    bankCardCodes: "621627006",
    bankNames: "忠县稠州村镇银行",
  },
  {
    bankCardCodes: "621627003",
    bankNames: "重庆北碚稠州村镇银行",
  },
  {
    bankCardCodes: "621627009",
    bankNames: "龙泉驿稠州村镇银行",
  },
  {
    bankCardCodes: "621627007",
    bankNames: "广州花都稠州村镇银行",
  },
  {
    bankCardCodes: "621627001",
    bankNames: "吉安稠州村镇银行",
  },
  {
    bankCardCodes: "621627002",
    bankNames: "浙江岱山稠州村镇银行",
  },
  {
    bankCardCodes: "621627005",
    bankNames: "浙江舟山普陀稠州村镇银行",
  },
  {
    bankCardCodes: "621627008",
    bankNames: "江苏东台稠州村镇银行",
  },
  {
    bankCardCodes: "621397101",
    bankNames: "江苏大丰江南村镇银行",
  },
  {
    bankCardCodes: "621397001",
    bankNames: "江苏大丰江南村镇银行",
  },
  {
    bankCardCodes: "621623001",
    bankNames: "宝生村镇银行",
  },
  {
    bankCardCodes: "62139383",
    bankNames: "新疆库尔勒富民村镇银行",
  },
  {
    bankCardCodes: "621393001",
    bankNames: "梅县客家村镇银行",
  },
  {
    bankCardCodes: "623143002",
    bankNames: "汾西县亿通村镇银行",
  },
  {
    bankCardCodes: "623143001",
    bankNames: "晋中市左权华丰村镇银行",
  },
  {
    bankCardCodes: "621481",
    bankNames: "晋中市榆次融信村镇银行",
  },
  {
    bankCardCodes: "623143003",
    bankNames: "吕梁孝义汇通村镇银行",
  },
  {
    bankCardCodes: "621401002",
    bankNames: "福鼎恒兴村镇银行",
  },
  {
    bankCardCodes: "62140101",
    bankNames: "福鼎恒兴村镇银行",
  },
  {
    bankCardCodes: "621401001",
    bankNames: "福建连江恒欣村镇银行",
  },
  {
    bankCardCodes: "621365010",
    bankNames: "安徽谯城湖商村镇银行",
  },
  {
    bankCardCodes: "621365002",
    bankNames: "安徽利辛湖商村镇银行",
  },
  {
    bankCardCodes: "621365003",
    bankNames: "安徽蒙城湖商村镇银行",
  },
  {
    bankCardCodes: "621365008",
    bankNames: "安徽涡阳湖商村镇银行",
  },
  {
    bankCardCodes: "621365009",
    bankNames: "安徽宣州湖商村镇银行",
  },
  {
    bankCardCodes: "621365004",
    bankNames: "安徽粤西湖商村镇银行",
  },
  {
    bankCardCodes: "621365007",
    bankNames: "安徽濉溪湖商卡村镇银行",
  },
  {
    bankCardCodes: "621365005",
    bankNames: "浙江德清湖商村镇银行",
  },
  {
    bankCardCodes: "621365011",
    bankNames: "浙江海盐湖商村镇银行",
  },
  {
    bankCardCodes: "621365012",
    bankNames: "浙江瑞安湖商村镇银行",
  },
  {
    bankCardCodes: "621365001",
    bankNames: "浙江建德湖商村镇银行",
  },
  {
    bankCardCodes: "621365006",
    bankNames: "浙江萧山湖商村镇银行",
  },
  {
    bankCardCodes: "621399022",
    bankNames: "榆林榆阳民生村镇银行",
  },
  {
    bankCardCodes: "621399020",
    bankNames: "志丹民生村镇银行",
  },
  {
    bankCardCodes: "621399027",
    bankNames: "腾冲民生村镇银行",
  },
  {
    bankCardCodes: "621399019",
    bankNames: "景洪民生村镇银行",
  },
  {
    bankCardCodes: "621399021",
    bankNames: "普洱民生村镇银行",
  },
  {
    bankCardCodes: "621399006",
    bankNames: "潼南民生村镇银行",
  },
  {
    bankCardCodes: "621399005",
    bankNames: "綦江民生村镇银行",
  },
  {
    bankCardCodes: "621399007",
    bankNames: "资阳民生村镇银行",
  },
  {
    bankCardCodes: "621399003",
    bankNames: "彭州民生村镇银行",
  },
  {
    bankCardCodes: "621399013",
    bankNames: "钟祥民生村镇银行",
  },
  {
    bankCardCodes: "621399011",
    bankNames: "宜都民生村镇银行",
  },
  {
    bankCardCodes: "621399009",
    bankNames: "江夏民生村镇银行",
  },
  {
    bankCardCodes: "621399010",
    bankNames: "长垣民生村镇银行",
  },
  {
    bankCardCodes: "621399014",
    bankNames: "蓬莱民生村镇银行",
  },
  {
    bankCardCodes: "621399018",
    bankNames: "漳浦民生村镇银行",
  },
  {
    bankCardCodes: "621399002",
    bankNames: "安溪民生村镇银行",
  },
  {
    bankCardCodes: "621399028",
    bankNames: "厦门翔安民生村镇银行",
  },
  {
    bankCardCodes: "621399024",
    bankNames: "池州贵池民生村镇银行",
  },
  {
    bankCardCodes: "621399023",
    bankNames: "宁国民生村镇银行",
  },
  {
    bankCardCodes: "621399026",
    bankNames: "天长民生村镇银行",
  },
  {
    bankCardCodes: "621399025",
    bankNames: "天台民生村镇银行",
  },
  {
    bankCardCodes: "621399015",
    bankNames: "阜宁民生村镇银行",
  },
  {
    bankCardCodes: "621399016",
    bankNames: "太仓民生村镇银行",
  },
  {
    bankCardCodes: "621399012",
    bankNames: "嘉定民生村镇银行",
  },
  {
    bankCardCodes: "621399001",
    bankNames: "上海松江民生村镇银行",
  },
  {
    bankCardCodes: "621399008",
    bankNames: "梅河口民生村镇银行",
  },
  {
    bankCardCodes: "621399017",
    bankNames: "宁晋民生村镇银行",
  },
  {
    bankCardCodes: "621392887",
    bankNames: "南阳村镇银行",
  },
  {
    bankCardCodes: "621392",
    bankNames: "南阳村镇银行",
  },
  {
    bankCardCodes: "623589943",
    bankNames: "会宁会师村镇银行",
  },
  {
    bankCardCodes: "621350943",
    bankNames: "会宁会师村镇银行",
  },
  {
    bankCardCodes: "623589018",
    bankNames: "重庆市酉阳融兴村镇银行",
  },
  {
    bankCardCodes: "623589019",
    bankNames: "重庆市沙坪坝融兴村镇银行",
  },
  {
    bankCardCodes: "621350018",
    bankNames: "重庆市酋阳融兴村镇银行",
  },
  {
    bankCardCodes: "623589008",
    bankNames: "重庆市武隆融兴村镇银行",
  },
  {
    bankCardCodes: "621350008",
    bankNames: "重庆市武隆融兴村镇银行",
  },
  {
    bankCardCodes: "621350012",
    bankNames: "重庆大渡口融兴村镇银行",
  },
  {
    bankCardCodes: "623589012",
    bankNames: "重庆市大渡口融兴村镇银行",
  },
  {
    bankCardCodes: "621350019",
    bankNames: "重庆沙坪坝融兴村镇银行",
  },
  {
    bankCardCodes: "623589014",
    bankNames: "遂宁安居融兴村镇银行",
  },
  {
    bankCardCodes: "621350014",
    bankNames: "遂宁安居融兴村镇银行",
  },
  {
    bankCardCodes: "621350017",
    bankNames: "海南保亭融兴村镇银行",
  },
  {
    bankCardCodes: "623589017",
    bankNames: "海南保亭融兴村镇银行",
  },
  {
    bankCardCodes: "623589755",
    bankNames: "深圳宝安融兴村镇银行",
  },
  {
    bankCardCodes: "621350755",
    bankNames: "深圳宝安融兴村镇银行",
  },
  {
    bankCardCodes: "623589007",
    bankNames: "耒阳融兴村镇银行",
  },
  {
    bankCardCodes: "621350007",
    bankNames: "耒阳融兴村镇银行",
  },
  {
    bankCardCodes: "623589016",
    bankNames: "株洲县融兴村镇银行",
  },
  {
    bankCardCodes: "621350016",
    bankNames: "株洲县融兴村镇银行",
  },
  {
    bankCardCodes: "623589011",
    bankNames: "洪湖融兴村镇银行",
  },
  {
    bankCardCodes: "621350011",
    bankNames: "洪湖融兴村镇银行",
  },
  {
    bankCardCodes: "623589006",
    bankNames: "应城融兴村镇银行",
  },
  {
    bankCardCodes: "621350006",
    bankNames: "应城融兴村镇银行",
  },
  {
    bankCardCodes: "623589015",
    bankNames: "偃师融兴村镇银行",
  },
  {
    bankCardCodes: "623589004",
    bankNames: "新安融兴村镇银行",
  },
  {
    bankCardCodes: "621350004",
    bankNames: "新安融兴村镇银行",
  },
  {
    bankCardCodes: "621350015",
    bankNames: "偃师融兴村镇银行",
  },
  {
    bankCardCodes: "623589002",
    bankNames: "乐平融兴村镇银行",
  },
  {
    bankCardCodes: "621350002",
    bankNames: "乐平融兴村镇银行",
  },
  {
    bankCardCodes: "623589003",
    bankNames: "安义融兴村镇银行",
  },
  {
    bankCardCodes: "621350003",
    bankNames: "安义融兴村镇银行",
  },
  {
    bankCardCodes: "623589009",
    bankNames: "江苏如东融兴村镇银行",
  },
  {
    bankCardCodes: "621350009",
    bankNames: "江苏如东融兴村镇银行",
  },
  {
    bankCardCodes: "621350005",
    bankNames: "桦川融兴村镇银行",
  },
  {
    bankCardCodes: "623589005",
    bankNames: "桦川融兴村镇银行",
  },
  {
    bankCardCodes: "623589013",
    bankNames: "拜泉融兴村镇银行",
  },
  {
    bankCardCodes: "621350013",
    bankNames: "拜泉融兴村镇银行",
  },
  {
    bankCardCodes: "621350001",
    bankNames: "延寿融兴村镇银行",
  },
  {
    bankCardCodes: "621350451",
    bankNames: "巴彦融兴村镇银行",
  },
  {
    bankCardCodes: "623589451",
    bankNames: "巴彦融兴村镇银行",
  },
  {
    bankCardCodes: "623589001",
    bankNames: "延寿融兴村镇银行",
  },
  {
    bankCardCodes: "623589431",
    bankNames: "榆树融兴村镇银行",
  },
  {
    bankCardCodes: "621350431",
    bankNames: "榆树融兴村镇银行",
  },
  {
    bankCardCodes: "621350020",
    bankNames: "河间融惠村镇银行",
  },
  {
    bankCardCodes: "623589020",
    bankNames: "河间融惠村镇银行",
  },
  {
    bankCardCodes: "623589010",
    bankNames: "北京怀柔融兴村镇银行",
  },
  {
    bankCardCodes: "621350010",
    bankNames: "北京怀柔融兴村镇银行",
  },
  {
    bankCardCodes: "621347007",
    bankNames: "重庆黔江银座村镇银行",
  },
  {
    bankCardCodes: "621347006",
    bankNames: "重庆渝北银座村镇银行",
  },
  {
    bankCardCodes: "621347101",
    bankNames: "深圳福田银座村镇银行",
  },
  {
    bankCardCodes: "621347001",
    bankNames: "深圳福田银座村镇银行",
  },
  {
    bankCardCodes: "621347003",
    bankNames: "江西赣州银座村镇银行",
  },
  {
    bankCardCodes: "621347005",
    bankNames: "浙江三门银座村镇银行",
  },
  {
    bankCardCodes: "62515901",
    bankNames: "浙江三门银座村镇银行",
  },
  {
    bankCardCodes: "621347008",
    bankNames: "浙江景宁银座村镇银行",
  },
  {
    bankCardCodes: "621347002",
    bankNames: "北京顺义银座村镇银行",
  },
  {
    bankCardCodes: "621356043",
    bankNames: "蒲城中银富登村镇银行",
  },
  {
    bankCardCodes: "621356031",
    bankNames: "乾县中银富登村镇银行",
  },
  {
    bankCardCodes: "621356057",
    bankNames: "凤翔中银富登村镇银行",
  },
  {
    bankCardCodes: "621356022",
    bankNames: "重庆石柱中银富登村镇银行",
  },
  {
    bankCardCodes: "621356039",
    bankNames: "重庆巫山中银富登村镇银行",
  },
  {
    bankCardCodes: "621356041",
    bankNames: "重庆奉节中银富登村镇银行",
  },
  {
    bankCardCodes: "621356058",
    bankNames: "重庆城口中银富登村镇银行",
  },
  {
    bankCardCodes: "621356052",
    bankNames: "万州中银富登村镇银行",
  },
  {
    bankCardCodes: "621356037",
    bankNames: "重庆合川中银富登村镇银行",
  },
  {
    bankCardCodes: "621356028",
    bankNames: "重庆长寿中银富登村镇银行",
  },
  {
    bankCardCodes: "621356053",
    bankNames: "武胜中银富登村镇银行",
  },
  {
    bankCardCodes: "621356011",
    bankNames: "监利中银富登村镇银行",
  },
  {
    bankCardCodes: "621356042",
    bankNames: "公安中银富登村镇银行",
  },
  {
    bankCardCodes: "621356012",
    bankNames: "松滋中银富登村镇银行",
  },
  {
    bankCardCodes: "621356010",
    bankNames: "潜江中银富登村镇银行",
  },
  {
    bankCardCodes: "621356061",
    bankNames: "黄梅中银富登村镇银行",
  },
  {
    bankCardCodes: "621356001",
    bankNames: "蕲春中银富登村镇银行",
  },
  {
    bankCardCodes: "621356062",
    bankNames: "武穴中银富登村镇银行",
  },
  {
    bankCardCodes: "621356045",
    bankNames: "沙洋中银富登村镇银行",
  },
  {
    bankCardCodes: "621356002",
    bankNames: "京山中银富登村镇银行",
  },
  {
    bankCardCodes: "621356008",
    bankNames: "枣阳中银富登村镇银行",
  },
  {
    bankCardCodes: "621356009",
    bankNames: "老河口中银富登村镇银行",
  },
  {
    bankCardCodes: "621356007",
    bankNames: "谷城中银富登村镇银行",
  },
  {
    bankCardCodes: "621356050",
    bankNames: "南漳中银富登村镇银行",
  },
  {
    bankCardCodes: "621356021",
    bankNames: "项城中银富登村镇银行",
  },
  {
    bankCardCodes: "621356019",
    bankNames: "临颍中银富登村镇银行",
  },
  {
    bankCardCodes: "621356020",
    bankNames: "滑县中银富登村镇银行",
  },
  {
    bankCardCodes: "621356025",
    bankNames: "五莲中银富登村镇银行",
  },
  {
    bankCardCodes: "621356049",
    bankNames: "东明中银富登村镇银行",
  },
  {
    bankCardCodes: "621356036",
    bankNames: "巨野中银富登村镇银行",
  },
  {
    bankCardCodes: "621356017",
    bankNames: "单县中银富登村镇银行",
  },
  {
    bankCardCodes: "621356003",
    bankNames: "曹县中银富登村镇银行",
  },
  {
    bankCardCodes: "621356004",
    bankNames: "沂水中银富登村镇银行",
  },
  {
    bankCardCodes: "621356006",
    bankNames: "临邑中银富登村镇银行",
  },
  {
    bankCardCodes: "621356024",
    bankNames: "曲阜中银富登村镇银行",
  },
  {
    bankCardCodes: "621356048",
    bankNames: "汶上中银富登村镇银行",
  },
  {
    bankCardCodes: "621356018",
    bankNames: "嘉祥中银富登村镇银行",
  },
  {
    bankCardCodes: "621356005",
    bankNames: "青州中银富登村镇银行",
  },
  {
    bankCardCodes: "621356023",
    bankNames: "栖霞中银富登村镇银行",
  },
  {
    bankCardCodes: "621356027",
    bankNames: "安福中银富登村镇银行",
  },
  {
    bankCardCodes: "621356054",
    bankNames: "泰和中银富登村镇银行",
  },
  {
    bankCardCodes: "621356047",
    bankNames: "上饶中银富登村镇银行",
  },
  {
    bankCardCodes: "621356029",
    bankNames: "宜丰中银富登村镇银行",
  },
  {
    bankCardCodes: "621356015",
    bankNames: "全椒中银富登村镇银行",
  },
  {
    bankCardCodes: "621356016",
    bankNames: "来安中银富登村镇银行",
  },
  {
    bankCardCodes: "621356026",
    bankNames: "界首中银富登村镇银行",
  },
  {
    bankCardCodes: "621356030",
    bankNames: "颍上中银富登村镇银行",
  },
  {
    bankCardCodes: "621356044",
    bankNames: "阜南中银富登村镇银行",
  },
  {
    bankCardCodes: "621356046",
    bankNames: "太和中银富登村镇银行",
  },
  {
    bankCardCodes: "621356055",
    bankNames: "临泉中银富登村镇银行",
  },
  {
    bankCardCodes: "621356013",
    bankNames: "宁海中银富登村镇银行",
  },
  {
    bankCardCodes: "621356038",
    bankNames: "象山中银富登村镇银行",
  },
  {
    bankCardCodes: "621356014",
    bankNames: "宁波镇海中银富登村镇银行",
  },
  {
    bankCardCodes: "621356040",
    bankNames: "响水中银富登村镇银行",
  },
  {
    bankCardCodes: "621356034",
    bankNames: "苏州吴江中银富登村镇银行",
  },
  {
    bankCardCodes: "621356032",
    bankNames: "睢宁中银富登村镇银行",
  },
  {
    bankCardCodes: "621353120",
    bankNames: "兰州七里河新华村镇银行",
  },
  {
    bankCardCodes: "621353119",
    bankNames: "兰州皋兰新华村镇银行",
  },
  {
    bankCardCodes: "621353118",
    bankNames: "兰州永登新华村镇银行",
  },
  {
    bankCardCodes: "621353111",
    bankNames: "东莞常平新华村镇银行",
  },
  {
    bankCardCodes: "621353112",
    bankNames: "江门新会新华村镇银行",
  },
  {
    bankCardCodes: "621353110",
    bankNames: "佛山南海新华村镇银行",
  },
  {
    bankCardCodes: "621353101",
    bankNames: "广州番禹新华村镇银行",
  },
  {
    bankCardCodes: "621353001",
    bankNames: "广州番禹新华村镇银行",
  },
  {
    bankCardCodes: "621353109",
    bankNames: "山东博兴新华村镇银行",
  },
  {
    bankCardCodes: "621353106",
    bankNames: "江西兴国新华村镇银行",
  },
  {
    bankCardCodes: "621353006",
    bankNames: "江西兴国新华村镇银行",
  },
  {
    bankCardCodes: "621353103",
    bankNames: "郎溪新华村镇银行",
  },
  {
    bankCardCodes: "621353003",
    bankNames: "郎溪新华村镇银行",
  },
  {
    bankCardCodes: "621353107",
    bankNames: "望江新华村镇银行",
  },
  {
    bankCardCodes: "621353007",
    bankNames: "望江新华村镇银行",
  },
  {
    bankCardCodes: "621353105",
    bankNames: "安徽和县新华村镇银行",
  },
  {
    bankCardCodes: "621353005",
    bankNames: "安徽和县新华村镇银行",
  },
  {
    bankCardCodes: "621353102",
    bankNames: "安徽当涂新华村镇银行",
  },
  {
    bankCardCodes: "621353002",
    bankNames: "安徽当涂新华村镇银行",
  },
  {
    bankCardCodes: "621353117",
    bankNames: "大厂回族自治县新华村镇银行",
  },
  {
    bankCardCodes: "621353115",
    bankNames: "沧州海兴新华村镇银行",
  },
  {
    bankCardCodes: "621353113",
    bankNames: "沧州盐山新华村镇银行",
  },
  {
    bankCardCodes: "621353108",
    bankNames: "天津静海新华村镇银行",
  },
  {
    bankCardCodes: "621353008",
    bankNames: "天津静海新华村镇银行",
  },
  {
    bankCardCodes: "621338009",
    bankNames: "重庆彭水民泰村镇银行",
  },
  {
    bankCardCodes: "621338006",
    bankNames: "重庆九龙坡民泰村镇银行",
  },
  {
    bankCardCodes: "621338010",
    bankNames: "广州白云民泰村镇银行",
  },
  {
    bankCardCodes: "621338002",
    bankNames: "福建漳平民泰村镇银行",
  },
  {
    bankCardCodes: "621338003",
    bankNames: "浙江龙泉民泰村镇银行",
  },
  {
    bankCardCodes: "621338007",
    bankNames: "浙江桐乡民泰村镇银行",
  },
  {
    bankCardCodes: "621338001",
    bankNames: "江苏邗江民泰村镇银行",
  },
  {
    bankCardCodes: "621338008",
    bankNames: "江苏金湖民泰村镇银行",
  },
  {
    bankCardCodes: "621338005",
    bankNames: "江苏惠山民泰村镇银行",
  },
  {
    bankCardCodes: "621326763",
    bankNames: "中山小榄村镇银行",
  },
  {
    bankCardCodes: "621326949",
    bankNames: "日照九银村镇银行",
  },
  {
    bankCardCodes: "621326929",
    bankNames: "井冈山九银村镇银行",
  },
  {
    bankCardCodes: "621326959",
    bankNames: "贵溪九银村镇银行股份有限公司",
  },
  {
    bankCardCodes: "621326939",
    bankNames: "修水九银村镇银行",
  },
  {
    bankCardCodes: "621326979",
    bankNames: "南昌昌东九银村镇银行",
  },
  {
    bankCardCodes: "621326969",
    bankNames: "南京六合九银村镇银行",
  },
  {
    bankCardCodes: "621326919",
    bankNames: "北京大兴九银村镇银行",
  },
  {
    bankCardCodes: "621346001",
    bankNames: "深圳龙岗鼎业村镇银行",
  },
  {
    bankCardCodes: "62134618",
    bankNames: "大连旅顺口蒙银村镇银行",
  },
  {
    bankCardCodes: "621346002",
    bankNames: "方大村镇银行",
  },
  {
    bankCardCodes: "62134606",
    bankNames: "五原蒙银村镇银行",
  },
  {
    bankCardCodes: "62134625",
    bankNames: "鄂尔多斯市东胜蒙银村镇银行",
  },
  {
    bankCardCodes: "621346003",
    bankNames: "鄂尔多斯市东胜蒙银村镇银行",
  },
  {
    bankCardCodes: "62134601",
    bankNames: "察哈尔右翼前旗蒙银村镇银行",
  },
  {
    bankCardCodes: "62134602",
    bankNames: "卓资蒙银村镇银行",
  },
  {
    bankCardCodes: "62134621",
    bankNames: "开鲁蒙银村镇银行",
  },
  {
    bankCardCodes: "62134622",
    bankNames: "霍林郭勒蒙银村镇银行",
  },
  {
    bankCardCodes: "62134609",
    bankNames: "突泉屯蒙银村镇银行",
  },
  {
    bankCardCodes: "62134604",
    bankNames: "扎赉特蒙银村镇银行",
  },
  {
    bankCardCodes: "62134603",
    bankNames: "扎兰屯蒙银村镇银行",
  },
  {
    bankCardCodes: "62134620",
    bankNames: "包头市昆都仑蒙银村镇银行",
  },
  {
    bankCardCodes: "62134608",
    bankNames: "土默特右旗蒙银村镇银行",
  },
  {
    bankCardCodes: "62134605",
    bankNames: "呼和浩特市新城蒙银村镇银行",
  },
  {
    bankCardCodes: "62134616",
    bankNames: "呼和浩特市赛罕蒙银村镇银行",
  },
  {
    bankCardCodes: "62134617",
    bankNames: "呼和浩特市如意蒙银村镇银行",
  },
  {
    bankCardCodes: "62134614",
    bankNames: "朔州市城区蒙银村镇银行",
  },
  {
    bankCardCodes: "62134615",
    bankNames: "三河蒙银村镇银行",
  },
  {
    bankCardCodes: "628267",
    bankNames: "重庆农村商业银行",
  },
  {
    bankCardCodes: "622218",
    bankNames: "重庆农村商业银行",
  },
  {
    bankCardCodes: "621241001",
    bankNames: "江苏沭阳东吴村镇银行",
  },
  {
    bankCardCodes: "621250007",
    bankNames: "重庆丰都汇丰村镇银行",
  },
  {
    bankCardCodes: "621250009",
    bankNames: "重庆荣昌汇丰村镇银行",
  },
  {
    bankCardCodes: "621250002",
    bankNames: "重庆大足汇丰村镇银行有限责任公司",
  },
  {
    bankCardCodes: "621250005",
    bankNames: "广东恩平汇丰村镇银行",
  },
  {
    bankCardCodes: "621250010",
    bankNames: "湖南平江汇丰村镇银行",
  },
  {
    bankCardCodes: "621250008",
    bankNames: "湖北天门汇丰村镇银行",
  },
  {
    bankCardCodes: "621250012",
    bankNames: "湖北麻城汇丰村镇银行",
  },
  {
    bankCardCodes: "621250001",
    bankNames: "湖北随州曾都汇丰村镇银行",
  },
  {
    bankCardCodes: "621250011",
    bankNames: "山东荣成汇丰村镇银行",
  },
  {
    bankCardCodes: "621250003",
    bankNames: "福建永安汇丰村镇银行",
  },
  {
    bankCardCodes: "621250006",
    bankNames: "大连普兰店汇丰村镇银行",
  },
  {
    bankCardCodes: "621250004",
    bankNames: "北京密云汇丰村镇银行",
  },
  {
    bankCardCodes: "621229",
    bankNames: "重庆璧山工银村镇银行",
  },
  {
    bankCardCodes: "621230",
    bankNames: "浙江平湖工银村镇银行",
  },
  {
    bankCardCodes: "621219",
    bankNames: "寿光张农商村镇银行",
  },
  {
    bankCardCodes: "621308",
    bankNames: "江苏东海张农商村镇银行",
  },
  {
    bankCardCodes: "62109213",
    bankNames: "安徽霍山联合村镇银行",
  },
  {
    bankCardCodes: "621092010",
    bankNames: "安徽霍山联合村镇银行",
  },
  {
    bankCardCodes: "62109215",
    bankNames: "安徽霍邱联合村镇银行",
  },
  {
    bankCardCodes: "621092012",
    bankNames: "安徽霍邱联合村镇银行",
  },
  {
    bankCardCodes: "62109214",
    bankNames: "安徽寿县联合村镇银行",
  },
  {
    bankCardCodes: "621092011",
    bankNames: "安徽寿县联合村镇银行",
  },
  {
    bankCardCodes: "62109206",
    bankNames: "浙江温岭联合村镇银行",
  },
  {
    bankCardCodes: "621092005",
    bankNames: "浙江温岭联合村镇银行",
  },
  {
    bankCardCodes: "62109212",
    bankNames: "浙江云和联合村镇银行",
  },
  {
    bankCardCodes: "621092004",
    bankNames: "浙江常山联合村镇银行",
  },
  {
    bankCardCodes: "62109205",
    bankNames: "浙江义乌联合村镇银行",
  },
  {
    bankCardCodes: "621092006",
    bankNames: "浙江义乌联合村镇银行",
  },
  {
    bankCardCodes: "62109211",
    bankNames: "浙江诸暨联合村镇银行",
  },
  {
    bankCardCodes: "62109208",
    bankNames: "浙江柯桥联合村镇银行",
  },
  {
    bankCardCodes: "621092008",
    bankNames: "浙江柯桥联合村镇银行",
  },
  {
    bankCardCodes: "62109203",
    bankNames: "浙江长兴联合村镇银行",
  },
  {
    bankCardCodes: "621092001",
    bankNames: "浙江长兴联合村镇银行",
  },
  {
    bankCardCodes: "62109202",
    bankNames: "浙江嘉善联合村镇银行",
  },
  {
    bankCardCodes: "621092002",
    bankNames: "浙江嘉善联合村镇银行",
  },
  {
    bankCardCodes: "62109207",
    bankNames: "浙江乐清联合村镇银行",
  },
  {
    bankCardCodes: "621092003",
    bankNames: "浙江乐清联合村镇银行",
  },
  {
    bankCardCodes: "62109209",
    bankNames: "江苏邗江联合村镇银行",
  },
  {
    bankCardCodes: "621092007",
    bankNames: "江苏邗江联合村镇银行",
  },
  {
    bankCardCodes: "621275331",
    bankNames: "乌鲁木齐米东浦发村镇银行",
  },
  {
    bankCardCodes: "621275311",
    bankNames: "甘肃榆中浦发村镇银行",
  },
  {
    bankCardCodes: "621275211",
    bankNames: "韩城浦发村镇银行股份有限公司",
  },
  {
    bankCardCodes: "621275301",
    bankNames: "富民浦发村镇银行",
  },
  {
    bankCardCodes: "621275151",
    bankNames: "重庆巴南浦发村镇银行",
  },
  {
    bankCardCodes: "621275101",
    bankNames: "绵竹浦发村镇银行",
  },
  {
    bankCardCodes: "621275261",
    bankNames: "临武浦发村镇银行",
  },
  {
    bankCardCodes: "621275141",
    bankNames: "资兴浦发村镇银行",
  },
  {
    bankCardCodes: "621275231",
    bankNames: "湖南沅江浦发村镇银行",
  },
  {
    bankCardCodes: "621275271",
    bankNames: "湖南衡南浦发村镇银行",
  },
  {
    bankCardCodes: "621275241",
    bankNames: "湖南茶陵浦发村镇银行",
  },
  {
    bankCardCodes: "621275131",
    bankNames: "河南巩义浦发村镇银行",
  },
  {
    bankCardCodes: "621275161",
    bankNames: "邹平浦发村镇银行",
  },
  {
    bankCardCodes: "621275251",
    bankNames: "江西临川浦发村镇银行",
  },
  {
    bankCardCodes: "621275201",
    bankNames: "浙江新昌浦发村镇银行",
  },
  {
    bankCardCodes: "621275221",
    bankNames: "浙江平阳浦发村镇银行",
  },
  {
    bankCardCodes: "621275321",
    bankNames: "宁波海曙浦发村镇银行",
  },
  {
    bankCardCodes: "621275121",
    bankNames: "溧阳浦发村镇银行",
  },
  {
    bankCardCodes: "621275191",
    bankNames: "江苏江阴浦发村镇银行",
  },
  {
    bankCardCodes: "621275111",
    bankNames: "奉贤浦发村镇银行",
  },
  {
    bankCardCodes: "621275281",
    bankNames: "哈尔滨呼兰浦发村镇银行股份有限公司",
  },
  {
    bankCardCodes: "621275291",
    bankNames: "公主岭浦发村镇银行股份有限公司",
  },
  {
    bankCardCodes: "621275181",
    bankNames: "大连甘井子浦发村镇银行",
  },
  {
    bankCardCodes: "621275171",
    bankNames: "泽州浦发村镇银行",
  },
  {
    bankCardCodes: "621275341",
    bankNames: "天津宝坻浦发村镇银行",
  },
  {
    bankCardCodes: "621260001",
    bankNames: "咸丰常农商村镇银行",
  },
  {
    bankCardCodes: "621260002",
    bankNames: "恩施常农商村镇银行",
  },
  {
    bankCardCodes: "621260103",
    bankNames: "金坛常农商村镇银行",
  },
  {
    bankCardCodes: "621053",
    bankNames: "福建建瓯瑞狮村镇银行",
  },
  {
    bankCardCodes: "62105902",
    bankNames: "宣汉诚民村镇银行",
  },
  {
    bankCardCodes: "62105900",
    bankNames: "宣汉诚民村镇银行",
  },
  {
    bankCardCodes: "62105911",
    bankNames: "双流诚民村镇银行",
  },
  {
    bankCardCodes: "62105901",
    bankNames: "双流诚民村镇银行",
  },
  {
    bankCardCodes: "62105905",
    bankNames: "海口苏南村镇银行",
  },
  {
    bankCardCodes: "62105915",
    bankNames: "海口苏南村镇银行",
  },
  {
    bankCardCodes: "62105906",
    bankNames: "兴化苏南村镇银行",
  },
  {
    bankCardCodes: "62105916",
    bankNames: "兴化苏南村镇银行",
  },
  {
    bankCardCodes: "62105903",
    bankNames: "句容苏南村镇银行",
  },
  {
    bankCardCodes: "62105913",
    bankNames: "句容苏南村镇银行",
  },
  {
    bankCardCodes: "622975681",
    bankNames: "湖北仙桃京都村镇银行",
  },
  {
    bankCardCodes: "6229756115",
    bankNames: "湖北仙桃京都村镇银行",
  },
  {
    bankCardCodes: "622975682",
    bankNames: "青岛即墨京都村镇银行",
  },
  {
    bankCardCodes: "6229756114",
    bankNames: "青岛即墨京都村镇银行",
  },
  {
    bankCardCodes: "622995",
    bankNames: "湖北嘉鱼吴江村镇银行",
  },
  {
    bankCardCodes: "621056803",
    bankNames: "石河子交银村镇银行",
  },
  {
    bankCardCodes: "621056801",
    bankNames: "大邑交银兴民村镇银行",
  },
  {
    bankCardCodes: "621056805",
    bankNames: "青岛崂山交银村镇银行",
  },
  {
    bankCardCodes: "621056802",
    bankNames: "安吉交银村镇银行",
  },
  {
    bankCardCodes: "628320",
    bankNames: "海口农村商业银行",
  },
  {
    bankCardCodes: "625126",
    bankNames: "海口农村商业银行",
  },
  {
    bankCardCodes: "623510",
    bankNames: "海口联合农村商业银行",
  },
  {
    bankCardCodes: "623576",
    bankNames: "江南农村商业银行",
  },
  {
    bankCardCodes: "623189",
    bankNames: "江南农村商业银行",
  },
  {
    bankCardCodes: "622891",
    bankNames: "江南农村商业银行",
  },
  {
    bankCardCodes: "621363",
    bankNames: "江南农村商业银行",
  },
  {
    bankCardCodes: "623207",
    bankNames: "武汉农村商业银行",
  },
  {
    bankCardCodes: "623033",
    bankNames: "武汉农村商业银行",
  },
  {
    bankCardCodes: "621361",
    bankNames: "武汉农村商业银行",
  },
  {
    bankCardCodes: "621557",
    bankNames: "黑龙江省农村信用社联合社",
  },
  {
    bankCardCodes: "62326502",
    bankNames: "黑龙江省农村信用社联合社",
  },
  {
    bankCardCodes: "621228",
    bankNames: "黑龙江省农村信用社联合社",
  },
  {
    bankCardCodes: "623516",
    bankNames: "黑龙江省农村信用社联合社",
  },
  {
    bankCardCodes: "623109",
    bankNames: "天津滨海农村商业银行",
  },
  {
    bankCardCodes: "621073",
    bankNames: "天津滨海农村商业银行",
  },
  {
    bankCardCodes: "623051",
    bankNames: "山西省农村信用社联合社",
  },
  {
    bankCardCodes: "621580",
    bankNames: "山西省农村信用社联合社",
  },
  {
    bankCardCodes: "621280",
    bankNames: "山西省农村信用社",
  },
  {
    bankCardCodes: "622968",
    bankNames: "山西省农村信用社联合社",
  },
  {
    bankCardCodes: "628399",
    bankNames: "辽宁省农村信用社联合社",
  },
  {
    bankCardCodes: "621026",
    bankNames: "辽宁省农村信用社联合社",
  },
  {
    bankCardCodes: "621449",
    bankNames: "辽宁省农村信用社联合社",
  },
  {
    bankCardCodes: "628332",
    bankNames: "甘肃省农村信用社联合社",
  },
  {
    bankCardCodes: "623065",
    bankNames: "甘肃省农村信用社联合社",
  },
  {
    bankCardCodes: "621520",
    bankNames: "甘肃省农村信用社联合社",
  },
  {
    bankCardCodes: "621061",
    bankNames: "甘肃省农村信用社联合社",
  },
  {
    bankCardCodes: "625097",
    bankNames: "四川省农村信用社联合社",
  },
  {
    bankCardCodes: "628297",
    bankNames: "四川省农村信用社联合社",
  },
  {
    bankCardCodes: "623256",
    bankNames: "四川省农村信用社联合社",
  },
  {
    bankCardCodes: "623201",
    bankNames: "四川省农村信用社联合社",
  },
  {
    bankCardCodes: "621530",
    bankNames: "四川省农村信用社联合社",
  },
  {
    bankCardCodes: "621459",
    bankNames: "四川省农村信用社联合社",
  },
  {
    bankCardCodes: "621457",
    bankNames: "四川省农村信用社联合社",
  },
  {
    bankCardCodes: "621099",
    bankNames: "四川省农村信用社联合社",
  },
  {
    bankCardCodes: "621033",
    bankNames: "四川省农村信用社联合社",
  },
  {
    bankCardCodes: "622976",
    bankNames: "内蒙古自治区农村信用联合社",
  },
  {
    bankCardCodes: "621737",
    bankNames: "内蒙古自治区农村信用联合社",
  },
  {
    bankCardCodes: "621533",
    bankNames: "内蒙古自治区农村信用联合社",
  },
  {
    bankCardCodes: "621362",
    bankNames: "内蒙古自治区农村信用联合社",
  },
  {
    bankCardCodes: "622470",
    bankNames: "广东省农村信用社联合社",
  },
  {
    bankCardCodes: "621728",
    bankNames: "广东省农村信用社联合社",
  },
  {
    bankCardCodes: "621518",
    bankNames: "广东省农村信用社联合社",
  },
  {
    bankCardCodes: "621018",
    bankNames: "广东省农村信用社联合社",
  },
  {
    bankCardCodes: "622362",
    bankNames: "广东省农村信用社联合社",
  },
  {
    bankCardCodes: "622302",
    bankNames: "广东省农村信用社联合社",
  },
  {
    bankCardCodes: "622510",
    bankNames: "广东省农村信用社联合社",
  },
  {
    bankCardCodes: "622509",
    bankNames: "广东省农村信用社联合社",
  },
  {
    bankCardCodes: "622477",
    bankNames: "广东省农村信用社联合社",
  },
  {
    bankCardCodes: "628289",
    bankNames: "青海省农村信用社联合社",
  },
  {
    bankCardCodes: "623213",
    bankNames: "青海省农村信用社联合社",
  },
  {
    bankCardCodes: "623017",
    bankNames: "青海省农村信用社联合社",
  },
  {
    bankCardCodes: "621065",
    bankNames: "青海省农村信用社联合社",
  },
  {
    bankCardCodes: "621517",
    bankNames: "青海省农村信用社联合社",
  },
  {
    bankCardCodes: "621458",
    bankNames: "海南省农村信用社联合社",
  },
  {
    bankCardCodes: "621036",
    bankNames: "海南省农村信用社联合社",
  },
  {
    bankCardCodes: "621536",
    bankNames: "海南省农村信用社联合社",
  },
  {
    bankCardCodes: "628387",
    bankNames: "安徽省农村信用社联合社",
  },
  {
    bankCardCodes: "622953",
    bankNames: "安徽省农村信用社联合社",
  },
  {
    bankCardCodes: "621526",
    bankNames: "安徽省农村信用社联合社",
  },
  {
    bankCardCodes: "628326",
    bankNames: "宁夏黄河农村商业银行",
  },
  {
    bankCardCodes: "625150",
    bankNames: "宁夏黄河农村商业银行",
  },
  {
    bankCardCodes: "623095",
    bankNames: "黄河农村商业银行",
  },
  {
    bankCardCodes: "621561",
    bankNames: "黄河农村商业银行",
  },
  {
    bankCardCodes: "622947",
    bankNames: "黄河农村商业银行",
  },
  {
    bankCardCodes: "623618",
    bankNames: "吉林省农村信用联合社",
  },
  {
    bankCardCodes: "623181",
    bankNames: "吉林省农村信用社联合社",
  },
  {
    bankCardCodes: "621531",
    bankNames: "吉林农信联合社",
  },
  {
    bankCardCodes: "622935",
    bankNames: "吉林农信联合社",
  },
  {
    bankCardCodes: "628277",
    bankNames: "新疆维吾尔自治区农村信用社联合社",
  },
  {
    bankCardCodes: "621287",
    bankNames: "新疆维吾尔自治区农村信用社联合",
  },
  {
    bankCardCodes: "621525",
    bankNames: "新疆农村信用社联合社",
  },
  {
    bankCardCodes: "621008",
    bankNames: "新疆维吾尔自治区农村信用社联合",
  },
  {
    bankCardCodes: "625121",
    bankNames: "广西壮族自治区农村信用社联合社",
  },
  {
    bankCardCodes: "628330",
    bankNames: "广西壮族自治区农村信用社联合社",
  },
  {
    bankCardCodes: "623133",
    bankNames: "广西农村信用社联合社",
  },
  {
    bankCardCodes: "622992",
    bankNames: "广西农村信用社联合社",
  },
  {
    bankCardCodes: "628323",
    bankNames: "陕西省农村信用社联合社",
  },
  {
    bankCardCodes: "623028",
    bankNames: "陕西省农村信用社联合社",
  },
  {
    bankCardCodes: "623027",
    bankNames: "陕西省农村信用社联合社",
  },
  {
    bankCardCodes: "622506",
    bankNames: "陕西省农村信用社联合社",
  },
  {
    bankCardCodes: "621566",
    bankNames: "陕西省农村信用社联合社",
  },
  {
    bankCardCodes: "623501",
    bankNames: "河北省农村信用社联合社",
  },
  {
    bankCardCodes: "621021",
    bankNames: "河北省农村信用社联合社",
  },
  {
    bankCardCodes: "623025",
    bankNames: "河北省农村信用社联合社",
  },
  {
    bankCardCodes: "622358",
    bankNames: "河北省农村信用社联合社",
  },
  {
    bankCardCodes: "623059",
    bankNames: "河南省农村信用社联合社",
  },
  {
    bankCardCodes: "623013",
    bankNames: "河南省农村信用社联合社",
  },
  {
    bankCardCodes: "621585",
    bankNames: "河南省农村信用社联合社",
  },
  {
    bankCardCodes: "622991",
    bankNames: "河南省农村信用社联合社",
  },
  {
    bankCardCodes: "621592",
    bankNames: "江西农信联合社",
  },
  {
    bankCardCodes: "622683",
    bankNames: "江西农信联合社",
  },
  {
    bankCardCodes: "622682",
    bankNames: "江西农信联合社",
  },
  {
    bankCardCodes: "622681",
    bankNames: "江西农信联合社",
  },
  {
    bankCardCodes: "622169",
    bankNames: "湖南省农村信用社联合社",
  },
  {
    bankCardCodes: "621539",
    bankNames: "湖南省农村信用社联合社",
  },
  {
    bankCardCodes: "621519",
    bankNames: "湖南省农村信用社联合社",
  },
  {
    bankCardCodes: "623090",
    bankNames: "湖南省农村信用社联合社",
  },
  {
    bankCardCodes: "623125",
    bankNames: "无锡农村商业银行",
  },
  {
    bankCardCodes: "622895",
    bankNames: "无锡农村商业银行",
  },
  {
    bankCardCodes: "622893",
    bankNames: "贵州省农村信用社联合社",
  },
  {
    bankCardCodes: "621590",
    bankNames: "贵州省农村信用社联合社",
  },
  {
    bankCardCodes: "621779",
    bankNames: "贵州省农村信用社联合社",
  },
  {
    bankCardCodes: "622882",
    bankNames: "尧都区农村信用合作社联社",
  },
  {
    bankCardCodes: "623075",
    bankNames: "太仓农村商业银行",
  },
  {
    bankCardCodes: "622869",
    bankNames: "太仓农村商业银行",
  },
  {
    bankCardCodes: "622859",
    bankNames: "珠海农村商业银行",
  },
  {
    bankCardCodes: "623521",
    bankNames: "苏州银行股份有限公司",
  },
  {
    bankCardCodes: "621461",
    bankNames: "苏州银行股份有限公司",
  },
  {
    bankCardCodes: "622855",
    bankNames: "苏州银行股份有限公司",
  },
  {
    bankCardCodes: "622686",
    bankNames: "浙江省农村信用社联合社",
  },
  {
    bankCardCodes: "623540",
    bankNames: "浙江省农村信用社联合社",
  },
  {
    bankCardCodes: "621736",
    bankNames: "浙江省农村信用社联合社",
  },
  {
    bankCardCodes: "621537",
    bankNames: "浙江省农村信用社联合社",
  },
  {
    bankCardCodes: "628280",
    bankNames: "浙江省农村信用社联合社",
  },
  {
    bankCardCodes: "622288",
    bankNames: "浙江省农村信用社联合社",
  },
  {
    bankCardCodes: "623091",
    bankNames: "浙江省农村信用社联合社",
  },
  {
    bankCardCodes: "621527",
    bankNames: "浙江省农村信用社联合社",
  },
  {
    bankCardCodes: "621058",
    bankNames: "浙江省农村信用社联合社",
  },
  {
    bankCardCodes: "622858",
    bankNames: "浙江省农村信用社联合社",
  },
  {
    bankCardCodes: "623110",
    bankNames: "吴江农商行",
  },
  {
    bankCardCodes: "622488",
    bankNames: "吴江农商行",
  },
  {
    bankCardCodes: "628248",
    bankNames: "吴江农商行",
  },
  {
    bankCardCodes: "622648",
    bankNames: "吴江农商行",
  },
  {
    bankCardCodes: "623267",
    bankNames: "江苏省农村信用社联合社",
  },
  {
    bankCardCodes: "623066",
    bankNames: "江苏省农村信用社联合社",
  },
  {
    bankCardCodes: "622324",
    bankNames: "江苏农信社",
  },
  {
    bankCardCodes: "621578",
    bankNames: "江苏省农村信用社联合社",
  },
  {
    bankCardCodes: "622452",
    bankNames: "江苏农信社",
  },
  {
    bankCardCodes: "622345",
    bankNames: "成都农村商业银行股份有限公司",
  },
  {
    bankCardCodes: "621516",
    bankNames: "成都农村商业银行",
  },
  {
    bankCardCodes: "625516",
    bankNames: "成都农村商业银行",
  },
  {
    bankCardCodes: "622343",
    bankNames: "佛山市三水区农村信用合作社",
  },
  {
    bankCardCodes: "628300",
    bankNames: "宁波鄞州农村合作银行",
  },
  {
    bankCardCodes: "622389",
    bankNames: "宁波鄞州农村合作银行",
  },
  {
    bankCardCodes: "622289",
    bankNames: "宁波鄞州农村合作银行",
  },
  {
    bankCardCodes: "621024",
    bankNames: "宁波鄞州农村合作银行",
  },
  {
    bankCardCodes: "620500",
    bankNames: "宁波鄞州农村合作银行",
  },
  {
    bankCardCodes: "623539",
    bankNames: "鄞州农村合作银行",
  },
  {
    bankCardCodes: "622339",
    bankNames: "鄞州农村合作银行",
  },
  {
    bankCardCodes: "623103",
    bankNames: "天津农村商业银行",
  },
  {
    bankCardCodes: "622329",
    bankNames: "天津农村商业银行",
  },
  {
    bankCardCodes: "622531",
    bankNames: "天津农村商业银行",
  },
  {
    bankCardCodes: "625526",
    bankNames: "北京农商行",
  },
  {
    bankCardCodes: "628336",
    bankNames: "北京农商行",
  },
  {
    bankCardCodes: "625186",
    bankNames: "北京农商行",
  },
  {
    bankCardCodes: "62326520",
    bankNames: "北京农村商业银行",
  },
  {
    bankCardCodes: "621067",
    bankNames: "北京农村商业银行",
  },
  {
    bankCardCodes: "620088",
    bankNames: "北京农村商业银行",
  },
  {
    bankCardCodes: "621068",
    bankNames: "北京农村商业银行",
  },
  {
    bankCardCodes: "621560",
    bankNames: "北京农村商业银行",
  },
  {
    bankCardCodes: "621066",
    bankNames: "北京农村商业银行",
  },
  {
    bankCardCodes: "622138",
    bankNames: "北京农村商业银行",
  },
  {
    bankCardCodes: "622127",
    bankNames: "福建省农村信用社联合社",
  },
  {
    bankCardCodes: "621701",
    bankNames: "福建省农村信用社联合社",
  },
  {
    bankCardCodes: "623036",
    bankNames: "福建省农村信用社联合社",
  },
  {
    bankCardCodes: "621589",
    bankNames: "福建省农村信用社联合社",
  },
  {
    bankCardCodes: "621251",
    bankNames: "福建省农村信用社联合社",
  },
  {
    bankCardCodes: "622184",
    bankNames: "福建省农村信用社联合社",
  },
  {
    bankCardCodes: "623123",
    bankNames: "张家港农村商业银行",
  },
  {
    bankCardCodes: "940063",
    bankNames: "张家港农村商业银行",
  },
  {
    bankCardCodes: "622332",
    bankNames: "张家港农村商业银行",
  },
  {
    bankCardCodes: "625888",
    bankNames: "东莞农村商业银行",
  },
  {
    bankCardCodes: "623038",
    bankNames: "东莞农村商业银行",
  },
  {
    bankCardCodes: "625288",
    bankNames: "东莞农村商业银行",
  },
  {
    bankCardCodes: "940062",
    bankNames: "东莞农村商业银行",
  },
  {
    bankCardCodes: "622328",
    bankNames: "东莞农村商业银行",
  },
  {
    bankCardCodes: "90020502",
    bankNames: "青岛农信",
  },
  {
    bankCardCodes: "90010502",
    bankNames: "青岛农信",
  },
  {
    bankCardCodes: "62231902",
    bankNames: "青岛农信",
  },
  {
    bankCardCodes: "628375",
    bankNames: "山东省农村信用社联合社",
  },
  {
    bankCardCodes: "622320",
    bankNames: "山东省农村信用社联合社",
  },
  {
    bankCardCodes: "621690",
    bankNames: "山东省农村信用社联合社",
  },
  {
    bankCardCodes: "621521",
    bankNames: "山东省农村信用社联合社",
  },
  {
    bankCardCodes: "622319",
    bankNames: "山东农村信用联合社",
  },
  {
    bankCardCodes: "900205",
    bankNames: "山东农村信用联合社",
  },
  {
    bankCardCodes: "900105",
    bankNames: "山东农村信用联合社",
  },
  {
    bankCardCodes: "621258",
    bankNames: "重庆农村商业银行",
  },
  {
    bankCardCodes: "621528",
    bankNames: "重庆农村商业银行",
  },
  {
    bankCardCodes: "621465",
    bankNames: "重庆农村商业银行",
  },
  {
    bankCardCodes: "940020",
    bankNames: "重庆农村商业银行股份有限公司",
  },
  {
    bankCardCodes: "622885",
    bankNames: "重庆农村商业银行股份有限公司",
  },
  {
    bankCardCodes: "622867",
    bankNames: "重庆农村商业银行股份有限公司",
  },
  {
    bankCardCodes: "623115",
    bankNames: "江阴农村商业银行",
  },
  {
    bankCardCodes: "940058",
    bankNames: "江阴农村商业银行",
  },
  {
    bankCardCodes: "622341",
    bankNames: "江阴农村商业银行",
  },
  {
    bankCardCodes: "622481",
    bankNames: "江阴市农村商业银行",
  },
  {
    bankCardCodes: "628381",
    bankNames: "江阴农村商业银行",
  },
  {
    bankCardCodes: "622312",
    bankNames: "徐州市郊农村信用合作联社",
  },
  {
    bankCardCodes: "940044",
    bankNames: "武汉农信",
  },
  {
    bankCardCodes: "623276",
    bankNames: "湖北农信社",
  },
  {
    bankCardCodes: "628254",
    bankNames: "湖北农信社",
  },
  {
    bankCardCodes: "625889",
    bankNames: "湖北农信社",
  },
  {
    bankCardCodes: "621013",
    bankNames: "湖北农信社",
  },
  {
    bankCardCodes: "623055",
    bankNames: "湖北农信社",
  },
  {
    bankCardCodes: "621523",
    bankNames: "湖北农信",
  },
  {
    bankCardCodes: "622412",
    bankNames: "湖北农信社",
  },
  {
    bankCardCodes: "623190",
    bankNames: "昆明农联社",
  },
  {
    bankCardCodes: "940042",
    bankNames: "昆明农联社",
  },
  {
    bankCardCodes: "622369",
    bankNames: "昆明农联社",
  },
  {
    bankCardCodes: "18572",
    bankNames: "昆明农联社",
  },
  {
    bankCardCodes: "621017",
    bankNames: "昆明农联社",
  },
  {
    bankCardCodes: "622322",
    bankNames: "广东顺德农村商业银行",
  },
  {
    bankCardCodes: "985262",
    bankNames: "广东顺德农村商业银行",
  },
  {
    bankCardCodes: "940038",
    bankNames: "广东顺德农村商业银行",
  },
  {
    bankCardCodes: "940037",
    bankNames: "广东南海农村商业银行",
  },
  {
    bankCardCodes: "622271",
    bankNames: "广东南海农村商业银行",
  },
  {
    bankCardCodes: "623257",
    bankNames: "广州农村商业银行股份有限公司",
  },
  {
    bankCardCodes: "622439",
    bankNames: "广州农村商业银行股份有限公司",
  },
  {
    bankCardCodes: "621522",
    bankNames: "广州农村商业银行",
  },
  {
    bankCardCodes: "940035",
    bankNames: "广州农村商业银行股份有限公司",
  },
  {
    bankCardCodes: "909810",
    bankNames: "广州农村商业银行股份有限公司",
  },
  {
    bankCardCodes: "623186",
    bankNames: "深圳农村商业银行",
  },
  {
    bankCardCodes: "623035",
    bankNames: "深圳农村商业银行",
  },
  {
    bankCardCodes: "622129",
    bankNames: "深圳农村商业银行",
  },
  {
    bankCardCodes: "622128",
    bankNames: "深圳农村商业银行",
  },
  {
    bankCardCodes: "9400301",
    bankNames: "常熟农村商业银行",
  },
  {
    bankCardCodes: "622323",
    bankNames: "常熟农村商业银行",
  },
  {
    bankCardCodes: "603694",
    bankNames: "常熟农村商业银行",
  },
  {
    bankCardCodes: "623071",
    bankNames: "常熟农村商业银行",
  },
  {
    bankCardCodes: "628272",
    bankNames: "常熟市农村商业银行",
  },
  {
    bankCardCodes: "622462",
    bankNames: "常熟市农村商业银行",
  },
  {
    bankCardCodes: "625101",
    bankNames: "常熟市农村商业银行",
  },
  {
    bankCardCodes: "940029",
    bankNames: "昆山农信社",
  },
  {
    bankCardCodes: "623132",
    bankNames: "昆山农信社",
  },
  {
    bankCardCodes: "622443",
    bankNames: "昆山农信社",
  },
  {
    bankCardCodes: "62326508",
    bankNames: "上海农商银行",
  },
  {
    bankCardCodes: "623552",
    bankNames: "上海农商银行",
  },
  {
    bankCardCodes: "623162",
    bankNames: "上海农商银行",
  },
  {
    bankCardCodes: "621688",
    bankNames: "上海农商银行",
  },
  {
    bankCardCodes: "621495",
    bankNames: "上海农商银行",
  },
  {
    bankCardCodes: "940013",
    bankNames: "上海农商银行",
  },
  {
    bankCardCodes: "622478",
    bankNames: "上海农商银行",
  },
  {
    bankCardCodes: "627068",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "627067",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "627066",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "627069",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "623621",
    bankNames: "海南银行",
  },
  {
    bankCardCodes: "623623",
    bankNames: "厦门国际银行",
  },
  {
    bankCardCodes: "623505",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "623160",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "623037",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "622979",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "622441",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "621753",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "621075",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "621235",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "621748",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "621337",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "621327",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "621271",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "6224217",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "621601",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "623660",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "621221",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "621035",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "623138",
    bankNames: "中原银行",
  },
  {
    bankCardCodes: "622508",
    bankNames: "贵州银行",
  },
  {
    bankCardCodes: "622961",
    bankNames: "贵州银行",
  },
  {
    bankCardCodes: "621591",
    bankNames: "贵州银行",
  },
  {
    bankCardCodes: "622939",
    bankNames: "贵州银行",
  },
  {
    bankCardCodes: "621460",
    bankNames: "贵州银行",
  },
  {
    bankCardCodes: "623577",
    bankNames: "本溪市商业银行",
  },
  {
    bankCardCodes: "621696",
    bankNames: "本溪市商业银行",
  },
  {
    bankCardCodes: "623087",
    bankNames: "枣庄银行",
  },
  {
    bankCardCodes: "628356",
    bankNames: "甘肃银行",
  },
  {
    bankCardCodes: "623182",
    bankNames: "甘肃银行",
  },
  {
    bankCardCodes: "621636",
    bankNames: "甘肃银行",
  },
  {
    bankCardCodes: "623537",
    bankNames: "宁波通商银行",
  },
  {
    bankCardCodes: "621655",
    bankNames: "宁波通商银行",
  },
  {
    bankCardCodes: "623627",
    bankNames: "广东华兴银行",
  },
  {
    bankCardCodes: "623688",
    bankNames: "广东华兴银行",
  },
  {
    bankCardCodes: "623665",
    bankNames: "广东华兴银行",
  },
  {
    bankCardCodes: "623611",
    bankNames: "广东华兴银行",
  },
  {
    bankCardCodes: "623113",
    bankNames: "广东华兴银行",
  },
  {
    bankCardCodes: "621625",
    bankNames: "广东华兴银行",
  },
  {
    bankCardCodes: "621469",
    bankNames: "广东华兴银行",
  },
  {
    bankCardCodes: "621339",
    bankNames: "新疆汇和银行",
  },
  {
    bankCardCodes: "621391",
    bankNames: "西藏银行",
  },
  {
    bankCardCodes: "628340",
    bankNames: "湖北银行",
  },
  {
    bankCardCodes: "625850",
    bankNames: "湖北银行",
  },
  {
    bankCardCodes: "623076",
    bankNames: "湖北银行",
  },
  {
    bankCardCodes: "621270",
    bankNames: "湖北银行",
  },
  {
    bankCardCodes: "622511",
    bankNames: "湖北银行",
  },
  {
    bankCardCodes: "622396",
    bankNames: "湖北银行",
  },
  {
    bankCardCodes: "622959",
    bankNames: "湖北银行",
  },
  {
    bankCardCodes: "621217",
    bankNames: "湖北银行",
  },
  {
    bankCardCodes: "623566",
    bankNames: "哈密市商业银行",
  },
  {
    bankCardCodes: "621360",
    bankNames: "哈密市商业银行",
  },
  {
    bankCardCodes: "623080",
    bankNames: "景德镇商业银行",
  },
  {
    bankCardCodes: "620063",
    bankNames: "营口沿海银行",
  },
  {
    bankCardCodes: "621348",
    bankNames: "营口沿海银行",
  },
  {
    bankCardCodes: "621388",
    bankNames: "华融湘江银行",
  },
  {
    bankCardCodes: "621366",
    bankNames: "华融湘江银行",
  },
  {
    bankCardCodes: "628331",
    bankNames: "信阳银行",
  },
  {
    bankCardCodes: "623084",
    bankNames: "雅安市商业银行",
  },
  {
    bankCardCodes: "621325",
    bankNames: "雅安市商业银行",
  },
  {
    bankCardCodes: "621263",
    bankNames: "库尔勒市商业银行",
  },
  {
    bankCardCodes: "623079",
    bankNames: "宜宾市商业银行",
  },
  {
    bankCardCodes: "621273",
    bankNames: "宜宾市商业银行",
  },
  {
    bankCardCodes: "621738",
    bankNames: "阳泉市商业银行",
  },
  {
    bankCardCodes: "621272",
    bankNames: "阳泉市商业银行",
  },
  {
    bankCardCodes: "628315",
    bankNames: "周口银行",
  },
  {
    bankCardCodes: "628380",
    bankNames: "衡水银行",
  },
  {
    bankCardCodes: "623068",
    bankNames: "衡水银行",
  },
  {
    bankCardCodes: "621239",
    bankNames: "衡水银行",
  },
  {
    bankCardCodes: "628389",
    bankNames: "驻马店银行",
  },
  {
    bankCardCodes: "621780",
    bankNames: "晋中银行",
  },
  {
    bankCardCodes: "621223",
    bankNames: "晋中银行",
  },
  {
    bankCardCodes: "623588",
    bankNames: "达州市商业银行",
  },
  {
    bankCardCodes: "621233",
    bankNames: "达州市商业银行",
  },
  {
    bankCardCodes: "628303",
    bankNames: "漯河银行",
  },
  {
    bankCardCodes: "623199",
    bankNames: "凉山州商业银行",
  },
  {
    bankCardCodes: "621057",
    bankNames: "凉山州商业银行",
  },
  {
    bankCardCodes: "628325",
    bankNames: "邢台银行",
  },
  {
    bankCardCodes: "621238",
    bankNames: "邢台银行股份有限公司",
  },
  {
    bankCardCodes: "623168",
    bankNames: "保定银行",
  },
  {
    bankCardCodes: "621091",
    bankNames: "保定银行",
  },
  {
    bankCardCodes: "623178",
    bankNames: "遂宁市商业银行",
  },
  {
    bankCardCodes: "621090",
    bankNames: "遂宁市商业银行",
  },
  {
    bankCardCodes: "623167",
    bankNames: "宁波东海银行",
  },
  {
    bankCardCodes: "621345",
    bankNames: "宁波东海银行",
  },
  {
    bankCardCodes: "623030",
    bankNames: "朝阳银行",
  },
  {
    bankCardCodes: "621515",
    bankNames: "朝阳银行",
  },
  {
    bankCardCodes: "621074",
    bankNames: "朝阳银行",
  },
  {
    bankCardCodes: "628339",
    bankNames: "平顶山银行",
  },
  {
    bankCardCodes: "623152",
    bankNames: "平顶山银行股份有限公司",
  },
  {
    bankCardCodes: "621071",
    bankNames: "平顶山银行股份有限公司",
  },
  {
    bankCardCodes: "623139",
    bankNames: "昆仑银行股份有限公司",
  },
  {
    bankCardCodes: "621766",
    bankNames: "昆仑银行股份有限公司",
  },
  {
    bankCardCodes: "621029",
    bankNames: "昆仑银行股份有限公司",
  },
  {
    bankCardCodes: "623161",
    bankNames: "盘锦市商业银行",
  },
  {
    bankCardCodes: "621089",
    bankNames: "盘锦市商业银行",
  },
  {
    bankCardCodes: "628355",
    bankNames: "石嘴山银行",
  },
  {
    bankCardCodes: "623120",
    bankNames: "石嘴山银行",
  },
  {
    bankCardCodes: "622940",
    bankNames: "石嘴山银行",
  },
  {
    bankCardCodes: "621755",
    bankNames: "重庆三峡银行",
  },
  {
    bankCardCodes: "622945",
    bankNames: "重庆三峡银行",
  },
  {
    bankCardCodes: "621044",
    bankNames: "宝鸡商行",
  },
  {
    bankCardCodes: "628385",
    bankNames: "长安银行",
  },
  {
    bankCardCodes: "621296",
    bankNames: "长安银行",
  },
  {
    bankCardCodes: "621448",
    bankNames: "长安银行",
  },
  {
    bankCardCodes: "621498",
    bankNames: "乐山市商业银行",
  },
  {
    bankCardCodes: "621086",
    bankNames: "乐山市商业银行",
  },
  {
    bankCardCodes: "623180",
    bankNames: "铁岭银行",
  },
  {
    bankCardCodes: "621038",
    bankNames: "铁岭银行",
  },
  {
    bankCardCodes: "623116",
    bankNames: "济宁银行股份有限公司",
  },
  {
    bankCardCodes: "621200",
    bankNames: "济宁银行股份有限公司",
  },
  {
    bankCardCodes: "628378",
    bankNames: "鄂尔多斯银行股份有限公司",
  },
  {
    bankCardCodes: "623093",
    bankNames: "鄂尔多斯银行股份有限公司",
  },
  {
    bankCardCodes: "628253",
    bankNames: "鄂尔多斯银行",
  },
  {
    bankCardCodes: "622978",
    bankNames: "鄂尔多斯银行股份有限公司",
  },
  {
    bankCardCodes: "622977",
    bankNames: "龙江银行股份有限公司",
  },
  {
    bankCardCodes: "621070",
    bankNames: "自贡市商业银行",
  },
  {
    bankCardCodes: "623121",
    bankNames: "自贡市商业银行",
  },
  {
    bankCardCodes: "623153",
    bankNames: "乌海银行股份有限公司",
  },
  {
    bankCardCodes: "622973",
    bankNames: "乌海银行股份有限公司",
  },
  {
    bankCardCodes: "628250",
    bankNames: "浙江稠州商业银行",
  },
  {
    bankCardCodes: "621028",
    bankNames: "浙江稠州商业银行",
  },
  {
    bankCardCodes: "623083",
    bankNames: "浙江稠州商业银行",
  },
  {
    bankCardCodes: "623196",
    bankNames: "泰安银行",
  },
  {
    bankCardCodes: "622972",
    bankNames: "泰安银行",
  },
  {
    bankCardCodes: "623130",
    bankNames: "东营银行",
  },
  {
    bankCardCodes: "621004",
    bankNames: "东营银行",
  },
  {
    bankCardCodes: "621014",
    bankNames: "上饶市商业银行",
  },
  {
    bankCardCodes: "625036",
    bankNames: "浙江民泰商业银行",
  },
  {
    bankCardCodes: "622740",
    bankNames: "浙江民泰商业银行",
  },
  {
    bankCardCodes: "620517",
    bankNames: "浙江民泰商业银行",
  },
  {
    bankCardCodes: "621088",
    bankNames: "浙江民泰商业银行",
  },
  {
    bankCardCodes: "621726",
    bankNames: "浙江民泰商业银行",
  },
  {
    bankCardCodes: "628351",
    bankNames: "玉溪市商业银行",
  },
  {
    bankCardCodes: "623135",
    bankNames: "玉溪市商业银行",
  },
  {
    bankCardCodes: "622980",
    bankNames: "玉溪市商业银行",
  },
  {
    bankCardCodes: "621010",
    bankNames: "平凉市商业银行",
  },
  {
    bankCardCodes: "625902",
    bankNames: "江苏银行",
  },
  {
    bankCardCodes: "622283",
    bankNames: "江苏银行",
  },
  {
    bankCardCodes: "628210",
    bankNames: "江苏银行",
  },
  {
    bankCardCodes: "623653",
    bankNames: "邯郸市商业银行",
  },
  {
    bankCardCodes: "623523",
    bankNames: "邯郸市商业银行",
  },
  {
    bankCardCodes: "622960",
    bankNames: "邯郸市商业银行",
  },
  {
    bankCardCodes: "623101",
    bankNames: "德州银行",
  },
  {
    bankCardCodes: "622937",
    bankNames: "德州银行",
  },
  {
    bankCardCodes: "62326515",
    bankNames: "承德银行",
  },
  {
    bankCardCodes: "623060",
    bankNames: "承德银行",
  },
  {
    bankCardCodes: "622936",
    bankNames: "承德市商业银行",
  },
  {
    bankCardCodes: "623509",
    bankNames: "长治银行股份有限公司",
  },
  {
    bankCardCodes: "622962",
    bankNames: "长治银行股份有限公司",
  },
  {
    bankCardCodes: "625823",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "625825",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "628296",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "625361",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "625360",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "356868",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "356869",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "526855",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "622156",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "622155",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "528020",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "998802",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "998801",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "622526",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "622525",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "483536",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "435745",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "435744",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "622157",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "531659",
    bankNames: "平安银行股份有限公司",
  },
  {
    bankCardCodes: "623279",
    bankNames: "江苏银行",
  },
  {
    bankCardCodes: "621579",
    bankNames: "江苏银行",
  },
  {
    bankCardCodes: "622873",
    bankNames: "江苏银行",
  },
  {
    bankCardCodes: "622876",
    bankNames: "江苏银行",
  },
  {
    bankCardCodes: "940076",
    bankNames: "江苏银行",
  },
  {
    bankCardCodes: "622131",
    bankNames: "江苏银行",
  },
  {
    bankCardCodes: "622447",
    bankNames: "江苏银行",
  },
  {
    bankCardCodes: "622422",
    bankNames: "江苏银行",
  },
  {
    bankCardCodes: "622173",
    bankNames: "江苏银行",
  },
  {
    bankCardCodes: "504923",
    bankNames: "江苏银行",
  },
  {
    bankCardCodes: "621076",
    bankNames: "江苏银行",
  },
  {
    bankCardCodes: "622567",
    bankNames: "汉口银行",
  },
  {
    bankCardCodes: "622566",
    bankNames: "汉口银行",
  },
  {
    bankCardCodes: "628200",
    bankNames: "汉口银行",
  },
  {
    bankCardCodes: "625946",
    bankNames: "汉口银行",
  },
  {
    bankCardCodes: "622626",
    bankNames: "汉口银行",
  },
  {
    bankCardCodes: "622625",
    bankNames: "汉口银行",
  },
  {
    bankCardCodes: "625988",
    bankNames: "温州银行",
  },
  {
    bankCardCodes: "628255",
    bankNames: "温州银行",
  },
  {
    bankCardCodes: "622899",
    bankNames: "温州银行",
  },
  {
    bankCardCodes: "622868",
    bankNames: "温州银行",
  },
  {
    bankCardCodes: "628398",
    bankNames: "东莞商行",
  },
  {
    bankCardCodes: "622888",
    bankNames: "东莞商行",
  },
  {
    bankCardCodes: "623197",
    bankNames: "晋城银行股份有限公司",
  },
  {
    bankCardCodes: "622532",
    bankNames: "晋城银行股份有限公司",
  },
  {
    bankCardCodes: "621497",
    bankNames: "曲靖市商业银行",
  },
  {
    bankCardCodes: "622777",
    bankNames: "曲靖市商业银行",
  },
  {
    bankCardCodes: "623193",
    bankNames: "唐山银行",
  },
  {
    bankCardCodes: "622167",
    bankNames: "唐山银行",
  },
  {
    bankCardCodes: "623264",
    bankNames: "德阳银行",
  },
  {
    bankCardCodes: "623137",
    bankNames: "德阳银行",
  },
  {
    bankCardCodes: "622563",
    bankNames: "德阳银行",
  },
  {
    bankCardCodes: "622562",
    bankNames: "德阳银行",
  },
  {
    bankCardCodes: "622561",
    bankNames: "德阳银行",
  },
  {
    bankCardCodes: "621403",
    bankNames: "莱商银行",
  },
  {
    bankCardCodes: "622864",
    bankNames: "莱芜银行",
  },
  {
    bankCardCodes: "628279",
    bankNames: "莱商银行",
  },
  {
    bankCardCodes: "622897",
    bankNames: "南充市商业银行",
  },
  {
    bankCardCodes: "623072",
    bankNames: "南充市商业银行",
  },
  {
    bankCardCodes: "620118",
    bankNames: "柳州银行股份有限公司",
  },
  {
    bankCardCodes: "622881",
    bankNames: "柳州银行股份有限公司",
  },
  {
    bankCardCodes: "622880",
    bankNames: "柳州银行股份有限公司",
  },
  {
    bankCardCodes: "621412",
    bankNames: "柳州银行股份有限公司",
  },
  {
    bankCardCodes: "622291",
    bankNames: "柳州银行股份有限公司",
  },
  {
    bankCardCodes: "622292",
    bankNames: "柳州银行股份有限公司",
  },
  {
    bankCardCodes: "622866",
    bankNames: "徐州市商业银行",
  },
  {
    bankCardCodes: "623150",
    bankNames: "江苏长江商业银行",
  },
  {
    bankCardCodes: "622870",
    bankNames: "江苏长江商业银行",
  },
  {
    bankCardCodes: "621037",
    bankNames: "龙江银行",
  },
  {
    bankCardCodes: "623216",
    bankNames: "龙江银行",
  },
  {
    bankCardCodes: "623518",
    bankNames: "龙江银行",
  },
  {
    bankCardCodes: "62326503",
    bankNames: "龙江银行",
  },
  {
    bankCardCodes: "623032",
    bankNames: "龙江银行",
  },
  {
    bankCardCodes: "622644",
    bankNames: "龙江银行",
  },
  {
    bankCardCodes: "622860",
    bankNames: "龙江银行",
  },
  {
    bankCardCodes: "621588",
    bankNames: "龙江银行",
  },
  {
    bankCardCodes: "621097",
    bankNames: "龙江银行",
  },
  {
    bankCardCodes: "621562",
    bankNames: "桂林银行",
  },
  {
    bankCardCodes: "621456",
    bankNames: "桂林银行",
  },
  {
    bankCardCodes: "623221",
    bankNames: "桂林市商业银行",
  },
  {
    bankCardCodes: "622856",
    bankNames: "桂林市商业银行",
  },
  {
    bankCardCodes: "621413",
    bankNames: "张家口市商业银行",
  },
  {
    bankCardCodes: "622982",
    bankNames: "张家口市商业银行股份有限公司",
  },
  {
    bankCardCodes: "623595",
    bankNames: "广东南粤银行",
  },
  {
    bankCardCodes: "622448",
    bankNames: "广东南粤银行",
  },
  {
    bankCardCodes: "622925",
    bankNames: "大同银行",
  },
  {
    bankCardCodes: "622395",
    bankNames: "大同银行",
  },
  {
    bankCardCodes: "623085",
    bankNames: "泸州市商业银行",
  },
  {
    bankCardCodes: "622392",
    bankNames: "泸州市商业银行",
  },
  {
    bankCardCodes: "622367",
    bankNames: "绵阳市商业银行",
  },
  {
    bankCardCodes: "623048",
    bankNames: "攀枝花市商业银行",
  },
  {
    bankCardCodes: "622342",
    bankNames: "攀枝花市商业银行",
  },
  {
    bankCardCodes: "623102",
    bankNames: "威海商业银行",
  },
  {
    bankCardCodes: "622411",
    bankNames: "威海商业银行",
  },
  {
    bankCardCodes: "622337",
    bankNames: "连云港市商业银行",
  },
  {
    bankCardCodes: "622315",
    bankNames: "包商银行",
  },
  {
    bankCardCodes: "625359",
    bankNames: "包商银行",
  },
  {
    bankCardCodes: "623210",
    bankNames: "包商银行",
  },
  {
    bankCardCodes: "625950",
    bankNames: "包商银行",
  },
  {
    bankCardCodes: "628295",
    bankNames: "包商银行",
  },
  {
    bankCardCodes: "622165",
    bankNames: "包商银行",
  },
  {
    bankCardCodes: "620021",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "620521",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "00405512",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "0049104",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "0053783",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "00601428",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "405512",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "434910",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "458123",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "458124",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "49104",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "520169",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "522964",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "53783",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "552853",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "601428",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "622250",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "622251",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "521899",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "622254",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "622255",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "622256",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "622257",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "622258",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "622259",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "622253",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "622261",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "622284",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "622656",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "628216",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "622252",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "66405512",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "6649104",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "622260",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "66601428",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "955590",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "955591",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "955592",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "955593",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "6653783",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "628218",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "622262",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "621002",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "621069",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "620013",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "625028",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "625029",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "621436",
    bankNames: "交通银行"
  },
  {
    bankCardCodes: "621335",
    bankNames: "交通银行"
  }
];

export default cardTypeMap;