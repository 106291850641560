<template>
  <div class="wrap">
    <head-nav></head-nav>
    <div class="con-wrap">
      <side-bar></side-bar>
      <div class="pages-con-wrap">
        <div class="asset-management-bg">
          <b-container fluid>
            <h2 class="page-title">资产管理</h2>
            <div class="user-asset-info">
              <b-row>
                <b-col class="items-wrap" sm="12" md="6" lg="6" xl="6">
                  <div class="asset-detail">
                    <div class="item">总e币：<span>{{userWallet.balanceTotal}}</span></div>
                    <div class="item">可用e币：<span>{{userWallet.balance}}</span></div>
                    <div class="item">冻结e币：<span>{{userWallet.balanceFreeze}}</span></div>
                  </div>
                </b-col>
                <b-col class="items-wrap" sm="12" md="6" lg="6" xl="6">
                  <div class="withdrawal-wrap">
                    <el-button type="warning" @click="openWithdrawalWindow">提现</el-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="asset-detailed-list">
              <el-tabs type="border-card" v-model="tabType" @tab-click="tabChange">
                <el-tab-pane label="收入记录" name="1">
                  <div class="table-wrap income" v-if="income.length > 0">
                    <table>
                      <thead>
                        <tr>
                          <th>单号</th>
                          <th>用户</th>
                          <th>E币</th>
                          <th>时间</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in income" :key="index">
                          <td>
                            <div class="item">{{item.orderId}}</div>
                          </td>
                          <td>
                            <div class="item">{{item.operationUserName}}</div>
                          </td>
                          <td>
                            <div class="item">{{item.amount}}</div>
                          </td>
                          <td>
                            <div class="item">{{item.createDate}}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="支出记录" name="2">
                  <div class="table-wrap income" v-if="expenditure.length > 0">
                    <table>
                      <thead>
                        <tr>
                          <th>单号</th>
                          <th>用户</th>
                          <th>E币</th>
                          <th>时间</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in expenditure" :key="index">
                          <td>
                            <div class="item">{{item.orderId}}</div>
                          </td>
                          <td>
                            <div class="item">{{item.operationUserName}}</div>
                          </td>
                          <td>
                            <div class="item">{{item.amount}}</div>
                          </td>
                          <td>
                            <div class="item">{{item.createDate}}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="提现记录" name="3">
                  <div class="table-wrap withdrawal" v-if="withdrawal.length > 0">
                    <table>
                      <thead>
                        <tr>
                          <th>申请时间</th>
                          <th>提现(E币)</th>
                          <th>账户名</th>
                          <th>银行名称</th>
                          <th>银行卡号</th>
                          <th>提现状态</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in withdrawal" :key="index">
                          <td>
                            <div class="item">{{item.createDate}}</div>
                          </td>
                          <td>
                            <div class="item">{{item.amount * 10}}</div>
                          </td>
                          <td>
                            <div class="item">{{item.accountName}}</div>
                          </td>
                          <td>
                            <div class="item">{{item.bankName}}</div>
                          </td>
                          <td>
                            <div class="item">{{item.bankAccount}}</div>
                          </td>
                          <td>
                            <div class="item">{{withdrawalStatus(item.status)}}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </b-container>
        </div>
      </div>
    </div>
    <footer-nav></footer-nav>
    <el-dialog
      title="请录入提现信息"
      :visible.sync="withdrawaDialogVisible"
      width="40%"
      center>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="银行卡号" prop="bankAccount">
          <el-input v-model="form.bankAccount" @input="bankAccountChange"></el-input>
        </el-form-item>
        <el-form-item label="银行名称" prop="bankName">
          <el-input v-model="form.bankName"></el-input>
        </el-form-item>
        <el-form-item label="账户名" prop="accountName">
          <el-input v-model="form.accountName"></el-input>
        </el-form-item>
        <div class="tip-wrap">
          <div class="amount">您当前可提现资产：<span>{{userWallet.balance}}</span>E币</div>
          <div class="tip">注：最低提现金额为2000E币，提现金额数量为2000E币的倍数！</div>
        </div>
        <el-form-item label="提现金额" prop="amount">
          <el-input v-model="form.amount"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="withdrawaDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitWithdrawal">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import headNav from "@/components/Navbar";
  import sideBar from "@/components/userCenterSidebar";
  import regExp from "@/util/regExp";
  import cardTypeMap from "@/util/unionPayCardBin";
  import footerNav from "@/components/Footer";
  export default {
    components: {
      headNav,
      sideBar,
      footerNav
    },
    data (){
      return {
        token: null,
        userWallet: [],
        tabType: "1",
        // 收入记录
        income: [],
        // 支出记录
        expenditure: [],
        // 提现记录
        withdrawal: [],
        withdrawaDialogVisible: false,
        form: {
          accountName: "",
          bankName: "",
          bankAccount: "",
          amount: ""
        },
        rules: {
          accountName: [
            { validator: this.checkAccountName, trigger: 'blur' }
          ],
          bankName: [
            { validator: this.checkBankName, trigger: 'blur' }
          ],
          bankAccount: [
            { validator: this.checkBankAccount, trigger: 'blur' }
          ],
          amount: [
            { validator: this.checkAmount, trigger: 'blur' }
          ]
        },
        validatorResult: {
          accountName: false,
          bankName: false,
          bankAccount: false,
          amount: false
        }
      }
    },
    created(){
      this.init();
    },
    computed: {
      // 提现状态 
      withdrawalStatus(){
        return state => {
          // 1 待审核 2审核通过 3已打款 4 驳回',
          let status = "";
          switch(state){
            case 1: status = "待审核";
            break;
            case 2: status = "审核通过";
            break;
            case 3: status = "已打款";
            break;
            case 4: status = "驳回";
            break;
          }
          return status;
        }
      }
    },
    methods: {
      init(){
        this.token = localStorage.getItem("token");
        this.getUserWallet();
        this.getRecorder();
      },
      // 查询用户e币 
      getUserWallet(){
        this.$api.getUserWallet({
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.userWallet = res.data;
          }
        }).catch(err => console.error(err))
      },
      // 切换tab
      tabChange(){
        this.getRecorder();
      },
      // 获取用户E币 收入 支出 提现记录 
      getRecorder(){
        this.$api.getRecorder({
          type: this.tabType,
          token: this.token
        }).then(res => {
          if(res.status == 100){
            switch(this.tabType){
              case "1": this.income = res.data;
              break;
              case "2": this.expenditure = res.data;
              break;
              case "3": this.withdrawal = res.data;
              break;
            }
          }
        }).catch(err => console.error(err));
      },
      // 打开提现窗口
      openWithdrawalWindow(){
        this.withdrawaDialogVisible = !this.withdrawaDialogVisible;
      },
      // 提交提现请求
      submitWithdrawal(){
        if(!(this.validatorResult.accountName && this.validatorResult.bankName && this.validatorResult.bankAccount && this.validatorResult.amount)) return this.$message.error('请输入正确的提现信息');
        this.$api.eCoinWithdrawal({
          accountName: this.form.accountName,
          bankName: this.form.bankName,
          bankAccount: this.form.bankAccount,
          num: this.form.amount / 2000,
          token: this.token
        }).then(res => {
          if(res.status == 100){
            // 关闭弹窗
            this.openWithdrawalWindow();
            
            this.$message({
              message: res.data,
              type: 'success'
            });
            // 刷新界面的资产信息
            this.getUserWallet();
          }
        }).catch(err => console.error(err))
      },
      // 验证账户名
      checkAccountName(){
        if (!arguments[1].trim()){
          this.validatorResult.accountName = false;
          return arguments[2](new Error('账户名不能为空'));
        }
        this.validatorResult.accountName = true;
      },
      // 验证银行名称
      checkBankName(){
        if (!arguments[1].trim()){
          this.validatorResult.bankName = false;
          return arguments[2](new Error('银行名称不能为空'));
        }
        this.validatorResult.bankName = true;
      },
      // 验证银行卡号
      checkBankAccount(){
        if (!arguments[1].trim()){
          this.validatorResult.bankAccount = false;
          return arguments[2](new Error('银行卡号不能为空'));
        }
        if(!regExp.bankCard.test(arguments[1].trim().replace(/\s/g, ''))){
          this.validatorResult.bankAccount = false;
          return arguments[2](new Error('请输入正确的银行卡号'));
        } 
        this.validatorResult.bankAccount = true;
      },
      // 验证提现金额
      checkAmount(){
        if (!arguments[1].trim()){
          this.validatorResult.amount = false;
          return arguments[2](new Error('提现金额不能为空'));
        }
        if(arguments[1].trim()%2000){
          this.validatorResult.amount = false;
          return arguments[2](new Error('提现金额必须输2000的倍数'));
        } 
        if(this.userWallet.balance < arguments[1].trim()){
          this.validatorResult.amount = false;
          return arguments[2](new Error('您输入的提现金额超出了可提现e币'));
        } 
        this.validatorResult.amount = true;
      },
      startWith(resouce,target){
        
        if(!resouce || resouce == ''){
          return false;
        }
        if(!target || target==''){
          return false;
        }
        let len=resouce.length;
        let tlen=target.length;
        if(len <= tlen){
          let tEqual=target.substring(0,len);
          if(tEqual == resouce){
            return true;
          }
        }else{
          let rEqual=resouce.substring(0,tlen);
          if(rEqual== target){
            return true;
          }
        }
        return false;
      },
      // 用户输入银行卡号自动匹配银行名称
      bankAccountChange(val){
        //限制英文、空格、符号、中文、只能输入数字 replace(/\D/g, '')
        this.form.bankAccount = val.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, "$1 ");
        //获取 输入的银行卡号 去掉空格
        const bankAccount = this.form.bankAccount.replace(/\s/g, '');
        
        //for循环
        for (let i = 0; i < cardTypeMap.length; i++) {
          // 获取每一次for循环的银行卡号的开头
          let bankCardCodes = cardTypeMap[i].bankCardCodes;
          // 判断循环获取到的 银行卡号 是否与 输入的银行卡号 相等 
          if (this.startWith(bankAccount,bankCardCodes)) {
            // 赋值到银行名称
            this.form.bankName = cardTypeMap[i].bankNames;
            this.validatorResult.bankName = true;
            break;
          }else{
            this.form.bankName = "";
            this.validatorResult.bankName = false;
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/assetManagement/assetManagement";
</style>
